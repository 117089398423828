import { Close } from '@mui/icons-material';
import { Fab, Modal, Stack } from '@mui/material';

import type { FabProps, ModalProps, StackProps } from '@mui/material';

export type ImageModalProps = {
  src?: StackProps<'img'>['src'];
  containerStackProps?: StackProps;
  imgStackProps?: StackProps<'img'>;
  closeFabProps?: FabProps;
} & Omit<ModalProps, 'children'>;

export const ImageModal = ({
  src,
  onClose,
  containerStackProps,
  imgStackProps,
  closeFabProps,
  ...modalProps
}: ImageModalProps) => {
  const onBackdropCloseHandler: React.MouseEventHandler<HTMLDivElement> = (
    event,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => {
    event.stopPropagation();
    onClose && onClose(event, reason ?? 'backdropClick');
  };

  return (
    <Modal
      onClose={onBackdropCloseHandler}
      {...modalProps}
      sx={[
        { display: 'flex', justifyContent: 'center', alignItems: 'center' },
        ...(modalProps?.sx && Array.isArray(modalProps?.sx) ? modalProps.sx : [modalProps?.sx]),
      ]}
    >
      <Stack onClick={onBackdropCloseHandler} {...containerStackProps}>
        <Stack
          component="img"
          src={src}
          maxHeight="100%"
          maxWidth="100%"
          onClick={(e) => e.stopPropagation()}
          {...imgStackProps}
          sx={[
            { objectFit: 'scale-down' },
            ...(imgStackProps?.sx && Array.isArray(imgStackProps?.sx) ? imgStackProps.sx : [imgStackProps?.sx]),
          ]}
        />

        <Fab
          size="small"
          {...closeFabProps}
          sx={[
            { position: 'absolute', top: 32, right: 16 },
            ...(closeFabProps?.sx && Array.isArray(closeFabProps?.sx) ? closeFabProps.sx : [closeFabProps?.sx]),
          ]}
        >
          <Close />
        </Fab>
      </Stack>
    </Modal>
  );
};
