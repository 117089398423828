import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { BottomTab, NavBar, Notifications } from '../components';

const RootLayout = () => {
  const location = useLocation();
  // showBottomTab if path is /jobs, /jobs/:id, /job_hirings
  const showBottomTab = /^(?:\/jobs\/?|\/jobs\/[0-9]+\/?|\/job_hirings\/?)$/.test(location.pathname);

  return (
    <Box display="flex" height="100svh" flexDirection="column">
      <NavBar />
      <Outlet />

      {showBottomTab ? <BottomTab location={location} /> : null}

      <Notifications />
    </Box>
  );
};

export default RootLayout;
