import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { clamp } from 'lodash';

import type { LinearProgressProps, TypographyProps } from '@mui/material';

export type LinearProgressWithLabelProps = {
  label?: (value: LinearProgressProps['value'], labelTypographyProps?: TypographyProps) => React.ReactNode;
  labelTypographyProps?: TypographyProps;
} & LinearProgressProps;

const LinearProgressWithLabel = ({ value, label, labelTypographyProps, ...props }: LinearProgressWithLabelProps) => {
  return (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <Stack flex={1}>
        <LinearProgress variant="determinate" value={clamp(value ?? 0, 0, 100)} {...props} />
      </Stack>
      <Box sx={{ minWidth: 32 }}>
        {label ? label(value, labelTypographyProps) : <Typography {...labelTypographyProps}>{value}</Typography>}
      </Box>
    </Stack>
  );
};

export default LinearProgressWithLabel;
