import dayjs from 'dayjs';
import dayjsLocalTH from 'dayjs/locale/th';
import { chain, compact, filter, floor, isArray, isNil, join, map } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { i18n, joinScope } from '../i18n/i18n';

import type { DurationUnitType } from 'dayjs/plugin/duration';
import type { Scope, TranslateOptions } from 'i18n-js';

export const joinPairs = (pairs?: string[][] | null) => {
  return chain(pairs)
    .map((pair) => join(pair, ' '))
    .join(', ')
    .value();
};

export const formatDate = (
  date?: string | number | Date | dayjs.Dayjs | null | undefined,
  format: string = 'ddd D MMM YYYY',
  locale: { preset: string | ILocale; object?: Partial<ILocale> | undefined } = { preset: 'th', object: dayjsLocalTH },
) => {
  return dayjs(date).locale(locale.preset, locale.object).format(format);
};

export const formatTime = (
  time?: string | number | Date | dayjs.Dayjs | null | undefined,
  addMinutes: number = 0,
  format: string = 'HH:mm',
  locale: { preset: string | ILocale; object?: Partial<ILocale> | undefined } = { preset: 'th', object: dayjsLocalTH },
) => {
  let timeObj = dayjs(time);
  if (!timeObj.isValid() && typeof time === 'string') {
    timeObj = dayjs('1970-01-01 ' + time);
  }
  return timeObj.add(addMinutes, 'minutes').locale(locale.preset, locale.object).format(format);
};

export const formattedDuration = (
  time: number,
  unit: DurationUnitType = 'minutes',
  format: 'hourMinute' | 'hourOnly' | 'minuteOnly' = 'hourMinute',
) => {
  const durationTime = dayjs.duration(time, unit);
  const hours = floor(durationTime.asHours());
  const minutes = floor(durationTime.subtract(hours, 'hours').asMinutes());

  let hoursStr = null;
  if ((format === 'hourMinute' || format === 'hourOnly') && hours) {
    hoursStr = i18n.t('datetime.formats.duration.hours', { count: hours, joinOutput: true });
  }

  let minutesStr = null;
  if ((format === 'hourMinute' || format === 'minuteOnly') && minutes) {
    minutesStr = i18n.t('datetime.formats.duration.minutes', { count: minutes, joinOutput: true });
  }

  return join(filter([hoursStr, minutesStr]), ' ');
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Nothing {}
type Union<T, U> = T | (U & Nothing);

export type labelType = Union<
  | 'business_type'
  | 'certificate_type'
  | 'gender'
  | 'job_duty'
  | 'job_status'
  | 'job_type'
  | 'job_urgents'
  | 'pre_experience'
  | 'referrer_type',
  string
>;
export const labelForEnum = (
  labelType: labelType,
  value: string | null | undefined,
  options?: TranslateOptions | undefined,
) => {
  if (value) return i18n.t(['labels', labelType, value], options);
};

export type labelScopeType = Union<
  | 'job.duties'
  | 'job.genders'
  | 'job.job_types'
  | 'job.job_urgents'
  | 'job.statuses'
  | 'register.business_types'
  | 'register.referrer_types',
  Scope
>;
export const labelFieldEnum = (
  labelScope: labelScopeType,
  value: string | null | undefined,
  options?: TranslateOptions | undefined,
) => {
  if (value) return i18n.t(joinScope('graphql.fields', labelScope, value), options);
};

export const getDeviceUUID = () => {
  let savedDeviceUUID = localStorage.getItem('DEVICE_UUID');
  if (isNil(savedDeviceUUID)) {
    savedDeviceUUID = uuidV4();
    localStorage.setItem('DEVICE_UUID', savedDeviceUUID);
  }

  return savedDeviceUUID;
};

export const hookFormNestedFieldErrorMessage = (
  fieldError?: { message?: string } | { message?: string }[],
  options = { joinErrorMessages: true },
) => {
  if (isArray(fieldError)) {
    const compactFieldError = compact(fieldError);
    return options.joinErrorMessages ? join(map(compactFieldError, 'message'), ', ') : compactFieldError[0]?.message;
  }

  return fieldError?.message;
};
