import { Add, People, WorkHistory } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Paper, Stack, useTheme } from '@mui/material';
import { trimEnd } from 'lodash';
import { Link } from 'react-router-dom';
import { createScopedI18n } from '../i18n/i18n';
import Protector from './protector';
import type { Location } from 'react-router-dom';

const bottomTabI18n = createScopedI18n('components.bottom_tab');

export type BottomTabProps = { location?: Location };

export const BottomTab = ({ location }: BottomTabProps) => {
  const theme = useTheme();

  return (
    <>
      <Stack>
        <BottomNavigation /* Use as spacer to push content out of BottomNavigation */ />
      </Stack>

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={5}>
        <BottomNavigation showLabels value={trimEnd(location?.pathname, '/')}>
          <BottomNavigationAction
            component={Link}
            to="/jobs"
            replace
            value="/jobs"
            label={bottomTabI18n('jobs_tab')}
            icon={<People />}
          />
          <Protector permissions={['jobCreate']}>
            <Protector.Allowed>
              <BottomNavigationAction
                component={Link}
                to="/jobs/new"
                value="/jobs/new"
                label={bottomTabI18n('create_job_button')}
                icon={<Add />}
                sx={{ marginY: 0.5, borderRadius: 1, color: theme.palette.primary.contrastText, backgroundColor: theme.palette.primary.main }}
              />
            </Protector.Allowed>
            <Protector.Rejected>
              <BottomNavigationAction
                disabled={true}
                value="/jobs/new"
                label={bottomTabI18n('create_job_button')}
                icon={<Add />}
                sx={{ marginY: 0.5, borderRadius: 1, color: 'white', backgroundColor: 'gray' }}
              />
            </Protector.Rejected>
          </Protector>
          <BottomNavigationAction
            component={Link}
            to="/job_hirings"
            replace
            value="/job_hirings"
            label={bottomTabI18n('job_hirings_tab')}
            icon={<WorkHistory />}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};
