import {
  ControlPointDuplicate,
  Description,
  Logout,
  NotificationsActive,
  Paid,
  Person,
  PersonOff,
  PrivacyTip,
  Smartphone,
  Store,
  SupportAgent,
} from '@mui/icons-material';
import { Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { createScopedI18n } from '../../i18n/i18n';

import type { DrawerProps } from '@mui/material';

export type AppDrawerProps = DrawerProps;

const appDrawerI18n = createScopedI18n('components.app_drawer');

export const AppDrawer = ({ onClose, ...props }: AppDrawerProps) => {
  const isMobile = useMediaQuery('(hover: none)');

  const closeDrawerOnItemClickHandler = () => {
    onClose && onClose({}, 'backdropClick');
  };

  return (
    <Drawer
      onClose={onClose}
      {...props}
      PaperProps={{ ...props.PaperProps, sx: { width: '75%', maxWidth: 300, ...props.PaperProps?.sx } }}
    >
      {isMobile ? <div style={{ flex: 1 }} /> : null}
      <Divider />

      <List sx={{ paddingLeft: 1 }}>
        <ListItemButton component={Link} to="/restaurants" onClick={closeDrawerOnItemClickHandler}>
          <ListItemIcon>
            <Store />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('restaurants')} />
        </ListItemButton>

        <ListItemButton component={Link} to="/profile" onClick={closeDrawerOnItemClickHandler}>
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('profile')} />
        </ListItemButton>

        <ListItemButton component={Link} to="/notifications" onClick={closeDrawerOnItemClickHandler}>
          <ListItemIcon>
            <NotificationsActive />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('notification')} />
        </ListItemButton>

        <ListItemButton component={Link} to="/credit" onClick={closeDrawerOnItemClickHandler}>
          <ListItemIcon>
            <Paid />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('credit')} />
        </ListItemButton>

        <ListItemButton component={Link} to="/credit/purchase" onClick={closeDrawerOnItemClickHandler}>
          <ListItemIcon>
            <ControlPointDuplicate />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('credit_purchase')} />
        </ListItemButton>
      </List>

      <Divider />

      <List sx={{ paddingLeft: 1 }}>
        <ListItemButton component={Link} to="/change_phone_number" onClick={closeDrawerOnItemClickHandler}>
          <ListItemIcon>
            <Smartphone />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('change_phone_number')} />
        </ListItemButton>

        <ListItemButton
          href="https://www.findtemp.in.th/term-condition"
          target="_blank"
          rel="noopener"
          onClick={closeDrawerOnItemClickHandler}
        >
          <ListItemIcon>
            <Description />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('term_condition')} />
        </ListItemButton>

        <ListItemButton
          href="https://www.findtemp.in.th/privacy-policy"
          target="_blank"
          rel="noopener"
          onClick={closeDrawerOnItemClickHandler}
        >
          <ListItemIcon>
            <PrivacyTip />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('privacy_policy')} />
        </ListItemButton>

        <ListItemButton component={Link} to="/contact_us" onClick={closeDrawerOnItemClickHandler}>
          <ListItemIcon>
            <SupportAgent />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('contact_us')} />
        </ListItemButton>

        <ListItemButton onClick={closeDrawerOnItemClickHandler}>
          <ListItemIcon>
            <PersonOff />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('user_delete_request')} />
        </ListItemButton>

        <ListItemButton component={Link} to="/sign_out" onClick={closeDrawerOnItemClickHandler}>
          <ListItemIcon>
            <Logout color="error" />
          </ListItemIcon>
          <ListItemText primary={appDrawerI18n('sign_out')} />
        </ListItemButton>
      </List>

      <Divider />
    </Drawer>
  );
};
