import React from 'react';

import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Alert, Collapse, Stack } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { ContainerFlexHeight, LoadingButton, apiAxiosAuth, useTrackPageView } from '../components';
import { AppContext } from '../contexts/app_context';
import { i18n } from '../i18n/i18n';
import { joinPairs } from '../utils/libs';
import tokenManager from '../utils/token_manager';
import Stash, { CREATE_JOB } from '../utils/stash';

const unregisterNotificationPushTokenGql = gql(`
  mutation unregisterNotificationPushToken($token: ID!) {
    unregisterNotificationPushToken(token: $token) {
      success
      errors
    }
  }
`);

export const SignOutPage = () => {
  useTrackPageView('SignOutPage');

  const apolloClient = useApolloClient();
  const {
    setContextIsLogin,
    setContextUser,
    setContextRedirectFrom,
    setContextRestaurants,
    setContextCurrentRestaurant,
    setContextInfoCompleteStatus,
  } = React.useContext(AppContext);

  const [unregisterNotificationPushToken] = useMutation(unregisterNotificationPushTokenGql);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const signOutHandler = async () => {
    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      // Unregister Notification Token
      let notiTokenStatus: { token?: string | null; checkAt?: string | null } | null = null;
      const notiTokenStatusJson = localStorage.getItem('NOTIFICATION_TOKEN_STATUS');
      if (notiTokenStatusJson) {
        notiTokenStatus = JSON.parse(notiTokenStatusJson);
      }
      if (notiTokenStatus?.token) {
        const { data } = await unregisterNotificationPushToken({
          variables: { token: notiTokenStatus.token },
        });

        if (data?.unregisterNotificationPushToken.success) {
          localStorage.removeItem('NOTIFICATION_TOKEN_STATUS');
        } else {
          const errors = data?.unregisterNotificationPushToken.errors;
          throw new Error(joinPairs(errors));
        }
      }

      // Sign Out
      await apiAxiosAuth('DELETE', '/users/sign_out');

      // Clear data
      await tokenManager.clearTokens();
      await apolloClient.clearStore();
      Stash(CREATE_JOB).clean();
      localStorage.clear();

      setContextUser(null);
      setContextRestaurants(null);
      setContextCurrentRestaurant(null);
      setContextInfoCompleteStatus(null);
      setContextRedirectFrom(null);

      // Will auto redirect to /auth/sign_in
      setContextIsLogin(false);
    } catch (error) {
      const errors = get(error, ['response', 'data', 'errors']);
      setErrorMessage(errors ? joinPairs(errors) : get(error, 'message', null));
    }

    setIsSubmitting(false);
  };

  return (
    <ContainerFlexHeight
      maxWidth="sm"
      sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', paddingY: 2 }}
    >
      <Stack width="100%" gap={2}>
        <Collapse in={!isEmpty(errorMessage)}>
          <Alert severity="error">{errorMessage}</Alert>
        </Collapse>

        <LoadingButton
          variant="contained"
          color="error"
          fullWidth
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={signOutHandler}
        >
          {i18n.t('general.sign_out')}
        </LoadingButton>
      </Stack>
    </ContainerFlexHeight>
  );
};
