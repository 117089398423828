import React from 'react';

import { Avatar, Link } from '@mui/material';
import { ImageModal } from './image_modal';

import type { AvatarProps } from '@mui/material';

export type EnlargeableAvatarProps = AvatarProps;

export const EnlargeableAvatar = ({ src, ...avatarProps }: EnlargeableAvatarProps) => {
  const [showImageModal, setShowImageModal] = React.useState(false);
  const onCloseHandler = () => setShowImageModal(false);

  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setShowImageModal(true);
  };

  return (
    <>
      <Link onClick={onClickHandler} sx={{ ':hover': { cursor: 'pointer' } }}>
        <Avatar src={src} {...avatarProps}></Avatar>
      </Link>

      <ImageModal src={src} open={showImageModal} onClose={onCloseHandler} />
    </>
  );
};
