import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/app_context';

export const IsForceChangePasswordGuard = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { contextInfoCompleteStatus, setContextRedirectFrom } = React.useContext(AppContext);

  const needRedirect = contextInfoCompleteStatus?.isForceChangePassword === true;
  if (needRedirect) {
    setTimeout(() => setContextRedirectFrom(location), 0); // TODO: Find other solution
    return <Navigate to="/force_change_password" replace />;
  } else {
    return children;
  }
};
