import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Button, ButtonGroup, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown, Delete } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { createScopedI18n } from '../../../i18n/i18n';
import { CloseJobDialog } from './close_job_dialog';
import { CancelJobDialog } from './cancel_job_dialog';

const jobStatusGql = gql(`
  query getJobStatus($jobId: ID!) {
    job(id: $jobId) {
      id
      status
    }
  }
`);

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');

export type CloseOrCancelJobButtonType = {
  jobId?: string;
  onSuccess?: () => void;
};

export const CloseOrCancelJobButton = ({ jobId, onSuccess }: CloseOrCancelJobButtonType) => {
  const { data: jobStatusData, loading: jobStatusLoading } = useQuery(jobStatusGql, {
    variables: { jobId },
    skip: isEmpty(jobId),
  });

  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
  const menuOpenHandler = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const menuCloseHandler = () => setMenuAnchor(null);

  const [showCloseJobDialog, setShowCloseJobDialog] = React.useState(false);
  const openCloseJobDialogHandler = () => setShowCloseJobDialog(true);
  const closeCloseJobDialogHandler = () => setShowCloseJobDialog(false);

  const [showCancelJobDialog, setShowCancelJobDialog] = React.useState(false);
  const openCancelJobDialogHandler = () => {
    menuCloseHandler();
    setShowCancelJobDialog(true);
  };
  const closeCancelJobDialogHandler = () => setShowCancelJobDialog(false);

  return (
    <>
      <ButtonGroup variant="outlined" color="error" disableElevation>
        <Button
          disabled={
            jobStatusLoading ||
            jobStatusData?.job?.status === 'full' ||
            jobStatusData?.job?.status === 'close' ||
            jobStatusData?.job?.status === 'cancel'
          }
          onClick={openCloseJobDialogHandler}
        >
          {jobDetailPageI18n('close_job')}
        </Button>
        <Button size="small" onClick={menuOpenHandler}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>

      <Menu
        open={!isEmpty(menuAnchor)}
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={menuCloseHandler}
      >
        <MenuItem
          disabled={jobStatusLoading || jobStatusData?.job?.status === 'cancel'}
          onClick={openCancelJobDialogHandler}
          sx={{ color: 'error.main' }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          {jobDetailPageI18n('cancel_job')}
        </MenuItem>
      </Menu>

      <CloseJobDialog
        open={showCloseJobDialog}
        jobId={jobId}
        onClose={closeCloseJobDialogHandler}
        onSuccess={onSuccess}
      />

      <CancelJobDialog
        open={showCancelJobDialog}
        jobId={jobId}
        onClose={closeCancelJobDialogHandler}
        onSuccess={onSuccess}
      />
    </>
  );
};
