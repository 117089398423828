import React from 'react';

import { Person, Star } from '@mui/icons-material';
import type { CardProps } from '@mui/material';
import { Alert, Card, CardActionArea, CardContent, Collapse, Stack, Typography, useTheme } from '@mui/material';
import { concat, filter, get, includes, isEmpty, isNil, map, size } from 'lodash';
import numeral from 'numeral';
import { SelectableAvatar, StudentPropertyChip } from '../../../components';
import NeedleIcon from '../../../components/icons/needle';
import NeedleOffIcon from '../../../components/icons/needle_off';
import { createScopedI18n } from '../../../i18n/i18n';
import Analytic from '../../../utils/analytic';
import { formattedDuration, labelForEnum } from '../../../utils/libs';
import StudentCandidateDetailDialog from './student_candidate_detail_dialog';

import type { SelectableAvatarProps } from '../../../components/selectable_avatar';
import type { StudentCandidateDetailDialogProps } from './student_candidate_detail_dialog';

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');

const badgesFromJobTypes = {
  catering: {
    certificate: ['food_handle'],
    experience: ['catering'],
    preExperience: ['serve'],
  },
  kitchen: {
    certificate: ['food_handle'],
    experience: ['kitchen'],
    preExperience: ['serve'],
  },
  pack: {
    certificate: [],
    experience: ['pack'],
    preExperience: ['serve'],
  },
  serve: {
    certificate: ['food_handle'],
    experience: ['serve'],
    preExperience: ['serve'],
  },
  wash: {
    certificate: ['food_handle'],
    experience: ['wash'],
    preExperience: ['serve'],
  },

  other: {
    certificate: null,
    experience: null,
    preExperience: null,
  },
};

const isJobTypeAcceptExperienceType = (experienceType: string, jobType: string = 'other') => {
  const showedExperienceType: string[] | null = get(badgesFromJobTypes, [jobType, 'experience'], null);
  if (isNil(showedExperienceType)) {
    return true;
  }

  return includes(showedExperienceType, experienceType);
};

const isJobTypeAcceptPreExperienceType = (preExperienceType: string, jobType: string = 'other') => {
  const showedPreExperienceType: string[] | null = get(badgesFromJobTypes, [jobType, 'preExperience'], null);
  if (isNil(showedPreExperienceType)) {
    return true;
  }

  return includes(showedPreExperienceType, preExperienceType);
};

const isJobTypeAcceptCertificateType = (certificateType: string, jobType: string = 'other') => {
  const showedCertificateType: string[] | null = get(badgesFromJobTypes, [jobType, 'certificate'], null);
  if (isNil(showedCertificateType)) {
    return true;
  }

  return includes(showedCertificateType, certificateType);
};

export type StudentCandidateType = {
  id?: string;
  avatar?: string;
  nickName?: string | null;
  headline?: string | null;
  rating?: number | null;
  isWorkedHere?: boolean | null;
  lastStudentCovid19Vaccine?: { id: string; nth: string; vaccinedAt: string | Date } | null;
  studentExperiences?: { id: string; jobType: string; minute: number }[] | null;
  studentPreExperiences?: string[] | null;
  studentCertificates?: { id: string; certificateType: string }[] | null;
  latestCommends?: string[] | null;
};

export type StudentCandidateCardProps = {
  student: StudentCandidateType;
  restaurantChargePerHour?: number;
  jobType?: string;
  jobApplicantId?: number | string;
  selected?: boolean;
  errorMessage?: string | null;
  disabled?: boolean;
  disableDetailDialog?: boolean;
  disableDetailDialogAction?: StudentCandidateDetailDialogProps['disableDetailDialogAction'];
  onAvatarClick?: SelectableAvatarProps['onClick'];
  onErrorMessageChange?: StudentCandidateDetailDialogProps['onErrorMessageChange'];
} & CardProps;

const StudentCandidateCard = (
  {
    jobApplicantId,
    student,
    restaurantChargePerHour,
    jobType,
    selected,
    errorMessage,
    disabled,
    disableDetailDialog,
    disableDetailDialogAction,
    onClick,
    onAvatarClick,
    onErrorMessageChange,
    ...props
  }: StudentCandidateCardProps,
  ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined,
) => {
  const theme = useTheme();

  const studentExperienceBadges = React.useMemo(
    () =>
      map(student.studentExperiences, (experience) => ({
        ...experience,
        badgeType: 'experience',
        isShow: isJobTypeAcceptExperienceType(experience.jobType, jobType),
        label: `${labelForEnum('job_type', experience.jobType) ?? experience.jobType} ${formattedDuration(experience.minute, 'minutes', 'hourOnly')}`,
      })),
    [student.studentExperiences, jobType],
  );

  const studentPreExperienceBadges = React.useMemo(
    () =>
      map(student.studentPreExperiences, (preExperience) => ({
        type: preExperience,
        badgeType: 'preExperience',
        isShow: isJobTypeAcceptPreExperienceType(preExperience, jobType),
        label: labelForEnum('pre_experience', preExperience) ?? preExperience,
      })),
    [student.studentPreExperiences, jobType],
  );

  const studentCertificateBadges = React.useMemo(
    () =>
      map(student.studentCertificates, (certificate) => ({
        ...certificate,
        badgeType: 'certificate',
        isShow: isJobTypeAcceptCertificateType(certificate.certificateType, jobType),
        label: labelForEnum('certificate_type', certificate.certificateType) ?? certificate.certificateType,
      })),
    [student.studentCertificates, jobType],
  );

  const studentPropertyBadges = React.useMemo(() => {
    let badges: (
      | (typeof studentExperienceBadges)[number]
      | (typeof studentPreExperienceBadges)[number]
      | (typeof studentCertificateBadges)[number]
    )[] = [];
    if (isEmpty(filter(studentExperienceBadges, 'isShow'))) {
      badges = concat(badges, studentPreExperienceBadges);
    } else {
      badges = concat(badges, studentExperienceBadges);
    }
    return concat(badges, studentCertificateBadges);
  }, [studentExperienceBadges, studentPreExperienceBadges, studentCertificateBadges]);

  const [showDialog, setShowDialog] = React.useState(false);
  const onCardClickHandler: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (onClick) onClick(event);

    if (!disableDetailDialog) {
      setShowDialog(true);

      Analytic().logViewStudentDetail('open', {
        student_id: student.id,
        job_applicant_id: jobApplicantId,
      });
    }
  };
  const closeDialogHandler = (event?: { stopPropagation?: () => void }) => {
    if (event?.stopPropagation) event.stopPropagation();

    setShowDialog(false);

    Analytic().logViewStudentDetail('close', {
      student_id: student.id,
      job_applicant_id: jobApplicantId,
    });
  };

  const onAvatarClickHandler: SelectableAvatarProps['onClick'] = (event) => {
    if (onAvatarClick) onAvatarClick(event);
  };

  return (
    <>
      <Card ref={ref} onClick={onCardClickHandler} {...props}>
        <CardActionArea>
          <CardContent sx={[{ gap: 1 }, { transition: 'opacity 0.2s ease' }, !!disabled && { opacity: 0.5 }]}>
            <Stack flexDirection="row" gap={1}>
              <SelectableAvatar
                src={student.avatar}
                selected={selected}
                onClick={onAvatarClickHandler}
                sx={{ width: 64, height: 64 }}
              >
                <Person fontSize="large" />
              </SelectableAvatar>

              <Stack flex={1}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-word',
                  }}
                >
                  {jobDetailPageI18n('temp_name', { name: student.nickName })}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-word',
                  }}
                >
                  {student.headline}
                </Typography>
              </Stack>

              <Stack alignItems="flex-end">
                <Stack alignItems="flex-end" width={56} height={32}>
                  {student.rating && student.rating > 0 && (
                    <Stack flexDirection="row" alignItems="center">
                      <Star color="warning" sx={{ fontSize: theme.typography.h6.fontSize }} />
                      <Typography variant="h6">{numeral(student.rating).format('0,0.0')}</Typography>
                    </Stack>
                  )}
                </Stack>
                {size(student.lastStudentCovid19Vaccine) > 0 ? (
                  <NeedleIcon color="primary" sx={{ fontSize: 24 }} />
                ) : (
                  <NeedleOffIcon color="disabled" sx={{ fontSize: 24 }} />
                )}
              </Stack>
            </Stack>

            <Stack flexDirection="column" gap={0.5}>
              <Stack flex={1} direction="row" flexWrap="wrap" alignItems="center" padding={1} gap={0.5}>
                {student.isWorkedHere && (
                  <StudentPropertyChip size="small" type="isWorkedHere" label={jobDetailPageI18n('been_work_here')} />
                )}

                {map(
                  studentPropertyBadges,
                  ({ isShow, badgeType, label }, index) =>
                    isShow && <StudentPropertyChip key={index} size="small" type={badgeType} label={label} />,
                )}
              </Stack>

              {restaurantChargePerHour && (
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  alignSelf="flex-end"
                  border={1}
                  borderRadius={1}
                  borderColor="primary.main"
                  color="primary.main"
                  paddingX={1.5}
                  paddingY={0.5}
                  gap={1}
                >
                  <Typography variant="h5">{numeral(restaurantChargePerHour).format('0,0')}</Typography>
                  <Typography variant="caption" textAlign="end">
                    {jobDetailPageI18n('credit_pre')}
                    <br />
                    {jobDetailPageI18n('pre_hour')}
                  </Typography>
                </Stack>
              )}
            </Stack>

            <Collapse in={!!errorMessage}>
              <Stack marginTop={1}>
                <Alert severity="error">{errorMessage}</Alert>
              </Stack>
            </Collapse>
          </CardContent>
        </CardActionArea>
      </Card>

      {!disableDetailDialog && (
        <StudentCandidateDetailDialog
          open={showDialog}
          onClose={closeDialogHandler}
          student={student}
          studentExperienceBadges={studentExperienceBadges}
          studentPreExperienceBadges={studentPreExperienceBadges}
          studentCertificateBadges={studentCertificateBadges}
          restaurantChargePerHour={restaurantChargePerHour}
          jobApplicantId={jobApplicantId}
          disableDetailDialogAction={disableDetailDialogAction}
          errorMessage={errorMessage}
          onErrorMessageChange={onErrorMessageChange}
        />
      )}
    </>
  );
};

export default React.forwardRef(StudentCandidateCard);
