import { CheckCircle, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { ListItem, ListItemAvatar, ListItemText, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { EnlargeableAvatar } from '../../../components';
import { formatTime } from '../../../utils/libs';

import type { ListItemProps } from '@mui/material';

const StepIcon = ({ active, completed, icon }: { active?: boolean; completed?: boolean; icon?: string }) => {
  const sx = { fontSize: 18 };
  if (completed) {
    return <CheckCircle color="info" sx={sx} />;
  } else if (active) {
    return icon ? (
      <Typography variant="caption" color="primary">
        {icon}
      </Typography>
    ) : (
      <RadioButtonChecked color="primary" sx={sx} />
    );
  } else {
    return <RadioButtonUnchecked color="action" sx={sx} />;
  }
};

export type JobHiringType = {
  id: string;
  startOperationTime?: string | Date | null;
  endOperationTime?: string | Date | null;
  status?: string;
  student: {
    id: string;
    avatar?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    nickName?: string | null;
  };
  studentReview?: {
    id?: string;
    star?: number;
  } | null;
};

export type JobHiringsCardItemProps = {
  jobHiring: JobHiringType;
  onStartWorkClick?: () => void;
  onGetOffWorkClick?: () => void;
  onReviewStudentClick?: () => void;
} & ListItemProps;

export const JobHiringsCardItem = ({ jobHiring, ...props }: JobHiringsCardItemProps) => {
  let activeStep = 0;
  if (isNil(jobHiring.startOperationTime)) {
    activeStep = 0;
  } else if (!isNil(jobHiring.startOperationTime) && jobHiring.status !== 'done') {
    activeStep = 1;
  } else if (jobHiring.status === 'done' && isNil(jobHiring.studentReview)) {
    activeStep = 2;
  } else if (!isNil(jobHiring.studentReview)) {
    activeStep = 3;
  }

  return (
    <ListItem {...props}>
      <ListItemAvatar>
        <EnlargeableAvatar src={jobHiring.student.avatar ?? undefined} sx={{ width: 48, height: 48 }} />
      </ListItemAvatar>
      <Stack flex={1}>
        <ListItemText
          primary={
            <Typography
              variant="body1"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all',
              }}
            >
              {jobHiring.student.firstName} (เทมป์{jobHiring.student.nickName})
            </Typography>
          }
          secondary={`${jobHiring.startOperationTime ? formatTime(jobHiring.startOperationTime) : 'ยังไม่ระบุ'} | ${
            jobHiring.endOperationTime ? formatTime(jobHiring.endOperationTime) : 'ยังไม่ระบุ'
          }`}
          sx={{ paddingLeft: 1, marginY: 0 }}
        />

        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel
              StepIconComponent={StepIcon}
              StepIconProps={{ icon: 'เข้างาน' }}
              sx={{ '.MuiStepLabel-iconContainer': { paddingRight: 0 } }}
            />
          </Step>
          <Step>
            <StepLabel
              StepIconComponent={StepIcon}
              StepIconProps={{ icon: 'ออกงาน' }}
              sx={{ '.MuiStepLabel-iconContainer': { paddingRight: 0 } }}
            />
          </Step>
          <Step>
            <StepLabel
              StepIconComponent={StepIcon}
              StepIconProps={{ icon: 'ประเมิน' }}
              sx={{ '.MuiStepLabel-iconContainer': { paddingRight: 0 } }}
            />
          </Step>
        </Stepper>
      </Stack>
    </ListItem>
  );
};
