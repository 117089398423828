import { Checkbox, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { EnlargeableAvatar } from '../../../components';

import type { ListItemButtonProps } from '@mui/material';
import type { JobHiringType } from './job_hirings_card_item';

export type JobHiringActionItemProps = {
  jobHiring: JobHiringType;
  selected?: boolean;
} & ListItemButtonProps;

export const JobHiringActionItem = ({ jobHiring, selected, ...props }: JobHiringActionItemProps) => {
  return (
    <ListItemButton {...props}>
      <Checkbox edge="start" checked={selected} />
      <ListItemAvatar>
        <EnlargeableAvatar src={jobHiring.student.avatar ?? undefined} sx={{ width: 48, height: 48 }} />
      </ListItemAvatar>
      <ListItemText
        primary={`เทมป์${jobHiring.student.nickName}`}
        primaryTypographyProps={{
          variant: 'body1',
        }}
        secondary={`${jobHiring.student.firstName} ${jobHiring.student.lastName}`}
        secondaryTypographyProps={{
          sx: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-all',
          },
        }}
        sx={{ marginY: 0 }}
      />
    </ListItemButton>
  );
};
