import { gql, useLazyQuery } from '@apollo/client';

interface PermissionData {
  permissions: {
    chain: {
      profileEdit: boolean;
    };
    restaurant: {
      jobCreate: boolean;
    };
  };
}

const getUserPermissionGql = gql(`
  query getPermission($chainId: ID!, $restaurantId: ID!) {
    permissions {
      chain(chainId: $chainId) {
        profileEdit
      }
      restaurant(restaurantId: $restaurantId) {
        jobCreate
      }
    }
  }
`);

export const useGetPermissions = () => {
  const [getUserPermissionQuery] = useLazyQuery<PermissionData>(getUserPermissionGql, {
    fetchPolicy: 'network-only',
  });

  const getUserPermissions = async (chainId: string | undefined, restaurantId: string) => {
    const { data } = await getUserPermissionQuery({
      variables: {
        chainId,
        restaurantId,
      },
    });
    return data?.permissions;
  };

  return { getUserPermissions };
};
