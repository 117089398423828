import React from 'react';

import { LocationOn } from '@mui/icons-material';
import { Button, Card, Stack, useTheme } from '@mui/material';
import { Marker } from '@react-google-maps/api';
import { GoogleMapsBase, MapsPickerDialog } from '.';

import type { CardProps } from '@mui/material';
import type { MapsPickerResultType } from './maps_picker_dialog';

const defaultAspectRatio = 2;
// DEFAULT TO Democracy Monument, https://maps.google.com?q=13.7563,100.5018
const DEFAULT_LOCATION = { lat: 13.7563, lng: 100.5018 };

const toLatLng = (
  location?: {
    lat?: number | google.maps.LatLng['lat'] | null;
    latitude?: number | null;
    lng?: number | google.maps.LatLng['lng'] | null;
    longitude?: number | null;
  } | null,
) => {
  const lat = (typeof location?.lat === 'function' ? location.lat() : location?.lat) ?? location?.latitude;
  const lng = (typeof location?.lng === 'function' ? location.lng() : location?.lng) ?? location?.longitude;
  if (lat && lng) {
    return { lat, lng };
  }
};

export type MapsPickerCardProps = {
  location?: { latitude: number; longitude: number };
  onConfirm?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, result: MapsPickerResultType) => void;
  disabled?: boolean;
  buttonText?: string;
} & CardProps;

export const MapsPickerCard = ({ location, onConfirm, disabled, buttonText, sx, ...props }: MapsPickerCardProps) => {
  const theme = useTheme();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [mapLoaded, setMapLoaded] = React.useState(false);

  return (
    <Card {...props} sx={{ width: '100%', aspectRatio: defaultAspectRatio, ...sx }}>
      <Stack width="100%" height="100%" position="relative">
        <GoogleMapsBase
          clickableIcons={false}
          zoom={location ? 15 : 12}
          center={toLatLng(location) ?? DEFAULT_LOCATION}
          options={{
            gestureHandling: 'none',
            disableDefaultUI: true,
          }}
          onLoad={() => {
            setTimeout(() => setMapLoaded(true), 100);
          }}
          mapContainerStyle={{ width: '100%', height: '100%' }}
        >
          {location && mapLoaded ? <Marker position={toLatLng(location) ?? DEFAULT_LOCATION} /> : null}
        </GoogleMapsBase>

        {disabled ? null : (
          <Stack
            width="100%"
            height="100%"
            position="absolute"
            justifyContent="flex-end"
            alignItems="center"
            gap={1}
            bgcolor="rgba(0,0,0,0.1)"
          >
            <Button
              id="google-maps-picker-button"
              variant="contained"
              color="inherit"
              size="large"
              disableElevation
              onClick={() => setOpenDialog(true)}
              startIcon={<LocationOn />}
              sx={{ minWidth: '40%', bottom: 32 }}
              style={{ backgroundColor: theme.palette.background.paper }}
            >
              {buttonText ?? 'เลือกสถานที่'}
            </Button>
          </Stack>
        )}
      </Stack>
      <MapsPickerDialog
        open={openDialog}
        initialMapCenter={location}
        onClose={() => setOpenDialog(false)}
        onConfirm={onConfirm}
      />
    </Card>
  );
};
