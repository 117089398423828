import { gql, useQuery } from '@apollo/client';
import { BrokenImage, SaveAlt } from '@mui/icons-material';
import { Button, Container, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useLocation } from 'react-router-dom';
import { LoadingSpinner } from '../../components';

const getGbPrimePayQrCodeGql = gql(`
  query getGbPrimePayQrCode($satang: Int!, $isTaxInvoice: Boolean) {
    getGbPrimePayQrCode(satang: $satang, isTaxInvoice: $isTaxInvoice) {
      success
      qrCodeBase64Image
      referenceId
    }
  }
`);

export const CheckOutQrPage = () => {
  const { state }: { state?: { amount?: number; isTaxInvoice?: boolean } } = useLocation();

  const { data, loading } = useQuery(getGbPrimePayQrCodeGql, {
    fetchPolicy: 'network-only',
    variables: {
      satang: (state?.amount ?? 0) * 100,
      isTaxInvoice: state?.isTaxInvoice,
    },
    skip: isNil(state?.amount),
  });

  const saveQrHandler = () => {
    const base64Str = `data:image/png;base64,${data?.getGbPrimePayQrCode.qrCodeBase64Image}`;
    const filename = `TopUp_${dayjs().format('YYYYMMDD')}_${data?.getGbPrimePayQrCode.referenceId}.png`;

    const downloadElement = document.createElement('a');
    downloadElement.href = base64Str;
    downloadElement.target = '_self';
    downloadElement.download = filename;
    downloadElement.click();
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="sm" sx={{ padding: 2 }}>
      <Stack alignItems="center" gap={2}>
        {isNil(data?.getGbPrimePayQrCode.qrCodeBase64Image) ? (
          <Stack width="100%" height={300} justifyContent="center" alignItems="center" gap={2}>
            <BrokenImage fontSize="large" color="action" />
            <Typography variant="body1" color="GrayText">
              ไม่มีข้อมูล
            </Typography>
          </Stack>
        ) : (
          <img
            src={`data:image/png;base64,${data?.getGbPrimePayQrCode.qrCodeBase64Image}`}
            width="100%"
            style={{ imageRendering: 'crisp-edges' }}
          ></img>
        )}
        <Button
          variant="contained"
          size="large"
          fullWidth
          disabled={isNil(data?.getGbPrimePayQrCode.qrCodeBase64Image)}
          startIcon={<SaveAlt />}
          onClick={saveQrHandler}
        >
          บันทึกรูป
        </Button>
      </Stack>
    </Container>
  );
};
