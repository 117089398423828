import React from 'react';

import { isNil, noop } from 'lodash';

import type { PaletteMode } from '@mui/material';
import type { Location } from 'react-router-dom';

export type ThemeModeType = PaletteMode | 'system';

export type UserType = {
  id: string;
  email?: string | null;
  username?: string | null;
  chain?: { id: string } | null;
  permissions?: string[] | null;
};

export type RestaurantType = {
  id: string;
  name: string;
  branchName: string;
  businessType: string;
  frontPhotoUri: string;
  isInfoComplete?: boolean;
  isHiring?: boolean;
};

export type RestaurantSelectorOnClickMapType = {
  [restaurantId: RestaurantType['id']]: (restaurant: RestaurantType) => void;
};

export type InfoCompleteStatus = {
  isPdpa?: boolean;
  isInfoComplete?: boolean;
  isForceChangePassword?: boolean;
};

export type DateType = {
  date: string;
  onClick?: (date: DateType) => void;
};

export type PermissionsType = {
  chain?: {
    profileEdit?: boolean;
  };

  restaurant?: {
    jobCreate?: boolean;
  };
};

export type AppContextType = {
  contextIsLogin: boolean | null;
  setContextIsLogin: (isLogin: boolean) => void;

  contextThemeMode: ThemeModeType;
  setContextThemeMode: (themeMode: ThemeModeType) => void;

  contextUser: UserType | null;
  setContextUser: (user: UserType | null) => void;

  contextPermissions: PermissionsType | null;
  setContextPermissions: (permissions: PermissionsType | null) => void;

  contextCurrentRestaurant: RestaurantType | null;
  setContextCurrentRestaurant: (currentRestaurant: RestaurantType | null) => void;

  contextRestaurants: RestaurantType[] | null;
  setContextRestaurants: (restaurants: RestaurantType[] | null) => void;

  contextRestaurantSelectorOnClickMap: RestaurantSelectorOnClickMapType | null;
  setContextRestaurantSelectorOnClickMap: (
    restaurantSelectorOnClickMap: RestaurantSelectorOnClickMapType | null,
  ) => void;

  contextRedirectFrom: Location | null;
  setContextRedirectFrom: (location: Location | null) => void;
  contextInfoCompleteStatus: InfoCompleteStatus | null;
  setContextInfoCompleteStatus: (infoCompleteStatus: Partial<InfoCompleteStatus> | null) => void;

  contextDates: DateType[] | null;
  setContextDates: (dates: DateType[] | null) => void;
};

const AppContext = React.createContext<AppContextType>({
  contextIsLogin: null,
  setContextIsLogin: noop,

  contextThemeMode: 'system',
  setContextThemeMode: noop,

  contextUser: null,
  setContextUser: noop,

  contextPermissions: null,
  setContextPermissions: noop,

  contextCurrentRestaurant: null,
  setContextCurrentRestaurant: noop,
  contextRestaurants: null,
  setContextRestaurants: noop,

  contextRestaurantSelectorOnClickMap: null,
  setContextRestaurantSelectorOnClickMap: noop,

  contextRedirectFrom: null,
  setContextRedirectFrom: noop,
  contextInfoCompleteStatus: null,
  setContextInfoCompleteStatus: noop,

  contextDates: null,
  setContextDates: noop,
});

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLogin, setIsLoading] = React.useState<boolean | null>(null);
  const setContextIsLoginHandler = React.useCallback((isLogin: boolean) => setIsLoading(isLogin), []);

  const [themeMode, setThemeMode] = React.useState<ThemeModeType | null>(null);
  const setContextThemeModeHandler = React.useCallback((themeMode: ThemeModeType) => {
    setThemeMode(themeMode);
    localStorage.setItem('THEME_MODE', themeMode);
  }, []);

  const [user, setUser] = React.useState<UserType | null>(null);
  const setContextUserHandler = React.useCallback((user: UserType | null) => {
    setUser(user);
    if (isNil(user)) {
      localStorage.removeItem('userInfo');
    } else {
      localStorage.setItem('userInfo', JSON.stringify(user));
    }
  }, []);

  const [permissions, setPermissions] = React.useState<PermissionsType | null>(null);
  const setContextPermissionsHandler = React.useCallback((permissions: PermissionsType | null) => setPermissions(permissions), []);

  const [currentRestaurant, setCurrentRestaurant] = React.useState<RestaurantType | null>(null);
  const setContextCurrentRestaurantHandler = React.useCallback(
    (nextCurrentRestaurant: RestaurantType | null) => setCurrentRestaurant(nextCurrentRestaurant),
    [],
  );

  const [restaurants, setRestaurants] = React.useState<RestaurantType[] | null>(null);
  const setContextRestaurantsHandler = React.useCallback(
    (nextRestaurants: RestaurantType[] | null) => setRestaurants(nextRestaurants),
    [],
  );

  const [restaurantSelectorOnClickMap, setRestaurantSelectorOnClickMap] =
    React.useState<RestaurantSelectorOnClickMapType | null>(null);

  const [redirectFrom, setRedirectFrom] = React.useState<Location | null>(null);

  const [infoCompleteStatus, setInfoCompleteStatus] = React.useState<InfoCompleteStatus | null>(null);
  const setContextInfoCompleteStatusHandler = React.useCallback(
    (infoCompleteStatus: Partial<InfoCompleteStatus> | null) => {
      if (isNil(infoCompleteStatus)) {
        setInfoCompleteStatus(null);
      } else {
        setInfoCompleteStatus((prev) => ({ ...prev, ...infoCompleteStatus }));
      }
    },
    [],
  );

  const [dates, setDates] = React.useState<DateType[] | null>(null);

  const appValues = {
    contextIsLogin: isLogin,
    setContextIsLogin: setContextIsLoginHandler,

    contextThemeMode: themeMode ?? 'system',
    setContextThemeMode: setContextThemeModeHandler,

    contextUser: user,
    setContextUser: setContextUserHandler,

    contextPermissions: permissions,
    setContextPermissions: setContextPermissionsHandler,

    contextCurrentRestaurant: currentRestaurant,
    setContextCurrentRestaurant: setContextCurrentRestaurantHandler,
    contextRestaurants: restaurants,
    setContextRestaurants: setContextRestaurantsHandler,

    contextRestaurantSelectorOnClickMap: restaurantSelectorOnClickMap,
    setContextRestaurantSelectorOnClickMap: setRestaurantSelectorOnClickMap,

    contextRedirectFrom: redirectFrom,
    setContextRedirectFrom: setRedirectFrom,
    contextInfoCompleteStatus: infoCompleteStatus,
    setContextInfoCompleteStatus: setContextInfoCompleteStatusHandler,

    contextDates: dates,
    setContextDates: setDates,
  };

  return <AppContext.Provider value={appValues}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
