import React from 'react';

import { Home, Refresh } from '@mui/icons-material';
import { Alert, Box, Button, Stack } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ContainerFlexHeight } from '../components';
import { createScopedI18n, i18n } from '../i18n/i18n';

const errorPageI18n = createScopedI18n('pages.error');

const RootError = () => {
  const routeError = useRouteError();

  const errorMessage = React.useMemo(() => {
    const error = isRouteErrorResponse(routeError) ? routeError.error : routeError;

    let errorMessage: string | undefined = get(error, 'message');

    const status: number | undefined = get(routeError, 'status');
    const statusText: string | undefined = get(routeError, 'statusText');

    if (status === 404) {
      errorMessage = errorPageI18n('error_404');
    } else if (status === 503 || get(error, 'message') === 'Failed to fetch') {
      errorMessage = errorPageI18n('error_503');
    }

    if (isEmpty(errorMessage) && status && statusText) {
      errorMessage = `${status} ${statusText}`;
    }

    return errorMessage;
  }, [routeError]);

  const reloadWebHandler = () => {
    window.location.reload();
  };

  return (
    <Box display="flex" height="100vh" flexDirection="column">
      <ContainerFlexHeight
        maxWidth="xs"
        sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        <Stack justifyContent="center" width="100%" margin={2} gap={2}>
          <Alert severity="error">{errorMessage ?? i18n.t('general.error')}</Alert>

          <Stack flexDirection="row" gap={1}>
            <Button fullWidth variant="outlined" onClick={reloadWebHandler} startIcon={<Refresh />}>
              {errorPageI18n('refresh_button')}
            </Button>
            <Button fullWidth variant="outlined" component={Link} to="/" replace startIcon={<Home />}>
              {errorPageI18n('go_back_home_button')}
            </Button>
          </Stack>
        </Stack>
      </ContainerFlexHeight>
    </Box>
  );
};

export default RootError;
