import {
  getAnalytics,
  logEvent as logFirebaseEvent,
  setUserId as setFirebaseUserId,
  setUserProperties as setFirebaseUserProperties,
} from 'firebase/analytics';

import type { FirebaseApp } from 'firebase/app';

export type PageViewEventParamsType = {
  page_title?: string;
  page_location?: string;
  page_path?: string;
  [key: string]: unknown;
};

export type ViewStudentDetailEventParamsType = {
  student_id?: string | number;
  job_id?: string | number;
  job_applicant_id?: string | number;
  [key: string]: unknown;
};

const Analytic = (app?: FirebaseApp) => {
  const analytics = getAnalytics(app);

  const setUserId = (userId: string | null) => {
    setFirebaseUserId(analytics, userId);
  };

  const setUserProperties = (properties: { [key: string]: unknown }) => {
    setFirebaseUserProperties(analytics, properties);
  };

  // TODO: Better type notation to list all eventName and eventParams
  const logEvent = (eventName: string, eventParams?: { [key: string]: unknown }) => {
    logFirebaseEvent(analytics, eventName, eventParams);
  };

  // Firebase event
  const logPageView = (eventParams?: PageViewEventParamsType) => {
    logEvent('page_view', eventParams);
  };

  // Custom event
  const logViewStudentDetail = (action: 'open' | 'close', eventParams?: ViewStudentDetailEventParamsType) => {
    if (action === 'open') {
      logEvent('open_student_detail', eventParams);
    } else if (action === 'close') {
      logEvent('close_student_detail', eventParams);
    }
  };

  return {
    analytics,
    setUserId,
    setUserProperties,
    logEvent,
    logPageView,
    logViewStudentDetail,
  };
};

export default Analytic;
