import React from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { map } from 'lodash';
import { AppContext } from '../contexts/app_context';
import { useGetPermissions } from '../utils/useGetPermissions';

const getRestaurantsGql = gql(`
  query getRestaurants {
    user {
      id
      restaurants {
        id
        name
        branchName
        businessType
        frontPhotoUri
        isInfoCompletedV2
      }
    }
  }
`);


export const useLoadRestaurantsContext = ({ skip }: { skip?: boolean } = {}) => {
  const { setContextRestaurants, setContextPermissions, contextUser } = React.useContext(AppContext);
  const { getUserPermissions } = useGetPermissions();

  const [isLoading, setIsLoading] = React.useState(true);
  const [getRestaurantsQuery, { loading }] = useLazyQuery(getRestaurantsGql, {
    fetchPolicy: 'network-only',
  });

  const fetchRestaurantsAndPermissions = React.useCallback(
    async (updateContext = true) => {
      const { data } = await getRestaurantsQuery();
      let nextRestaurants = [];
      if (data?.user.restaurants) {
        nextRestaurants = map(data.user.restaurants, ({ isInfoCompletedV2, ...restaurant }) => ({
          ...restaurant,
          isInfoComplete: isInfoCompletedV2,
        }));
        if (updateContext) {
          setContextRestaurants(nextRestaurants);
        }
      }

      // *Note: Why same function? To make loading state management easier and to reduce duplication of similar logic.
      // Fetch permissions for the first restaurant if there are any restaurants
      if (nextRestaurants.length > 0) {
        const firstRestaurantId = nextRestaurants[0].id;

        const permissions = await getUserPermissions(contextUser?.chain?.id, firstRestaurantId);
        if (permissions) {
          setContextPermissions(permissions);
        }
      }

      return nextRestaurants;
    },
    [getRestaurantsQuery, setContextRestaurants, contextUser],
  );

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      await fetchRestaurantsAndPermissions();
      setIsLoading(false);
    };

    if (!skip) {
      fetch();
    } else {
      setIsLoading(false);
    }
  }, [fetchRestaurantsAndPermissions, skip]);

  return { loading: loading || isLoading, fetchRestaurantsAndPermissions };
};
