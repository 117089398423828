import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  Link as LinkMui,
  Stack,
  TextField,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { get, isEmpty, join } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { BottomSafeArea, LoadingButton, apiAxios } from '../../components';
import { AppContext } from '../../contexts/app_context';
import { createScopedI18n, i18n } from '../../i18n/i18n';
import tokenManager from '../../utils/token_manager';

import type { SubmitHandler } from 'react-hook-form';

const signInPageI18n = createScopedI18n('pages.sign_in');
const signInFieldI18n = createScopedI18n('graphql.fields.sign_in');
const signInErrorI18n = createScopedI18n('graphql.errors.types.sign_in.fields', { joinOutput: true });

const SignInSchema = Yup.object({
  password: Yup.string().required(signInErrorI18n('password.blank')),
  username: Yup.string().required(signInErrorI18n('username.blank')),
});
type SignInFormData = Yup.InferType<typeof SignInSchema>;

export const SignInPage = () => {
  const { setContextIsLogin } = React.useContext(AppContext);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignInFormData>({
    defaultValues: {
      password: undefined,
      username: undefined,
    },
    resolver: yupResolver(SignInSchema),
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const submitHandler: SubmitHandler<SignInFormData> = async (values) => {
    setErrorMessage(null);

    try {
      const response = await apiAxios({
        method: 'post',
        url: '/users/sign_in/',
        data: {
          password: values.password,
          username: values.username,
        },
      });
      const accessToken = response.headers['access-token'];
      const refreshToken = response.headers['refresh-token'];

      if (accessToken && refreshToken) {
        const { user }: { user: { id: string; email: string; username?: string } } = jwtDecode(accessToken);
        localStorage.setItem('userInfo', JSON.stringify(user));

        await tokenManager.clearTokens();
        await tokenManager.setToken(accessToken);
        await tokenManager.setRefreshToken(refreshToken);

        // setContextIsLogin and LoggedInRedirectGuard will redirect
        setContextIsLogin(true);
      } else {
        setErrorMessage(i18n.t('general.error'));
      }
    } catch (error) {
      if (get(error, ['response', 'status']) === 422) {
        setErrorMessage(signInPageI18n('invalid_user_password'));
      } else {
        const serverErrorMessage = get(error, ['response', 'data', 'non_field_errors', 0]);
        const errors = get(error, ['response', 'data', 'errors']);
        setErrorMessage(serverErrorMessage || join(errors, ', ') || get(error, ['message']));
      }
    }
  };

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Stack gap={2}>
          <Stack width="100%" alignItems="center">
            <LinkMui href="https://findtemp.in.th/" target="_blank" rel="noopener">
              <Box
                component="img"
                src="/logo.png"
                alt={i18n.t('general.logo_alt')}
                height={240}
                width={240}
                sx={{ objectFit: 'contain', aspectRatio: 1 }}
              />
            </LinkMui>
          </Stack>

          <Controller
            name="username"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <FormLabel>{signInFieldI18n('username')}</FormLabel>
                <TextField
                  id={field.name}
                  type="tel"
                  fullWidth
                  autoComplete="tel"
                  value={value ?? ''}
                  error={invalid}
                  helperText={error?.message}
                  {...field}
                />
              </FormControl>
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <FormLabel>{signInFieldI18n('password')}</FormLabel>
                <TextField
                  id={field.name}
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  autoComplete="current-password"
                  value={value ?? ''}
                  error={invalid}
                  helperText={error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              </FormControl>
            )}
          />

          <Collapse in={!isEmpty(errorMessage)}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>
          <LoadingButton type="submit" fullWidth variant="contained" disabled={isSubmitting} loading={isSubmitting}>
            {i18n.t('general.sign_in')}
          </LoadingButton>
          <FormHelperText>{signInPageI18n('sign_in_cookies')}</FormHelperText>

          <Stack flexDirection="row" justifyContent="space-around" gap={2}>
            <Button fullWidth component={Link} to="/auth/register">
              {i18n.t('graphql.types.register')}
            </Button>
            <Button fullWidth component={Link} to="/auth/reset_password">
              {i18n.t('graphql.types.reset_password')}
            </Button>
          </Stack>

          <Stack alignItems="center">
            <Button
              variant="outlined"
              href="https://findtemp.in.th"
              target="_blank"
              rel="noopener"
              sx={{ paddingX: 6 }}
            >
              {signInPageI18n('more_info')}
            </Button>
          </Stack>
        </Stack>
      </form>

      <BottomSafeArea />
    </Container>
  );
};
