import React from 'react';

import { Navigate } from 'react-router-dom';
import { AppContext } from '../contexts/app_context';

export const LoggedInRedirectGuard = ({ children }: { children: React.ReactNode }) => {
  const { contextIsLogin, contextRedirectFrom, setContextRedirectFrom } = React.useContext(AppContext);

  if (contextIsLogin === true) {
    if (contextRedirectFrom) {
      setTimeout(() => setContextRedirectFrom(null), 0);
    }

    return <Navigate to={contextRedirectFrom ?? '/'} replace />;
  } else {
    return children;
  }
};
