import React, { useMemo, useState } from 'react';

import { AddBusiness, ArrowDropDown, Search, Store } from '@mui/icons-material';
import {
  Avatar,
  Button,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { includes, isEmpty, map, partition } from 'lodash';
import { Link } from 'react-router-dom';
import type { ButtonProps } from '@mui/material';
import { AppContext } from '../../contexts/app_context';
import { createScopedI18n } from '../../i18n/i18n';
import { useGetPermissions } from '../../utils/useGetPermissions';
import type { RestaurantType } from '../../contexts/app_context';

const restaurantSelectorI18n = createScopedI18n('components.restaurant_selector');

export type RestaurantSelectorProps = ButtonProps & {
  group?: boolean;
};

export const RestaurantSelector = React.forwardRef(
  (
    { sx, group, ...props }: RestaurantSelectorProps,
    ref: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined,
  ) => {
    const {
      contextRestaurants,
      contextCurrentRestaurant,
      contextRestaurantSelectorOnClickMap,
      setContextCurrentRestaurant,
      setContextPermissions,
      contextUser,
    } = React.useContext(AppContext);

    const { getUserPermissions } = useGetPermissions();

    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
    const menuOpenHandler = (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchor(event.currentTarget);
    };
    const menuCloseHandler = () => setMenuAnchor(null);

    const selectRestaurantHandlerHigherOrder = (restaurant: RestaurantType) => async () => {
      menuCloseHandler();

      if (contextRestaurantSelectorOnClickMap) {
        const onClick = contextRestaurantSelectorOnClickMap[restaurant.id];
        onClick && onClick(restaurant);
      }

      setContextCurrentRestaurant(restaurant);
      if (contextUser?.id && restaurant?.id) {
        const newPermission = await getUserPermissions(contextUser?.id, restaurant.id);
        if (newPermission) {
          setContextPermissions(newPermission || null);
        }
      }
    };

    const [filterInput, setFilterInput] = useState('');
    const filteredRestaurants = useMemo(() => {
      const _filteredRestaurants = contextRestaurants || [];
      if (filterInput === '') return _filteredRestaurants;
      return _filteredRestaurants.filter(
        (res) => includes(res.name, filterInput) || includes(res.branchName, filterInput),
      );
    }, [contextRestaurants, filterInput]);

    const [hiringRestaurants, notHiringRestaurants] = useMemo(() => {
      if (!filteredRestaurants) return [[], []]; // Handle case where filteredRestaurants is not ready
      return partition(filteredRestaurants, (i) => i.isHiring);
    }, [filteredRestaurants]);

    return (
      <>
        <Button
          ref={ref}
          color="inherit"
          size="small"
          onClick={menuOpenHandler}
          sx={{ minHeight: 48, textTransform: 'none', textAlign: 'inherit', ...sx }}
          {...props}
        >
          <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
            <Avatar
              src={contextCurrentRestaurant?.frontPhotoUri}
              sx={{ display: { xs: 'none', sm: 'inherit' }, marginRight: '4px' }}
            >
              <Store />
            </Avatar>

            <Stack width={{ xs: 'calc(100% - 20px)', sm: 'calc(100% - 64px)' }} paddingLeft={{ sm: '4px' }}>
              {contextCurrentRestaurant ? (
                <>
                  <Typography noWrap display="inline-block" variant="caption">
                    {contextCurrentRestaurant.name}
                  </Typography>
                  <Typography noWrap display="inline-block" variant="subtitle2">
                    {contextCurrentRestaurant.branchName}
                  </Typography>
                </>
              ) : (
                <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
                  {restaurantSelectorI18n('select_restaurant')}
                </Typography>
              )}
            </Stack>

            <Stack width="20px">
              <ArrowDropDown
                fontSize="small"
                sx={{ transform: menuAnchor ? 'rotate(180deg)' : 'rotate(360deg)', transition: '0.2s ease' }}
              />
            </Stack>
          </Stack>
        </Button>

        <Menu
          open={!!menuAnchor}
          anchorEl={menuAnchor}
          onClose={menuCloseHandler}
          slotProps={{ paper: { sx: { width: 330 } } }}
        >
          <MenuList disablePadding>
            <MenuItem>
              <TextField
                fullWidth
                autoFocus
                variant="standard"
                placeholder={restaurantSelectorI18n('filter_restaurant', { joinOutput: true })}
                value={filterInput}
                onKeyDown={(event) => event.stopPropagation()}
                onChange={(event) => setFilterInput(event.target.value)}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </MenuItem>
            {isEmpty(filteredRestaurants) ? (
              <MenuItem
                component={Link}
                to="/restaurants/new"
                divider
                onClick={menuCloseHandler}
                sx={{ gap: 1, height: 48 }}
              >
                <ListItemIcon sx={{ width: 40, justifyContent: 'center' }}>
                  <AddBusiness />
                </ListItemIcon>
                {restaurantSelectorI18n('add_restaurant')}
              </MenuItem>
            ) : group ? (
              <>
                <div style={{ marginBottom: '34px' }}>
                  <p style={{ paddingLeft: '16px', marginBottom: '5px' }}>กำลังประกาศจ้าง</p>
                  {map(hiringRestaurants, (restaurant) => (
                    <MenuItem key={restaurant.id} divider onClick={selectRestaurantHandlerHigherOrder(restaurant)}>
                      <Avatar src={restaurant.frontPhotoUri} sx={{ marginRight: '4px' }}>
                        <Store />
                      </Avatar>

                      <Stack width="calc(100% - 44px)" paddingLeft="4px">
                        <Typography noWrap display="inline-block" variant="caption">
                          {restaurant.name}
                        </Typography>
                        <Typography noWrap display="inline-block" variant="subtitle2">
                          {restaurant.branchName}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <p style={{ paddingLeft: '16px', marginBottom: '5px' }}>ไม่มีประกาศจ้าง</p>
                  {map(notHiringRestaurants, (restaurant) => (
                    <MenuItem key={restaurant.id} divider onClick={selectRestaurantHandlerHigherOrder(restaurant)}>
                      <Avatar src={restaurant.frontPhotoUri} sx={{ marginRight: '4px' }}>
                        <Store />
                      </Avatar>

                      <Stack width="calc(100% - 44px)" paddingLeft="4px">
                        <Typography noWrap display="inline-block" variant="caption">
                          {restaurant.name}
                        </Typography>
                        <Typography noWrap display="inline-block" variant="subtitle2">
                          {restaurant.branchName}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </div>
              </>
            ) : (
              <div>
                {map(filteredRestaurants, (restaurant) => (
                  <MenuItem key={restaurant.id} divider onClick={selectRestaurantHandlerHigherOrder(restaurant)}>
                    <Avatar src={restaurant.frontPhotoUri} sx={{ marginRight: '4px' }}>
                      <Store />
                    </Avatar>

                    <Stack width="calc(100% - 44px)" paddingLeft="4px">
                      <Typography noWrap display="inline-block" variant="caption">
                        {restaurant.name}
                      </Typography>
                      <Typography noWrap display="inline-block" variant="subtitle2">
                        {restaurant.branchName}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </div>
            )}
          </MenuList>
        </Menu>
      </>
    );
  },
);
