import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { LoadingSpinner } from '.';
import { GOOGLE_MAPS_API_KEY } from '../config';

import type { GoogleMapProps, Libraries } from '@react-google-maps/api';

const googleMapsLibraries: Libraries = ['places'];

export const GoogleMapsBase = ({ children, ...props }: GoogleMapProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries,
  });

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  return <GoogleMap {...props}>{children}</GoogleMap>;
};
