import React from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import { Button, Chip, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material';
import { isEmpty, map } from 'lodash';
import { AppContext } from '../../contexts/app_context';
import { i18n } from '../../i18n/i18n';
import { formatDate } from '../../utils/libs';

import type { ButtonProps } from '@mui/material';
import type { DateType } from '../../contexts/app_context';

export type DateSelectorProps = ButtonProps;

export const DateSelector = React.forwardRef<HTMLButtonElement, DateSelectorProps>(({ sx, ...props }, ref) => {
  const { contextDates } = React.useContext(AppContext);

  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
  const menuOpenHandler = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const menuCloseHandler = () => setMenuAnchor(null);

  const selectDateHandlerHigherOrder = (date: DateType) => () => {
    menuCloseHandler();
    date.onClick && date.onClick(date);
  };

  return (
    <>
      <Button
        ref={ref}
        color="inherit"
        size="small"
        onClick={menuOpenHandler}
        sx={{ minHeight: 48, textTransform: 'none', textAlign: 'inherit', ...sx }}
        {...props}
      >
        <Stack width="100%" direction="row" gap={0.5} alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
            {i18n.t('components.date_selector.select_date')}
          </Typography>

          <ArrowDropDown
            fontSize="small"
            sx={{ transform: menuAnchor ? 'rotate(180deg)' : 'rotate(360deg)', transition: '0.2s ease' }}
          />
        </Stack>
      </Button>

      <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={menuCloseHandler}>
        <MenuList disablePadding>
          {isEmpty(contextDates) ? (
            <MenuItem divider sx={{ justifyContent: 'center' }}>
              <Typography sx={{ opacity: 0.6 }}>{i18n.t('general.empty')}</Typography>
            </MenuItem>
          ) : (
            map(contextDates, (date) => (
              <MenuItem key={date.date} divider onClick={selectDateHandlerHigherOrder(date)} sx={{ gap: 1 }}>
                <Chip size="small" label={formatDate(date.date, 'dd')} sx={{ width: 48 }} />
                <Typography variant="inherit">{formatDate(date.date, 'DD MMM YYYY')}</Typography>
              </MenuItem>
            ))
          )}
        </MenuList>
      </Menu>
    </>
  );
});
