import React from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { isNil } from 'lodash';
import { AppContext } from '../contexts/app_context';

const getInfoCompleteStatusGql = gql(`
  query getInfoCompleteStatus{
    user {
      id
      isPdpa
      isForceChangePassword
      chain {
        id
        roles
        userRestaurantContact {
          id
          isInfoCompleted
        }
      }
    }
  }
`);

export const useLoadInfoCompleteStatusContext = ({ skip }: { skip?: boolean } = {}) => {
  const { setContextInfoCompleteStatus } = React.useContext(AppContext);

  const [isLoading, setIsLoading] = React.useState(true);
  const [getInfoCompleteStatusQuery, { loading }] = useLazyQuery(getInfoCompleteStatusGql, {
    fetchPolicy: 'network-only',
  });

  const fetchInfoCompleteStatus = React.useCallback(
    async (updateContext = true) => {
      const { data } = await getInfoCompleteStatusQuery();

      let nextInfoCompleteStatus = null;
      if (data) {
        const isInfoComplete = isNil(data?.user.chain)
          ? undefined
          : data?.user.chain?.userRestaurantContact?.isInfoCompleted ?? false;
        nextInfoCompleteStatus = {
          isPdpa: data?.user.isPdpa ?? undefined,
          isInfoComplete,
          isForceChangePassword: data?.user.isForceChangePassword ?? undefined,
        };
        if (updateContext) {
          setContextInfoCompleteStatus(nextInfoCompleteStatus);
        }
      }

      return nextInfoCompleteStatus;
    },
    [getInfoCompleteStatusQuery, setContextInfoCompleteStatus],
  );

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      await fetchInfoCompleteStatus();
      setIsLoading(false);
    };

    if (!skip) {
      fetch();
    } else {
      setIsLoading(false);
    }
  }, [fetchInfoCompleteStatus, skip]);

  return { loading: loading || isLoading, fetchInfoCompleteStatus };
};
