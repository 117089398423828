import { Box } from '@mui/material';
import { NavBar } from '../../components';
import { includes, trimEnd } from 'lodash';
import { useLocation } from 'react-router-dom';

export const ProfileEditLayout = () => {
  const location = useLocation();
  const showNavBar = includes(['/first_profile_edit','/force_change_password','/pdpa_agreement'], trimEnd(location.pathname, '/'));

  return (
    <Box display="flex" flexDirection="column">
      {showNavBar && <NavBar />}
    </Box>
  );
};
