import { createTheme } from '@mui/material';
import { light } from '@mui/material/styles/createPalette';

import type { PaletteMode, ThemeOptions } from '@mui/material';

const fontFamily = `"K2D","Roboto","Helvetica","Arial",sans-serif`;
const fontFamilyLarge = `"Mitr","Roboto","Helvetica","Arial",sans-serif`;

const themeTypography: ThemeOptions['typography'] = {
  fontFamily: fontFamily,
  h1: { fontFamily: fontFamilyLarge },
  h2: { fontFamily: fontFamilyLarge },
  h3: { fontFamily: fontFamilyLarge },
  h4: { fontFamily: fontFamilyLarge },
  h5: { fontFamily: fontFamilyLarge },
  h6: { fontFamily: fontFamilyLarge },
};

const lightTheme: ThemeOptions = {
  palette: {
    mode: 'light',

    primary: {
      main: '#4451ff',
    },
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        root: { borderColor: `color-mix(in srgb, ${light.text.primary} 16%, transparent)` },
      },
    },
  },

  typography: themeTypography,
};

const darkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',

    primary: {
      main: '#bec2ff',
    },
  },

  typography: themeTypography,
};

export const createCustomTheme = (mode: PaletteMode = 'light') => {
  const theme = createTheme(mode === 'dark' ? darkTheme : lightTheme);
  return theme;
};
