import React from 'react';

import { isNull } from 'lodash';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../contexts/app_context';

export const IsFindTempUserGuard = ({ children }: { children: React.ReactNode }) => {
  const { contextUser } = React.useContext(AppContext);

  if (isNull(contextUser?.chain)) {
    return <Navigate to="/flow_sign_out" replace />;
  } else {
    return children;
  }
};
