import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { find, isEmpty, isNil, size } from 'lodash';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/app_context';
import { i18n } from '../i18n/i18n';

const redirectTo = '/first_new_restaurant';

export const IsHaveRestaurantGuard = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  const { contextRestaurants, setContextRedirectFrom } = React.useContext(AppContext);

  const incompleteRestaurant = find(contextRestaurants, (restaurant) => restaurant.isInfoComplete === false);
  // Have incompleteRestaurant and not /restaurants/[:id]/edit
  const showDialog = !isEmpty(incompleteRestaurant) && !/\/restaurants\/\d+\/edit\/?/.test(location.pathname);

  const needRedirect = !isNil(contextRestaurants) && size(contextRestaurants) <= 0;

  if (needRedirect) {
    setTimeout(() => setContextRedirectFrom(location), 0); // TODO: Find other solution
    return <Navigate to={redirectTo} replace />;
  } else {
    return (
      <>
        {children}

        <Dialog open={showDialog}>
          <DialogTitle>{i18n.t('pages.restaurants_new.restaurant_incomplete')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {i18n.t('pages.restaurants_new.restaurant_incomplete_hint_branch', {
                name: incompleteRestaurant?.name,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button component={Link} to={`/restaurants/${incompleteRestaurant?.id}/edit`}>
              {i18n.t('pages.restaurants_new.to_edit')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};
