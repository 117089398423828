import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createScopedI18n } from '../i18n/i18n';

const i18n = createScopedI18n('components.credit_not_enough_popup');

export type CreditNotEnoughPopupProps = {
  open?: boolean;
  onClosed?: (state: 'confirm' | 'cancel') => void;
};

export const CreditNotEnoughPopup = ({ open = false, onClosed }: CreditNotEnoughPopupProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleCancel = () => {
    if (onClosed) onClosed('cancel');
    setIsOpen(false);
  };

  const handleConfirm = () => {
    goToPurchaseCredit();
    if (onClosed) onClosed('confirm');
    setIsOpen(false);
  };

  const goToPurchaseCredit = () => {
    navigate('/credit/purchase', { replace: false });
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{i18n('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{i18n('description')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{i18n('close')}</Button>
        <Button onClick={handleConfirm} autoFocus>
          {i18n('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
