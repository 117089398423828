import { gql, useMutation, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import { Button, Collapse, Container, List, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { get, map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner, useTrackPageView } from '../components';
import { formatDate, formatTime } from '../utils/libs';

const getNotificationsGql = gql(`
  query getNotifications {
    notifications {
      id
      title
      body
      data
      read
      sendAt
    }
  }
`);

const markNotificationReadGql = gql(`
  mutation markNotificationRead($notificationReceiptId: ID!) {
    markNotificationRead(notificationReceiptId: $notificationReceiptId) {
      success
      errors
    }
  }
`);

export const NotificationsPage = () => {
  const navigate = useNavigate();

  useTrackPageView('NotificationListPage');

  const { data, loading, refetch } = useQuery(getNotificationsGql, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [markNotificationReadMutation] = useMutation(markNotificationReadGql);
  const markNotificationReadHandler = async (notificationId: string) => {
    try {
      markNotificationReadMutation({
        variables: { notificationReceiptId: notificationId },
        update: (cache, result) => {
          if (result.data?.markNotificationRead.success) {
            cache.updateQuery({ query: getNotificationsGql }, (data) => {
              if (data) {
                return {
                  ...data,
                  notifications: map(data.notifications, (notification) =>
                    notification.id === notificationId ? { ...notification, read: true } : notification,
                  ),
                };
              }
            });
          }
        },
      });
    } catch (error) {
      // ¯\_(ツ)_/¯
    }
  };

  const openNotificationHandlerHigherOrder = (notificationId: string, data: unknown, isRead: boolean) => () => {
    if (!isRead) {
      markNotificationReadHandler(notificationId);
    }

    const forwardUri: string | undefined = get(data, 'forwardUri');
    if (forwardUri) {
      window.open(forwardUri, '_blank', 'noopener,noreferrer')?.focus();
    }

    const routeName: string | undefined = get(data, 'routeName');
    const routeParams: unknown = get(data, 'params');
    if (routeName) {
      switch (routeName) {
        case 'CreditUsage': {
          navigate('/credit/usage');
          break;
        }
        case 'DateCandidateList':
        case 'DateJobList':
        case 'JobList': {
          navigate('/jobs');
          break;
        }
        case 'JobDetail': {
          const jobId = get(routeParams, 'jobId');
          if (jobId) {
            navigate(`/jobs/${jobId}`);
          }
          break;
        }
      }
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <Stack>
        <Button onClick={() => refetch()} endIcon={<Refresh />} sx={{ flex: 0, alignSelf: 'flex-end' }}>
          อัปเดต
        </Button>
      </Stack>

      <List>
        {map(data?.notifications, (notification) => (
          <ListItemButton
            key={notification.id}
            divider
            onClick={openNotificationHandlerHigherOrder(notification.id, notification.data, notification.read)}
          >
            <ListItemText
              primary={notification.title}
              secondary={
                <>
                  {notification.body}
                  <Typography display="block" variant="caption" textAlign="end">
                    {dayjs(notification.sendAt).isSame(dayjs(), 'day')
                      ? formatTime(notification.sendAt)
                      : formatDate(notification.sendAt, 'DD MMMM YYYY - HH:mm')}
                  </Typography>
                </>
              }
              primaryTypographyProps={{ fontWeight: notification.read ? undefined : 'bold' }}
              secondaryTypographyProps={{ fontWeight: notification.read ? undefined : 'bold' }}
            />
          </ListItemButton>
        ))}
      </List>
    </Container>
  );
};
