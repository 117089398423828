import { forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useTheme from '@mui/material/styles/useTheme';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SnackbarSuccessVariantProps extends CustomContentProps {}

const SnackbarSuccessVariant = forwardRef<HTMLDivElement, SnackbarSuccessVariantProps>(({ id, message }, ref) => {
  const theme = useTheme();

  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Card style={{ backgroundColor: theme.palette.success.main }}>
        <CardActions>
          <Typography variant="body2" color={theme.palette.success.contrastText} style={{ marginLeft: '8px' }}>
            {message}
          </Typography>
          <IconButton
            size="small"
            onClick={handleDismiss}
            sx={{ color: theme.palette.success.contrastText }}
            style={{ marginLeft: '8px' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

export default SnackbarSuccessVariant;
