import { OptionsObject, enqueueSnackbar } from 'notistack';
import { createScopedI18n } from '../i18n/i18n';
import { TranslateOptions } from 'i18n-js/typings/typing';

const errorVariantI18n = createScopedI18n('errors.messages', { joinOutput: true });
const successVariantI18n = createScopedI18n('components.snackbar_success_variant', { joinOutput: true });

// List of errors we handled
export const INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE';

const errorMessages = (options?: TranslateOptions) => {
  return {
    [INSUFFICIENT_BALANCE]: errorVariantI18n('insufficient_balance', options),
  };
};

export type SnackbarErrorCode = typeof INSUFFICIENT_BALANCE;

// List of success message codes
export const SIGN_OUT_CHANGE_PHONE_NUMBER = 'SIGN_OUT_CHANGE_PHONE_NUMBER';
export const SIGN_OUT_CHANGE_PASSWORD = 'SIGN_OUT_CHANGE_PASSWORD';

const successMessages = (options?: TranslateOptions) => {
  return {
    [SIGN_OUT_CHANGE_PHONE_NUMBER]: successVariantI18n('sign_out_change_phone_number', options),
    [SIGN_OUT_CHANGE_PASSWORD]: successVariantI18n('sign_out_change_password', options),
  };
};

export type SnackbarSuccessCode = keyof ReturnType<typeof successMessages>;

const Snackbar = () => {
  /**
   * calls `enqueueSnackbar` from `notistack` but feeze style like error snackbar
   *
   * `enqueueError('CODE_WE_HANDLED', { someArgumentPassToTranslation: 'Something passed' })`
   *
   * @param code handled code would we define
   * @param translateOptions see in i18n
   */
  const enqueueError = (code: SnackbarErrorCode, translateOptions?: TranslateOptions) => {
    const message: string = errorMessages(translateOptions)[code];
    enqueueSnackbar(message, { variant: 'snackbarErrorVariant', persist: true });
  };

  /**
   * calls `enqueueSnackbar` from `notistack` but feeze style like success snackbar
   *
   * `enqueueSuccess('CODE_WE_HANDLED', { someArgumentPassToTranslation: 'Something passed' }, { someOptionsForSnackbar: 'I'm hungry' })`
   *
   * @param code handled code would we define
   * @param translateOptions see in i18n
   * @param snackbarOptions options for `notistack`
   */
  const enqueueSuccess = (
    code: SnackbarSuccessCode,
    translateOptions?: TranslateOptions,
    snackbarOptions?: OptionsObject<'snackbarSuccessVariant'>,
  ) => {
    const message = successMessages(translateOptions)[code];
    enqueueSnackbar(message, { variant: 'snackbarSuccessVariant', ...snackbarOptions });
  };

  return {
    enqueueError,
    enqueueSuccess,
  };
};

export default Snackbar();
