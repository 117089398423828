import { has } from 'lodash';

export const CREATE_JOB = 'CREATE_JOB';

const Stash = (name: string) => {
  const storage = () => {
    const data = localStorage.getItem(name);
    if (data === null || data === '') return {};
    return JSON.parse(data);
  };

  const clean = () => {
    localStorage.removeItem(name);
  };

  const load = (key: string) => {
    const items = storage();
    if (!has(items, key)) return {};
    return items[key];
  };

  const save = (key: string, values: object) => {
    const items = storage();
    localStorage.setItem(name, JSON.stringify({ ...items, [key]: values }));
  };

  return {
    load,
    save,
    clean,
  };
};

export default Stash;
