import axios from 'axios';
import { compact, concat, isPlainObject, merge, noop } from 'lodash';
import { useEffect, useState } from 'react';
import { APP_NAME, APP_VERSION } from '../config';
import { getDeviceUUID } from '../utils/libs';
import manager from '../utils/token_manager';

import type { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, Method } from 'axios';

export const apiAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'App-Name': APP_NAME,
    'App-Version': APP_VERSION,
  },
  transformRequest: compact(
    concat((data) => {
      if (isPlainObject(data)) {
        return merge({ deviceId: getDeviceUUID() }, data);
      } else {
        return data;
      }
    }, axios.defaults.transformRequest),
  ),
});

export const apiAxiosAuth = async (
  method: Method = 'GET',
  url: string,
  config?: AxiosRequestConfig<unknown> | undefined,
) =>
  apiAxios.request({
    url,
    method,
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${await manager.getToken()}`,
    },
  });

const useHttpRequest = <Type>({
  url,
  method,
  headers,
  data,
  onSuccess = noop,
  onError = noop,
}: {
  url: string;
  method?: 'get' | 'post' | 'patch' | 'delete';
  headers?: AxiosRequestHeaders;
  data?: Type;
  onSuccess?: (response: AxiosResponse) => void;
  onError?: (error: unknown) => void;
}) => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const sendHttpRequest = async () => {
      setIsLoading(true);

      try {
        const resp = await apiAxios({
          method,
          url,
          headers,
          data: method === 'get' ? null : data,
          params: method === 'get' ? data : null,
        });

        setResponse(resp);
        onSuccess(resp);
      } catch (error) {
        onError(error);
      }

      setIsLoading(false);
    };

    sendHttpRequest();
  }, [data, headers, method, onError, onSuccess, url]);

  return { response, isLoading };
};

export default useHttpRequest;
