import React from 'react';

import { MyLocation } from '@mui/icons-material';
import { Fab, FabProps } from '@mui/material';
import { LoadingSpinner } from '.';

export type GeolocationButtonProps = {
  onGeolocationChange?: (location: { latitude: number; longitude: number }, position: GeolocationPosition) => void;
  onGeolocationError?: (message: string, error?: GeolocationPositionError) => void;
  geolocationOptions?: PositionOptions;
} & FabProps;

export const GeolocationButton = ({
  onGeolocationChange,
  onGeolocationError,
  onClick,
  geolocationOptions,
  ...props
}: GeolocationButtonProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const getGeolocationSuccessHandler = (position: GeolocationPosition) => {
    const location = { latitude: position.coords.latitude, longitude: position.coords.longitude };
    onGeolocationChange && onGeolocationChange(location, position);

    setIsLoading(false);
  };

  const getGeolocationFailedHandler = (error: GeolocationPositionError) => {
    onGeolocationError && onGeolocationError(error.message, error);

    setIsLoading(false);
  };

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick && onClick(event);

    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        getGeolocationSuccessHandler,
        getGeolocationFailedHandler,
        geolocationOptions,
      );
    } else {
      onGeolocationError && onGeolocationError('Not supported');
    }
  };

  return (
    <Fab onClick={onClickHandler} {...props}>
      {isLoading ? <LoadingSpinner size={24} color="inherit" BoxProps={{ flex: 0 }} /> : <MyLocation />}
    </Fab>
  );
};
