import React from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Collapse, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { map } from 'lodash';
import { JobHiringsCard2 } from './job_hirings_card_2';

export type RestaurantJobHiringsGroupProps = {
  restaurant: {
    id?: string;
    name?: string | null;
    branchName?: string | null;
  };
  jobIds: string[];
};

export const RestaurantJobHiringsGroup = ({ restaurant, jobIds }: RestaurantJobHiringsGroupProps) => {
  const [expand, setExpand] = React.useState(true);

  return (
    <Stack gap={0.5}>
      <ListItemButton onClick={() => setExpand(!expand)}>
        <ListItemText
          primary={
            <Typography
              variant="h6"
              flex={1}
              sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}
            >
              {restaurant.name} {restaurant.branchName}
            </Typography>
          }
        />
        <ExpandMore sx={{ transform: expand ? 'rotate(180deg)' : 'rotate(360deg)', transition: '0.2s ease' }} />
      </ListItemButton>

      <Collapse in={expand}>
        <Stack gap={0.5}>
          {map(jobIds, (jobId) => (
            <JobHiringsCard2 key={jobId} jobId={jobId}></JobHiringsCard2>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
