import React from 'react';

import { TextField } from '@mui/material';
import { toNumber } from 'lodash';

import type { TextFieldProps } from '@mui/material';

export type NumberTextFieldProps = {
  onNumberChange?: (value: number | null) => void;
  disableSpinButton?: boolean;
  disableScroll?: boolean;
} & TextFieldProps;

export const NumberTextField = React.forwardRef<HTMLDivElement, NumberTextFieldProps>(
  ({ disableScroll = true, disableSpinButton = true, onChange, onNumberChange, inputProps, sx, ...props }, ref) => {
    const onChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
      const value = event.target.value;
      const valueNumber = toNumber(value);

      onChange && onChange(event);
      onNumberChange && onNumberChange(valueNumber);
    };

    const inputOnWheelHandler: React.WheelEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
      if (disableScroll) {
        event.currentTarget.blur();
      }
      inputProps?.onWheel && inputProps.onWheel(event);
    };

    return (
      <TextField
        ref={ref}
        type="number"
        inputMode="numeric"
        onChange={onChangeHandler}
        {...props}
        inputProps={{ ...inputProps, onWheel: inputOnWheelHandler }}
        sx={[
          ...(Array.isArray(sx) ? sx : [sx]),
          disableSpinButton && {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' },
          },
        ]}
      ></TextField>
    );
  },
);
