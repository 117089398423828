import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/app_context';

const redirectTo = '/first_profile_edit';

export const IsInfoCompleteGuard = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  const { contextInfoCompleteStatus, setContextRedirectFrom } = React.useContext(AppContext);

  const needRedirect = contextInfoCompleteStatus?.isInfoComplete === false;

  if (needRedirect) {
    setTimeout(() => setContextRedirectFrom(location), 0); // TODO: Find other solution
    return <Navigate to={redirectTo} replace />;
  } else {
    return children;
  }
};
