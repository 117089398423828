import React from 'react';

import { Check } from '@mui/icons-material';
import { Avatar, Box, Fade, Link } from '@mui/material';

import type { AvatarProps, BoxProps, LinkProps } from '@mui/material';

export type SelectableAvatarProps = {
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLSpanElement>;
  selectOverlay?: React.ReactNode;
  linkProps?: LinkProps;
  overlayBoxProps?: BoxProps;
} & AvatarProps;

export const SelectableAvatar = ({
  selected,
  selectOverlay,
  onClick,
  linkProps,
  overlayBoxProps,
  ...props
}: SelectableAvatarProps) => {
  const onClickHandler = onClick
    ? (event: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        onClick(event);
      }
    : undefined;

  return (
    <Link
      onClick={onClickHandler}
      {...linkProps}
      sx={onClickHandler ? { ':hover': { cursor: 'pointer' }, ...linkProps?.sx } : linkProps?.sx}
    >
      <Box position="relative" width="fit-content">
        <Avatar {...props} />
        <Fade in={selected}>
          <Box display="flex" position="absolute" top={0} bottom={0} left={0} right={0}>
            {selectOverlay ?? (
              <Box
                display="flex"
                width="100%"
                height="100%"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                bgcolor={'rgba(0, 0, 0, 0.5)'}
                {...overlayBoxProps}
              >
                {overlayBoxProps?.children ?? <Check fontSize="medium" sx={{ color: 'white' }} />}
              </Box>
            )}
          </Box>
        </Fade>
      </Box>
    </Link>
  );
};
