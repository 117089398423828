import React from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { includes, isNil } from 'lodash';
import { AppContext } from '../contexts/app_context';
import tokenManager from '../utils/token_manager';

import type { UserType } from '../contexts/app_context';

const getUserRoleInfoGql = gql(`
  query getUserRoleInfo {
    user {
      id
      chain {
        id
        roles
      }
    }
  }
`);

export const useLoadUserInfoContext = ({ skip }: { skip?: boolean } = {}) => {
  const { setContextUser } = React.useContext(AppContext);

  const [isLoading, setIsLoading] = React.useState(true);
  const [getUserRoleInfoQuery, { loading }] = useLazyQuery(getUserRoleInfoGql, {
    fetchPolicy: 'network-only',
  });

  const loadUserInfoLocalStorage = React.useCallback(
    (updateContext = true) => {
      const userInfoJson = localStorage.getItem('userInfo');

      let userInfo: UserType | null = null;
      if (userInfoJson) {
        try {
          userInfo = JSON.parse(userInfoJson);
        } catch (error) {
          // Do not thing
        }
      }

      if (updateContext && userInfo) {
        setContextUser(userInfo);
      }

      return userInfo;
    },
    [setContextUser],
  );

  const fetchUserInfo = React.useCallback(
    async (updateContext = true) => {
      const accessToken = await tokenManager.getToken();
      const { data: userRoleData } = await getUserRoleInfoQuery();

      const { user: tokenUser }: { user: { id: string; email: string; username?: string } } = jwtDecode(accessToken);

      let userInfo: UserType | null = null;
      if (tokenUser) {
        userInfo = {
          id: tokenUser.id,
          email: tokenUser.email,
          username: tokenUser.username,
          chain: userRoleData?.user?.chain,
          permissions: includes(userRoleData?.user?.chain?.roles, 'restaurant_root') ? ['edit_profile'] : [], // TODO: remove this and fix edit_profile as soon as possible
        };

        if (updateContext) {
          setContextUser(userInfo);
        }
      }

      return userInfo;
    },
    [getUserRoleInfoQuery, setContextUser],
  );

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      let userInfo = loadUserInfoLocalStorage(false);
      const needFetch =
        isNil(userInfo) ||
        isNil(userInfo.email) ||
        isNil(userInfo.username) ||
        isNil(userInfo.chain) ||
        isNil(userInfo.permissions);

      if (needFetch) {
        userInfo = await fetchUserInfo(false);
      }

      if (userInfo) {
        setContextUser(userInfo);
      }
      setIsLoading(false);
    };

    if (!skip) {
      fetch();
    } else {
      setIsLoading(false);
    }
  }, [fetchUserInfo, loadUserInfoLocalStorage, setContextUser, skip]);

  return { loading: loading || isLoading, fetchUserInfo, loadUserInfoLocalStorage };
};
