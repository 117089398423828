import { Button, CircularProgress } from '@mui/material';

import type { ButtonProps, CircularProgressProps } from '@mui/material';

export type LoadingButtonProps = {
  loading?: boolean;
  CircularProgressProps?: CircularProgressProps;
} & ButtonProps;

export const LoadingButton = <C extends React.ElementType>({
  loading,
  startIcon,
  children,
  CircularProgressProps,
  ...props
}: ButtonProps<C, { component?: C }> & LoadingButtonProps) => {
  return (
    <Button
      startIcon={loading ? <CircularProgress color="inherit" size={16} {...CircularProgressProps} /> : startIcon}
      {...props}
    >
      {children}
    </Button>
  );
};
