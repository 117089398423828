const RUN_ENV = import.meta.env.RUN_ENV;
const APP_NAME = import.meta.env.VITE_APP_NAME;
const APP_VERSION = import.meta.env.VITE_APP_VERSION;
const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
const FCM_VAPID_KEY = import.meta.env.VITE_FCM_VAPID_KEY;

const GB_PRIME_PAY_CARD_TOKEN_URL = import.meta.env.VITE_GB_PRIME_PAY_CARD_TOKEN_URL;
const GB_PRIME_PAY_PUBLIC_KEY = import.meta.env.VITE_GB_PRIME_PAY_PUBLIC_KEY;

const GOLDEN_RATIO = 1.618033988749894;

export {
  APP_NAME,
  APP_VERSION,
  GOLDEN_RATIO,
  GB_PRIME_PAY_CARD_TOKEN_URL,
  GB_PRIME_PAY_PUBLIC_KEY,
  RUN_ENV,
  GOOGLE_MAPS_API_KEY,
  FCM_VAPID_KEY,
};
