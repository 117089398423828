import { Box } from '@mui/material';

import type { BoxProps } from '@mui/material';

const sizeMap = { none: 0, small: 2, medium: 4, large: 8, unset: undefined };

export type BottomSafeAreaProps = { size?: 'none' | 'small' | 'medium' | 'large' | 'unset' | number } & BoxProps;

export const BottomSafeArea = ({ size = 'medium', ...props }: BottomSafeAreaProps) => {
  return <Box paddingTop={typeof size === 'number' ? size : sizeMap[size]} {...props} />;
};
