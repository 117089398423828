import { Container, Stack, Typography } from '@mui/material';
import { Facebook, Phone, Web } from '@mui/icons-material'; // Import other icon components as needed
import { groupBy, map } from 'lodash';
import { Link } from 'react-router-dom';
import { BottomSafeArea, useHttpRequest, LoadingSpinner, useTrackPageView } from '../../components';
import { createScopedI18n } from '../../i18n/i18n';
import { IconLine } from '../../components/icons/icon_line';

const defaultContact = [
  {
    label: 'www.findtemp.in.th',
    type: 'website',
    uri: 'https://findtemp.in.th',
  },
  {
    label: 'findTEMP แอปพลิเคชั่นบริการพนักงานพาร์ทไทม์ส่งถึงที่',
    type: 'facebook',
    uri: 'https://www.facebook.com/findTEMP',
  },
  {
    label: '@findtemp',
    type: 'line',
    uri: 'https://lin.ee/1ggzCzc',
  },
  {
    label: '066-112-4349 เมย์',
    type: 'call',
    uri: 'tel:0661124349',
  },
  {
    label: '085-489-7354 บิ๊ก',
    type: 'call',
    uri: 'tel:0854897354',
  },
];

const contactUsPageI18n = createScopedI18n('pages.contact_us');

export const ContactUsPage = () => {
  useTrackPageView('ContactUsPage');

  const { response, isLoading } = useHttpRequest({ url: '/enums/admin_findtemp_contacts' });

  const contacts = groupBy(response?.data ?? defaultContact, (contact) => contact?.type);
  if (isLoading) {
    return <LoadingSpinner size={24} sx={{ marginTop: 2 }} />;
  }
  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <Stack gap={2}>
        <Typography style={{ fontSize: '1.2rem' }}>{contactUsPageI18n('title')}</Typography>

        <Stack style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
            <Stack style={{ marginLeft: 2, width: '24px', height: '24px' }}>
              <IconLine />
            </Stack>
            <Typography style={{ fontSize: 'large' }}>Line</Typography>
          </Stack>
          <Stack style={{ paddingLeft: 44 }}>
            {map(contacts?.line, ({ label, uri }) => (
              <Link to={uri} target="_blank" style={{ textDecoration: 'none' }}>
                <Typography style={{ paddingTop: 4, paddingBottom: 4, color: '#bebebe', fontSize: 'small' }}>
                  {label}
                </Typography>
              </Link>
            ))}
          </Stack>
        </Stack>

        <Stack style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
            <Phone style={{ marginLeft: 2 }} />
            <Typography style={{ fontSize: 'large' }}>{contactUsPageI18n('phone_number')}</Typography>
          </Stack>
          <Stack style={{ paddingLeft: 44 }}>
            {map(contacts?.call, ({ label, uri }) => (
              <Link to={uri} target="_blank" style={{ textDecoration: 'none' }}>
                <Typography style={{ paddingTop: 4, paddingBottom: 4, color: '#bebebe', fontSize: 'small' }}>
                  {label}
                </Typography>
              </Link>
            ))}
          </Stack>
        </Stack>

        <Stack style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
            <Facebook style={{ marginLeft: 2 }} />
            <Typography style={{ fontSize: 'large' }}>Facebook</Typography>
          </Stack>
          <Stack style={{ paddingLeft: 44 }}>
            {map(contacts?.facebook, ({ label, uri }) => (
              <Link to={uri} target="_blank" style={{ textDecoration: 'none' }}>
                <Typography style={{ paddingTop: 4, paddingBottom: 4, color: '#bebebe', fontSize: 'small' }}>
                  {label}
                </Typography>
              </Link>
            ))}
          </Stack>
        </Stack>

        <Stack style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
            <Web style={{ marginLeft: 2 }} />
            <Typography style={{ fontSize: 'large' }}>{contactUsPageI18n('website')}</Typography>
          </Stack>
          <Stack style={{ paddingLeft: 44 }}>
            {map(contacts?.website, ({ label, uri }) => (
              <Link to={uri} target="_blank" style={{ textDecoration: 'none' }}>
                <Typography style={{ paddingTop: 4, paddingBottom: 4, color: '#bebebe', fontSize: 'small' }}>
                  {label}
                </Typography>
              </Link>
            ))}
          </Stack>
        </Stack>
      </Stack>

      <BottomSafeArea size="large" />
    </Container>
  );
};
