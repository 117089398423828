import React from 'react';

import { useTheme } from '@mui/material';
import { AppContext } from '../contexts/app_context';
import Analytic from '../utils/analytic';

const useSetAnalyticUserProperties = () => {
  const theme = useTheme();
  const { contextUser } = React.useContext(AppContext);

  React.useEffect(() => {
    Analytic().setUserId(contextUser?.id ?? null);
  }, [contextUser?.id]);

  React.useEffect(() => {
    Analytic().setUserProperties({ theme_mode: theme.palette.mode });
  }, [theme.palette.mode]);
};

export default useSetAnalyticUserProperties;
