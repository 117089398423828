import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { Alert, Collapse, Container, Link as LinkMui, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingButton } from '../components';
import { APP_NAME } from '../config';
import { AppContext } from '../contexts/app_context';

const acceptPdpaGql = gql(`
  mutation acceptPdpa {
    acceptPdpa {
      success
    }
  }
`);

export const PdpaAgreementPage = () => {
  const { contextRedirectFrom, setContextRedirectFrom, setContextInfoCompleteStatus } = React.useContext(AppContext);
  const navigate = useNavigate();

  const [acceptPdpaMutation] = useMutation(acceptPdpaGql);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const submitHandler = async () => {
    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      const { data } = await acceptPdpaMutation();

      if (data?.acceptPdpa.success) {
        setContextInfoCompleteStatus({ isPdpa: true });
        navigate(contextRedirectFrom ?? '/', { replace: true });
        setContextRedirectFrom(null);
      }
    } catch (error) {
      setErrorMessage(get(error, 'message', null));
    }

    setIsSubmitting(false);
  };

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <Stack gap={2}>
        <Typography variant="h6" color="primary">
          ขอใช้ข้อมูลส่วนบุคคล
        </Typography>
        <Typography>
          เพื่อให้คุณสามารถเข้าถึงผลิตภัณฑ์และบริการของ {APP_NAME} รวมถึงพันธมิตรทางธุรกิจ และผู้ให้บริการภายในแพลตฟอร์ม
          คุณยินยอมให้ {APP_NAME} เก็บรวบรวม ใช้ และเปิดเผยข้อมูลของคุณ ตามที่ระบุใน{' '}
          <LinkMui href="https://www.findtemp.in.th/privacy-policy" target="_blank" rel="noopener">
            นโยบายการคุ้มครองข้อมูลส่วนบุคคล
          </LinkMui>{' '}
          และมีผลต่อ{' '}
          <LinkMui href="https://www.findtemp.in.th/term-condition" target="_blank" rel="noopener">
            เงื่อนไขการให้บริการ
          </LinkMui>{' '}
          ของ {APP_NAME}
        </Typography>

        <Collapse in={!!errorMessage}>
          <Stack width="100%">
            <Alert severity="error">{errorMessage}</Alert>
          </Stack>
        </Collapse>
        <Stack flexDirection="row" gap={1}>
          <LoadingButton
            variant="contained"
            color="error"
            disabled={isSubmitting}
            loading={isSubmitting}
            fullWidth
            component={Link}
            to="/flow_sign_out"
          >
            ปฏิเสธ
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            loading={isSubmitting}
            fullWidth
            onClick={submitHandler}
          >
            ยอมรับ
          </LoadingButton>
        </Stack>
      </Stack>
    </Container>
  );
};
