import React from 'react';

export type ProtectorState = 'valid' | 'invalid' | null;

interface IProtector {
  state: ProtectorState;
  setState: React.Dispatch<React.SetStateAction<ProtectorState>>;
}

const ProtectorContext = React.createContext<IProtector | null>(null);

export const useProtectorContext = () => {
  const context = React.useContext(ProtectorContext);
  if (!context) {
    throw new Error('Only should be used on the protection scope');
  }
  return context;
};
export default ProtectorContext;
