import React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Close, EditCalendar, Remove, Schedule, Star } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link as LinkMui,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {
  clamp,
  concat,
  filter,
  find,
  get,
  includes,
  isEmpty,
  isNil,
  join,
  map,
  noop,
  pick,
  reduce,
  snakeCase,
  some,
  uniq,
} from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-multi-date-picker';
import { Link, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import type { SubmitHandler } from 'react-hook-form';
import type { DateObject } from 'react-multi-date-picker';
import {
  BottomSafeArea,
  LoadingButton,
  LoadingSpinner,
  NumberTextField,
  RadioButtonGroup,
  useGoBack,
  useTrackPageView,
} from '../../components';
import { RestaurantSelector } from '../../components/nav_bar/restaurant_selector';
import { AppContext } from '../../contexts/app_context';
import { createScopedI18n, i18n } from '../../i18n/i18n';
import { hookFormNestedFieldErrorMessage, joinPairs, labelFieldEnum } from '../../utils/libs';
import Protector from '../../components/protector';
import { CreditNotEnoughPopup } from '../../components/credit_not_enough_popup';
import Snackbar, { INSUFFICIENT_BALANCE } from '../../utils/snackbar';
import Stash, { CREATE_JOB } from '../../utils/stash';

const jobCreatePageI18n = createScopedI18n('pages.jobs_new');
const jobFieldI18n = createScopedI18n('graphql.fields.job');
const jobErrorI18n = createScopedI18n('graphql.errors.types.job.fields', { joinOutput: true });
const walletErrorI18n = createScopedI18n('graphql.errors.types.wallet.fields', { joinOutput: true });

const MAX_SCHEDULE_DAYS = 12;

const JobCreateSchema = Yup.object({
  scheduleDates: Yup.array()
    .of(
      Yup.lazy(() =>
        Yup.date()
          .max(
            dayjs().endOf('day').add(MAX_SCHEDULE_DAYS, 'days'),
            jobErrorI18n('schedule_date.too_long', { count: MAX_SCHEDULE_DAYS }),
          )
          .required(jobErrorI18n('schedule_date.blank')),
      ),
    )
    .required(jobErrorI18n('schedule_dates.blank'))
    .min(1, jobErrorI18n('schedule_dates.too_short', { count: 1 })),
  startTime: Yup.date()
    .typeError(jobErrorI18n('job_start_at.invalid'))
    .required(jobErrorI18n('job_start_at.blank'))
    .test({ message: jobErrorI18n('job_start_at.invalid'), test: (startTime) => startTime.getMinutes() % 30 === 0 }),
  endTime: Yup.date()
    .typeError(jobErrorI18n('job_end_at.invalid'))
    .required(jobErrorI18n('job_end_at.blank'))
    .test({ message: jobErrorI18n('job_end_at.invalid'), test: (endTime) => endTime.getMinutes() % 30 === 0 }),
  breakDurationMinute: Yup.number()
    .required(jobErrorI18n('break_duration.blank'))
    .min(0, jobErrorI18n('break_duration.too_short', { count: 0 })),
  gender: Yup.string()
    .required(jobErrorI18n('gender.blank'))
    .oneOf(['male', 'female', 'SET_NULL_ON_SUBMIT'], jobErrorI18n('gender.invalid')),
  jobType: Yup.string().required(jobErrorI18n('job_type.blank')),
  duties: Yup.array()
    .of(Yup.string().required(jobErrorI18n('duty.blank')))
    .required(jobErrorI18n('duty.blank'))
    .when('jobType', ([jobType], schema) => {
      if (isNil(jobType) || jobType === 'other') {
        return schema;
      }
      return schema.min(1, jobErrorI18n('duty.blank'));
    }),
  remark: Yup.string()
    .nullable()
    .when('jobType', ([jobType], schema) =>
      isNil(jobType) || jobType === 'other' ? schema.required(jobErrorI18n('remark.blank')) : schema,
    ),
  detail: Yup.string().nullable(),
  numberOfPosition: Yup.number()
    .required(jobErrorI18n('number_of_position.blank'))
    .min(1, jobErrorI18n('number_of_position.too_short', { count: 1 })),
  uniform: Yup.string().required(jobErrorI18n('uniform.blank')),
  benefit: Yup.string().required(jobErrorI18n('benefit.blank')),
});

const mapValueKeyToI18nField = (valueKey: string) => {
  const valueKeyI18nFieldMap: { [valueKey: string]: string } = {
    scheduleDates: 'schedule_dates',
    startTime: 'start_time',
    endTime: 'end_time',
    breakDurationMinute: 'break_duration_minute',
    gender: 'gender',
    jobType: 'job_type',
    duties: 'duty',
    remark: 'remark',
    detail: 'detail',
    numberOfPosition: 'number_of_position',
    uniform: 'uniform',
    benefit: 'benefit',
  };

  return valueKeyI18nFieldMap[valueKey] ?? snakeCase(valueKey);
};

interface JobCreateFormData {
  scheduleDates: Date[];
  startTime: Date;
  endTime: Date;
  breakDurationMinute: number;
  gender: string;
  jobType: string;
  duties: string[];
  remark: string | null | undefined;
  detail: string | null | undefined;
  numberOfPosition: number;
  uniform: string;
  benefit: string;
}

const jobCreatingOptionGql = gql(`
  query jobCreatingOption($businessType: BUSINESS_TYPE_OPTIONS!) {
    jobCreatingOption(businessType: $businessType) {
      maxNumberOfPosition
      otHour
      pricing {
        id
        price
        priceType
      }
      cancelFee {
        id
        price
        priceType
      }
      enumJobTypesAndDuties {
        id
        duties
        defaultDuties
      }
    }
  }
`);

const createJobGql = gql(`
  mutation createJob(
    $restaurantId: ID
    $benefit: String
    $breakDurationMinute: Int!
    $numberOfPosition: Int!
    $duties: JSON
    $endTime: Time!
    $jobType: String
    $gender: GENDER_OPTIONS
    $remark: String
    $scheduleDate: Date!
    $startTime: Time!
  ) {
    createJob(
      restaurantId: $restaurantId
      benefit: $benefit
      breakDurationMinute: $breakDurationMinute
      duties: $duties
      endTime: $endTime
      jobTypeV2: $jobType
      gender: $gender
      remark: $remark
      scheduleDate: $scheduleDate
      startTime: $startTime
      numberOfPosition: $numberOfPosition
    ) {
      success
      errors
    }
  }
`);

const hasUrgentJob = (scheduleDates: Date[]) => {
  const tomorrowEndDay = dayjs().add(1, 'day').endOf('day');
  return some(scheduleDates, (scheduleDate) => dayjs(scheduleDate).isSameOrBefore(tomorrowEndDay));
};
const hasNormalJob = (scheduleDates: Date[]) => {
  const tomorrowEndDay = dayjs().add(1, 'day').endOf('day');
  return some(scheduleDates, (scheduleDate) => dayjs(scheduleDate).isAfter(tomorrowEndDay));
};

const JobCreatePage = () => {
  const goBack = useGoBack();

  const { contextCurrentRestaurant } = React.useContext(AppContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const datePickerRef = React.useRef<any>(null);

  useTrackPageView('NewJobPage');

  const [showDialog, setShowDialog] = React.useState(false);
  const [isNotEnoughCredit, setIsNotEnoughCredit] = React.useState(false);
  const openDialogHandler = () => setShowDialog(true);
  const closeDialogHandler = () => setShowDialog(false);

  const [showRestaurantIncompleteDialog, setShowRestaurantIncomplete] = React.useState(false);
  const closeRestaurantIncompleteDialogHandler = () => setShowRestaurantIncomplete(false);

  React.useEffect(() => {
    if (contextCurrentRestaurant?.isInfoComplete === false) {
      setShowRestaurantIncomplete(true);
    }
  }, [contextCurrentRestaurant]);
  const {
    control,
    getValues,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: {
      isSubmitting,
      touchedFields: { duties: dutiesTouched },
      errors: validateErrors,
    },
  } = useForm<JobCreateFormData>({
    defaultValues: {
      scheduleDates: [],
      breakDurationMinute: 60,
      gender: 'SET_NULL_ON_SUBMIT',
      jobType: 'other',
      duties: [],
      numberOfPosition: 1,
    },
    resolver: yupResolver(JobCreateSchema),
  });

  const scheduleDates = watch('scheduleDates');
  const jobType = watch('jobType');

  const { data, loading } = useQuery(jobCreatingOptionGql, {
    variables: { businessType: contextCurrentRestaurant?.businessType },
    skip: !contextCurrentRestaurant?.businessType,
  });

  const maxNumberOfPosition = data?.jobCreatingOption?.maxNumberOfPosition ?? 20;
  const otHour = data?.jobCreatingOption?.otHour ?? 0;

  const jobTypeOptionWithOther = React.useMemo(
    () =>
      some(data?.jobCreatingOption?.enumJobTypesAndDuties, ({ id }) => id === 'other')
        ? data?.jobCreatingOption?.enumJobTypesAndDuties
        : concat(data?.jobCreatingOption?.enumJobTypesAndDuties ?? [], { id: 'other', duties: [], defaultDuties: [] }),
    [data?.jobCreatingOption?.enumJobTypesAndDuties],
  );
  const jobDutyOptions = find(jobTypeOptionWithOther, (jobAndDuty) => jobAndDuty.id === jobType);

  const urgentPricing = reduce(
    data?.jobCreatingOption?.pricing,
    (prev, { priceType, price }) => ({ ...prev, [priceType]: price }),
    {},
  );
  const cancelFees = reduce(
    data?.jobCreatingOption?.cancelFee,
    (prev, { priceType, price }) => ({ ...prev, [priceType]: price }),
    {},
  );

  const numberOfPositionHandler = (positionNumber: number | null) => {
    if (isNil(positionNumber)) {
      // @ts-expect-error Let user fix this by enter something to form.
      setValue('numberOfPosition', null);
      return;
    }

    let nextValue = positionNumber;
    if (!Number.isFinite(nextValue)) {
      return;
    }

    if (maxNumberOfPosition !== -1) {
      nextValue = clamp(nextValue, 0, maxNumberOfPosition);
    } else {
      nextValue = nextValue < 0 ? 0 : nextValue;
    }
    setValue('numberOfPosition', nextValue);
  };

  React.useEffect(() => {
    if (contextCurrentRestaurant === null) return;

    const stash = Stash(CREATE_JOB);
    const key = contextCurrentRestaurant.id.toString();
    const values = stash.load(key);

    // monkey patch
    if (values.startTime) values.startTime = dayjs(values.startTime);
    if (values.endTime) values.endTime = dayjs(values.endTime);

    if (values.jobType && !find(jobTypeOptionWithOther, ({ id }) => id === values.jobType)) {
      values.jobType = 'other';
    }

    reset(values, { keepDefaultValues: true });
  }, [contextCurrentRestaurant, reset, jobTypeOptionWithOther]);

  React.useEffect(() => {
    if (jobType === 'other' || isNil(jobType)) {
      setValue('duties', []);
    }

    if (!dutiesTouched) {
      setValue('duties', jobDutyOptions?.defaultDuties ?? []);
    }
  }, [dutiesTouched, jobDutyOptions?.defaultDuties, jobType, setValue]);

  const jobDutiesHandler = (checked: boolean, value: string) => {
    let newDuties = getValues('duties');
    if (checked) {
      newDuties = uniq([value, ...newDuties]);
    } else {
      newDuties = filter(newDuties, (duty) => duty !== value);
    }
    setValue('duties', newDuties, { shouldTouch: true });
  };

  const [createJobMutation] = useMutation(createJobGql);
  const [errorMessagePerDate, setErrorMessagePerDate] = React.useState<{ date: Date; errorMessage: string | null }[]>(
    [],
  );

  const prefillFormData = (prefillData: JobCreateFormData) => {
    if (contextCurrentRestaurant === null) return;

    const stash = Stash(CREATE_JOB);
    const key = contextCurrentRestaurant.id.toString();
    const values = pick(prefillData, [
      'startTime',
      'endTime',
      'breakDurationMinute',
      'gender',
      'jobType',
      'duties',
      'remark',
      'detail',
      'numberOfPosition',
      'uniform',
      'benefit',
    ]);
    stash.save(key, values);
  };

  const submitHandler: SubmitHandler<JobCreateFormData> = async (values) => {
    if (!contextCurrentRestaurant?.id) return;

    if (contextCurrentRestaurant.isInfoComplete === false) {
      setShowRestaurantIncomplete(true);
      return;
    }

    setErrorMessagePerDate([]);
    let nextErrorMessagePerDate: { date: Date; errorMessage: string | null }[] = [];
    const variable = {
      restaurantId: contextCurrentRestaurant.id.toString(),
      startTime: dayjs(values.startTime).format('HH:mm'),
      endTime: dayjs(values.endTime).format('HH:mm'),
      breakDurationMinute: values.breakDurationMinute,
      gender: values.gender === 'SET_NULL_ON_SUBMIT' ? null : values.gender,
      jobType: values.jobType,
      duties: values.jobType === 'other' ? [] : values.duties,
      remark: join(
        values.jobType === 'other' ? [values.uniform, values.detail, values.remark] : [values.uniform, values.detail],
        '\n\n',
      ),
      numberOfPosition: values.numberOfPosition,
      benefit: values.benefit,
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const scheduleDate of values.scheduleDates) {
      try {
        // TODO: CACHE Update /jobs cache
        // eslint-disable-next-line no-await-in-loop
        const { data: jobData } = await createJobMutation({
          variables: {
            ...variable,
            scheduleDate: dayjs(scheduleDate).format('YYYY-MM-DD'),
          },
        });

        if (!jobData?.createJob.success) {
          const errorMessage = joinPairs(jobData?.createJob.errors);
          nextErrorMessagePerDate = [...nextErrorMessagePerDate, { date: scheduleDate, errorMessage }];

          if (errorMessage.includes(walletErrorI18n('amount.not_enough'))) {
            setIsNotEnoughCredit(true);
            Snackbar.enqueueError(INSUFFICIENT_BALANCE);
          }
        } else {
          // TODO: avoid loop
          prefillFormData(values);
        }
      } catch (error) {
        nextErrorMessagePerDate = [
          ...nextErrorMessagePerDate,
          { date: scheduleDate, errorMessage: get(error, 'message', null) },
        ];
      }
    }

    setErrorMessagePerDate(nextErrorMessagePerDate);

    if (isEmpty(nextErrorMessagePerDate)) {
      goBack();
    }
  };

  if (isNil(contextCurrentRestaurant?.businessType)) {
    return (
      <Container
        maxWidth={'sm'}
        sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Stack gap={1}>
          <Alert severity="warning">
            <AlertTitle>{i18n.t('errors.messages.restaurant_branch.blank')}</AlertTitle>
            {i18n.t('errors.messages.restaurant_branch.blank_detail')}
          </Alert>
          <RestaurantSelector variant="outlined" fullWidth sx={{ padding: 1 }} />
        </Stack>
      </Container>
    );
  }

  if (contextCurrentRestaurant?.isInfoComplete === false) {
    return (
      <Container
        maxWidth={'sm'}
        sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Stack gap={1}>
          <Alert severity="warning">
            <AlertTitle>{i18n.t('pages.restaurants_new.restaurant_incomplete')}</AlertTitle>
            {i18n.t('pages.restaurants_new.restaurant_incomplete_hint')}
          </Alert>
          <Button component={Link} to={`/restaurants/${contextCurrentRestaurant.id}/edit`} variant="outlined">
            {i18n.t('pages.restaurants_new.to_edit')}
          </Button>
        </Stack>
      </Container>
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Protector permissions={['jobCreate']}>
        <Protector.Rejected>
          <Navigate to="/error" replace />
        </Protector.Rejected>
      </Protector>
      <Container maxWidth="sm" sx={{ paddingY: 2 }}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Grid container gap={2}>
            <Grid container item gap={1}>
              <Grid item xs={3}>
                <Stack minHeight={56} flexDirection={'row'} alignItems={'center'}>
                  <FormLabel error={isNil(contextCurrentRestaurant)}>{jobCreatePageI18n('restaurant')} *</FormLabel>
                </Stack>
              </Grid>
              <Grid container item xs={9} sx={{ marginRight: -1 }}>
                <RestaurantSelector fullWidth />
              </Grid>
            </Grid>

            <Controller
              key="scheduleDates"
              name="scheduleDates"
              control={control}
              render={({ field: { name, onChange, onBlur, value }, fieldState: { invalid, error } }) => (
                <FormControl id={name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <Grid item xs={3}>
                      <FormLabel>{jobFieldI18n('schedule_dates')} *</FormLabel>
                    </Grid>
                    <Grid item xs="auto">
                      <DatePicker
                        ref={datePickerRef}
                        multiple
                        name={name}
                        minDate={dayjs().startOf('day').toDate()}
                        maxDate={dayjs().endOf('day').add(MAX_SCHEDULE_DAYS, 'days').toDate()}
                        value={value}
                        onChange={(dates: DateObject[] | null) => {
                          onChange(map(dates, (date) => date.toDate()));
                          onBlur();
                        }}
                        render={
                          <IconButton>
                            <EditCalendar />
                          </IconButton>
                        }
                      />
                    </Grid>
                    <Grid item xs overflow={'auto'}>
                      <Button
                        color="inherit"
                        fullWidth
                        size="small"
                        onClick={() => datePickerRef.current?.openCalendar()}
                        sx={{ justifyContent: 'flex-start', minHeight: 48 }}
                      >
                        {isEmpty(scheduleDates) ? (
                          <Typography variant="subtitle2" textAlign={'start'} sx={{ opacity: 0.6 }}>
                            {jobCreatePageI18n('schedule_date_hint', { max_date: 12 })}
                          </Typography>
                        ) : (
                          <Stack direction={'row'} gap={1}>
                            {map(scheduleDates, (date, index) => (
                              <Box
                                key={index}
                                width={40}
                                height={40}
                                borderRadius={40}
                                flexShrink={0}
                                display={'inline-flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                color={'primary.contrastText'}
                                bgcolor={'primary.main'}
                              >
                                <Typography>{dayjs(date).format('DD')}</Typography>
                              </Box>
                            ))}
                          </Stack>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container item gap={1} justifyContent={'flex-end'}>
                    <Grid item xs={9}>
                      <FormHelperText>
                        {hookFormNestedFieldErrorMessage(error, { joinErrorMessages: false })}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />

            <Grid container item gap={1}>
              <Grid item xs={3}>
                <Stack minHeight={56} flexDirection={'row'} alignItems={'center'}>
                  <FormLabel error={!isEmpty(validateErrors.startTime) || !isEmpty(validateErrors.endTime)}>
                    {jobCreatePageI18n('job_time')} *
                  </FormLabel>
                </Stack>
              </Grid>
              <Grid item container xs>
                <Controller
                  key="startTime"
                  name="startTime"
                  control={control}
                  render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                    <FormControl id={field.name} fullWidth error={invalid}>
                      <Grid container item alignItems={'center'} gap={1}>
                        <Grid item xs="auto">
                          <FormLabel>{jobCreatePageI18n('job_time_from')}</FormLabel>
                        </Grid>
                        <Grid item xs>
                          <MobileTimePicker
                            ampm={false}
                            closeOnSelect
                            minutesStep={30}
                            value={value ?? null}
                            slotProps={{ textField: { InputProps: { endAdornment: <Schedule /> } } }}
                            {...field}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item gap={1} justifyContent={'flex-end'}>
                        <FormHelperText>{error?.message}</FormHelperText>
                      </Grid>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item container xs>
                <Controller
                  key="endTime"
                  name="endTime"
                  control={control}
                  render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                    <FormControl id={field.name} fullWidth error={invalid}>
                      <Grid container item alignItems={'center'} gap={1}>
                        <Grid item xs="auto">
                          <FormLabel>{jobCreatePageI18n('job_time_to')}</FormLabel>
                        </Grid>
                        <Grid item xs>
                          <MobileTimePicker
                            ampm={false}
                            closeOnSelect
                            minutesStep={30}
                            value={value ?? null}
                            slotProps={{ textField: { InputProps: { endAdornment: <Schedule /> } } }}
                            {...field}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item gap={1} justifyContent={'flex-end'}>
                        <FormHelperText>{error?.message}</FormHelperText>
                      </Grid>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>

            <Controller
              key="breakDurationMinute"
              name="breakDurationMinute"
              control={control}
              render={({ field: { name, onBlur, onChange, value }, fieldState: { invalid, error } }) => (
                <FormControl id={name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <Grid item xs={3}>
                      <FormLabel>{jobFieldI18n('break_duration')} *</FormLabel>
                    </Grid>
                    <Grid item xs overflow={'auto'} whiteSpace={'nowrap'}>
                      <RadioButtonGroup
                        id={name}
                        color="primary"
                        value={value}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        items={[
                          { label: jobFieldI18n('break_duration_hour.zero'), value: 0, },
                          { label: jobFieldI18n('break_duration_hour.one'), value: 60, },
                          { label: jobFieldI18n('break_duration_hour.one_and_half'), value: 90, },
                          { label: jobFieldI18n('break_duration_hour.two'), value: 120, },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item gap={1} justifyContent={'flex-end'}>
                    <Grid item xs={9}>
                      <FormHelperText>{error?.message}</FormHelperText>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />

            <Controller
              key="gender"
              name="gender"
              control={control}
              render={({ field: { name, onBlur, onChange, value }, fieldState: { invalid, error } }) => (
                <FormControl id={name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <Grid item xs={3}>
                      <FormLabel>{jobFieldI18n('gender')} *</FormLabel>
                    </Grid>
                    <Grid item xs overflow={'auto'} whiteSpace={'nowrap'}>
                      <RadioButtonGroup
                        id={name}
                        color="primary"
                        value={value}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        items={[
                          { label: labelFieldEnum('job.genders', 'male'), value: 'male' },
                          { label: labelFieldEnum('job.genders', 'female'), value: 'female' },
                          { label: labelFieldEnum('job.genders', 'unspecified'), value: 'SET_NULL_ON_SUBMIT' },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item gap={1} justifyContent={'flex-end'}>
                    <Grid item xs={9}>
                      <FormHelperText>{error?.message}</FormHelperText>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />

            <Controller
              key="jobType"
              name="jobType"
              control={control}
              render={({ field: { name, onBlur, onChange, value }, fieldState: { invalid, error } }) => (
                <FormControl id={name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <Grid item xs={3}>
                      <FormLabel>{jobFieldI18n('job_type')} *</FormLabel>
                    </Grid>
                    <Grid item xs overflow={'auto'} whiteSpace={'nowrap'}>
                      <RadioButtonGroup
                        id={name}
                        color="primary"
                        value={value}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        items={map(jobTypeOptionWithOther, (jobAndDuty) => ({
                          label: labelFieldEnum('job.job_types', jobAndDuty.id),
                          value: jobAndDuty.id,
                        }))}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item gap={1} justifyContent={'flex-end'}>
                    <Grid item xs={9}>
                      <FormHelperText>{error?.message}</FormHelperText>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />

            <Collapse key="duties" in={!isNil(jobType) && jobType !== 'other'} sx={{ width: '100%', marginBottom: -2 }}>
              <Controller
                key="duties"
                name="duties"
                control={control}
                render={({ field: { name, value: duties }, fieldState: { invalid, error } }) => (
                  <FormControl id={name} fullWidth error={invalid}>
                    <Grid container item gap={1}>
                      <Grid item xs={12}>
                        <FormLabel>{jobFieldI18n('duty')} *</FormLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup row>
                          {map(jobDutyOptions?.duties, (duty) => (
                            <Grid key={duty} item xs={12} sm={6}>
                              <FormControlLabel
                                label={labelFieldEnum('job.duties', duty)}
                                value={duty}
                                control={
                                  <Checkbox
                                    checked={includes(duties, duty)}
                                    onChange={({ target: { checked } }) => jobDutiesHandler(checked, duty)}
                                  />
                                }
                                sx={{ paddingLeft: 2 }}
                              />
                            </Grid>
                          ))}
                        </FormGroup>
                      </Grid>
                      <Grid container item>
                        <FormHelperText>{error?.message}</FormHelperText>
                      </Grid>
                    </Grid>
                  </FormControl>
                )}
              />
            </Collapse>

            <Collapse key="remark" in={isNil(jobType) || jobType === 'other'} sx={{ width: '100%', marginTop: -2 }}>
              <Controller
                key="remark"
                name="remark"
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <FormControl id={field.name} fullWidth error={invalid}>
                    <Grid container item gap={1}>
                      <FormLabel>{jobFieldI18n('remark')} *</FormLabel>
                      <TextField
                        id={field.name}
                        type="text"
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={8}
                        error={invalid}
                        placeholder={join(jobCreatePageI18n('remark_hint'))}
                        helperText={error?.message}
                        {...field}
                      />
                    </Grid>
                  </FormControl>
                )}
              />
            </Collapse>

            <Controller
              key="detail"
              name="detail"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item gap={1}>
                    <FormLabel>{jobFieldI18n('detail')} </FormLabel>
                    <TextField
                      id={field.name}
                      type="text"
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={8}
                      error={invalid}
                      placeholder={`ตัวอย่าง มีค่ามัดจำผ้ากันเปื้อน 100 บาท `}
                      helperText={error?.message}
                      {...field}
                    />
                  </Grid>
                </FormControl>
              )}
            />

            <Controller
              key="numberOfPosition"
              name="numberOfPosition"
              control={control}
              render={({
                field: { value: numberOfPosition, onChange: _onChange, ...field },
                fieldState: { invalid, error },
              }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item alignItems={'center'} gap={1}>
                    <Grid item xs={3}>
                      <FormLabel>{jobFieldI18n('number_of_position')} *</FormLabel>
                    </Grid>
                    <Grid item xs>
                      <NumberTextField
                        {...field}
                        id={field.name}
                        inputMode="numeric"
                        value={numberOfPosition.toString()}
                        onNumberChange={numberOfPositionHandler}
                        error={invalid}
                        InputProps={{
                          inputProps: { pattern: '[0-9]*', sx: { maxWidth: 64, textAlign: 'center' } },
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                disabled={(numberOfPosition ?? 0) <= 1}
                                onClick={() => numberOfPositionHandler((numberOfPosition ?? 0) - 1)}
                              >
                                <Remove />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disabled={maxNumberOfPosition !== -1 && (numberOfPosition ?? 0) >= maxNumberOfPosition}
                                onClick={() => numberOfPositionHandler((numberOfPosition ?? 0) + 1)}
                              >
                                <Add />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item gap={1} justifyContent={'flex-end'}>
                    <Grid item xs={9}>
                      <FormHelperText>{error?.message}</FormHelperText>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />

            <Controller
              key="uniform"
              name="uniform"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item gap={1}>
                    <FormLabel>{jobFieldI18n('uniform')} *</FormLabel>
                    <TextField
                      id={field.name}
                      type="text"
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={8}
                      error={invalid}
                      helperText={error?.message}
                      {...field}
                    />
                  </Grid>
                </FormControl>
              )}
            />

            <Controller
              key="benefit"
              name="benefit"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <Grid container item gap={1}>
                    <FormLabel>{jobFieldI18n('benefit')} *</FormLabel>
                    <TextField
                      id={field.name}
                      type="text"
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={8}
                      error={invalid}
                      placeholder={join(jobCreatePageI18n('benefit_hint'))}
                      helperText={error?.message}
                      {...field}
                    />
                  </Grid>
                </FormControl>
              )}
            />

            {hasUrgentJob(scheduleDates) && (
              <Grid container item gap={1}>
                <Typography variant="subtitle1" color={'primary'} alignItems={'center'} display={'inline-flex'}>
                  <Star fontSize="small" sx={{ marginRight: 1 }} />
                  {jobCreatePageI18n('create_condition_title_urgent')}
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.87 }}>
                  {jobCreatePageI18n('create_condition_description_urgent_1')}
                  <Typography variant="body2" sx={{ textDecoration: 'underline' }} display="inline">
                    {jobCreatePageI18n('create_condition_description_urgent_2_underline')}
                  </Typography>
                  {jobCreatePageI18n('create_condition_description_urgent_3')}
                </Typography>

                <Grid container item justifyContent={'center'}>
                  <Grid container item xs={12} sm={10}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">{jobCreatePageI18n('date_duration')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{jobCreatePageI18n('work_day')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{jobCreatePageI18n('wage')}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell rowSpan={7}>
                            <Typography variant="body2">{jobCreatePageI18n('next_day')}</Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">{i18n.t('datetime.day_of_week.weekday')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {jobCreatePageI18n('pricing_per_hour', {
                                price: get(urgentPricing, 'restaurant_hiring_next_day_weekday'),
                              })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {otHour > 0 && get(urgentPricing, 'restaurant_hiring_next_day_weekday_ot') ? (
                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('price_ot_rate')}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('pricing_per_hour', {
                                  price: get(urgentPricing, 'restaurant_hiring_next_day_weekday_ot'),
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : null}

                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">{i18n.t('datetime.day_of_week.weekend')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {jobCreatePageI18n('pricing_per_hour', {
                                price: get(urgentPricing, 'restaurant_hiring_next_day_weekend'),
                              })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {otHour > 0 && get(urgentPricing, 'restaurant_hiring_next_day_weekend_ot') ? (
                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('price_ot_rate')}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('pricing_per_hour', {
                                  price: get(urgentPricing, 'restaurant_hiring_next_day_weekend_ot'),
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : null}

                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">{i18n.t('datetime.day_of_week.holiday')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {jobCreatePageI18n('pricing_per_hour', {
                                price: get(urgentPricing, 'restaurant_hiring_next_day_holiday'),
                              })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {otHour > 0 && get(urgentPricing, 'restaurant_hiring_next_day_holiday_ot') ? (
                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('price_ot_rate')}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('pricing_per_hour', {
                                  price: get(urgentPricing, 'restaurant_hiring_next_day_holiday_ot'),
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>

                      <TableBody>
                        <TableRow>
                          <TableCell rowSpan={7}>
                            <Typography variant="body2">{jobCreatePageI18n('same_day')}</Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">{i18n.t('datetime.day_of_week.weekday')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {jobCreatePageI18n('pricing_per_hour', {
                                price: get(urgentPricing, 'restaurant_hiring_same_day_weekday'),
                              })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {otHour > 0 && get(urgentPricing, 'restaurant_hiring_same_day_weekday_ot') ? (
                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('price_ot_rate')}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('pricing_per_hour', {
                                  price: get(urgentPricing, 'restaurant_hiring_same_day_weekday_ot'),
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : null}

                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">{i18n.t('datetime.day_of_week.weekend')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {jobCreatePageI18n('pricing_per_hour', {
                                price: get(urgentPricing, 'restaurant_hiring_same_day_weekend'),
                              })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {otHour > 0 && get(urgentPricing, 'restaurant_hiring_same_day_weekend_ot') ? (
                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('price_ot_rate')}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('pricing_per_hour', {
                                  price: get(urgentPricing, 'restaurant_hiring_same_day_weekend_ot'),
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : null}

                        <TableRow>
                          <TableCell>
                            <Typography variant="body2">{i18n.t('datetime.day_of_week.holiday')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {jobCreatePageI18n('pricing_per_hour', {
                                price: get(urgentPricing, 'restaurant_hiring_same_day_holiday'),
                              })}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {otHour > 0 && get(urgentPricing, 'restaurant_hiring_same_day_holiday_ot') ? (
                          <TableRow>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('price_ot_rate')}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="text.secondary">
                                {jobCreatePageI18n('pricing_per_hour', {
                                  price: get(urgentPricing, 'restaurant_hiring_same_day_holiday_ot'),
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>

                <Grid container item gap={0.5}>
                  <Typography variant="body2" sx={{ opacity: 0.6 }}>
                    {jobCreatePageI18n('create_condition_description_urgent_4')}
                    <Typography variant="body2" sx={{ textDecoration: 'underline' }} display="inline">
                      {jobCreatePageI18n('create_condition_description_urgent_5_underline')}
                    </Typography>
                  </Typography>
                  {otHour > 0 ? (
                    <Typography variant="body2" sx={{ opacity: 0.87 }}>
                      {jobCreatePageI18n('create_condition_ot_rate', { ot_hour: otHour })}
                    </Typography>
                  ) : null}
                  <Typography variant="body2" sx={{ opacity: 0.87 }}>
                    {jobCreatePageI18n('create_condition_description_urgent_6', {
                      cancel_fee: get(cancelFees, 'restaurant_cancel_same_day'),
                    })}{' '}
                    <LinkMui onClick={openDialogHandler} sx={{ '&:hover': { cursor: 'pointer' } }}>
                      <Typography
                        variant="body2"
                        color={'primary'}
                        sx={{ textDecoration: 'underline' }}
                        display="inline"
                        onClick={noop}
                      >
                        {i18n.t('general.see_more_detail')}
                      </Typography>
                    </LinkMui>
                  </Typography>
                </Grid>
              </Grid>
            )}

            {hasNormalJob(scheduleDates) && (
              <Grid container item gap={0.5}>
                <Typography variant="subtitle1" color="primary" alignItems="center" display="inline-flex" width="100%">
                  <Star fontSize="small" sx={{ marginRight: 1 }} />
                  {jobCreatePageI18n('create_condition_title_normal')}
                </Typography>

                {otHour > 0 ? (
                  <Typography variant="body2" sx={{ opacity: 0.87 }}>
                    {jobCreatePageI18n('create_condition_ot_rate', { ot_hour: otHour })}
                  </Typography>
                ) : null}
                <Typography variant="body2" sx={{ opacity: 0.87 }}>
                  {jobCreatePageI18n('create_condition_description_normal')}{' '}
                  <LinkMui onClick={openDialogHandler} sx={{ '&:hover': { cursor: 'pointer' } }}>
                    <Typography variant="body2" display="inline">
                      {i18n.t('general.see_more_detail')}
                    </Typography>
                  </LinkMui>
                </Typography>
              </Grid>
            )}

            <Collapse in={!isEmpty(validateErrors)} unmountOnExit sx={{ width: '100%' }}>
              <Alert severity="error" sx={{ width: '100%' }}>
                {map(validateErrors, (error, type) => (
                  <Typography key={type}>
                    • {jobFieldI18n(mapValueKeyToI18nField(type))}{' '}
                    {hookFormNestedFieldErrorMessage(error, { joinErrorMessages: false })}
                  </Typography>
                ))}
              </Alert>
            </Collapse>

            <Collapse in={!isEmpty(errorMessagePerDate)} unmountOnExit sx={{ width: '100%' }}>
              {map(errorMessagePerDate, ({ date, errorMessage }, index) => (
                <Alert key={index} severity="error">
                  {jobCreatePageI18n('error_message_per_date', {
                    date: dayjs(date).format('DD'),
                    error_message: errorMessage,
                  })}
                </Alert>
              ))}
            </Collapse>

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {i18n.t('general.done')}
            </LoadingButton>
          </Grid>
        </form>
        <Dialog open={showDialog} onClose={closeDialogHandler} PaperProps={{ sx: { width: '100%' } }}>
          <DialogTitle flexDirection={'row'} alignItems={'center'} display={'inline-flex'}>
            <DialogTitle>{jobCreatePageI18n('create_condition_title')}</DialogTitle>
            <div style={{ flex: 1 }} />
            <Box>
              <IconButton onClick={closeDialogHandler}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container gap={2}>
              <Typography>
                {jobCreatePageI18n('create_condition_detail_1_1')}
                <Typography sx={{ textDecoration: 'underline' }} display={'inline'}>
                  {jobCreatePageI18n('create_condition_detail_1_2_underline')}
                </Typography>
                {jobCreatePageI18n('create_condition_detail_1_3')}
              </Typography>
              <Typography>
                {jobCreatePageI18n('create_condition_detail_2_1')}
                <Typography sx={{ textDecoration: 'underline' }} display={'inline'}>
                  {jobCreatePageI18n('create_condition_detail_2_2_underline')}
                </Typography>
                {jobCreatePageI18n('create_condition_detail_2_3')}
              </Typography>
              <Typography>
                {jobCreatePageI18n('create_condition_detail_3_1')}
                <Typography sx={{ textDecoration: 'underline' }} display={'inline'}>
                  {jobCreatePageI18n('create_condition_detail_3_2_underline')}
                </Typography>
                {jobCreatePageI18n('create_condition_detail_3_3')}
              </Typography>
              <Typography>
                {jobCreatePageI18n('create_condition_detail_4_1')}
                <Typography sx={{ textDecoration: 'underline' }} display={'inline'}>
                  {jobCreatePageI18n('create_condition_detail_4_2_underline')}
                </Typography>{' '}
                {jobCreatePageI18n('create_condition_detail_4_3')}
              </Typography>
              <Grid container item justifyContent={'center'}>
                <Grid item xs={12} sm={9}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>{jobCreatePageI18n('date_duration')}</TableCell>
                        <TableCell>{jobFieldI18n('cancel_fee')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{jobCreatePageI18n('cancel_3_days')}</TableCell>
                        <TableCell>
                          {jobCreatePageI18n('cancel_fee_per_time_per_position', {
                            cancel_fee: get(cancelFees, 'restaurant_cancel_normal'),
                          })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{jobCreatePageI18n('cancel_2_days')}</TableCell>
                        <TableCell>
                          {jobCreatePageI18n('cancel_fee_per_time_per_position', {
                            cancel_fee: get(cancelFees, 'restaurant_cancel_next_day'),
                          })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{jobCreatePageI18n('cancel_1_day')}</TableCell>
                        <TableCell>
                          {jobCreatePageI18n('cancel_fee_per_time_per_position', {
                            cancel_fee: get(cancelFees, 'restaurant_cancel_same_day'),
                          })}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <Grid container item gap={2} paddingLeft={4}>
                <Typography>
                  {jobCreatePageI18n('create_condition_detail_41_1')}{' '}
                  {jobCreatePageI18n('cancel_fee_per_time_per_position', {
                    cancel_fee: get(cancelFees, 'restaurant_cancel_normal'),
                  })}
                </Typography>
                <Typography>
                  {jobCreatePageI18n('create_condition_detail_42_1')}{' '}
                  {jobCreatePageI18n('cancel_fee_per_time_per_position', {
                    cancel_fee: get(cancelFees, 'restaurant_cancel_next_day'),
                  })}
                </Typography>
                <Typography>
                  {jobCreatePageI18n('create_condition_detail_43_1')}{' '}
                  {jobCreatePageI18n('cancel_fee_per_time_per_position', {
                    cancel_fee: get(cancelFees, 'restaurant_cancel_same_day'),
                  })}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={showRestaurantIncompleteDialog}>
          <DialogTitle>{jobCreatePageI18n('restaurant_incomplete')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{jobCreatePageI18n('restaurant_incomplete_hint')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeRestaurantIncompleteDialogHandler}>
              {jobCreatePageI18n('restaurant_incomplete_to_close')}
            </Button>
            <Button
              component={Link}
              to={`/restaurants/${contextCurrentRestaurant?.id}/edit`}
              onClick={closeRestaurantIncompleteDialogHandler}
            >
              {jobCreatePageI18n('restaurant_incomplete_to_edit')}
            </Button>
          </DialogActions>
        </Dialog>

        <CreditNotEnoughPopup open={isNotEnoughCredit} onClosed={(_) => setIsNotEnoughCredit(false)} />

        <BottomSafeArea />
      </Container>
    </>
  );
};

export default JobCreatePage;
