import { Stack, useMediaQuery, useTheme } from '@mui/material';
import JobCandidateList from './job_candidate_list';
import JobHiringList from './job_hire_list';

export type JobCandidateHiringListProps = {
  jobId?: string;
};

export const JobCandidateHiringList = ({ jobId }: JobCandidateHiringListProps) => {
  const theme = useTheme();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Stack
        flexShrink={0}
        flex={isSmallWidth ? undefined : 1}
        width={isSmallWidth ? '90%' : undefined}
        maxWidth={isSmallWidth ? 360 : undefined}
        marginRight={0.5}
        marginBottom={1}
        border={1}
        borderRadius={1}
        borderColor={theme.palette.divider}
      >
        <JobCandidateList jobId={jobId} />
      </Stack>

      <Stack
        flexShrink={0}
        flex={isSmallWidth ? undefined : 1}
        width={isSmallWidth ? '90%' : undefined}
        maxWidth={isSmallWidth ? 360 : undefined}
        marginBottom={1}
        border={1}
        borderRadius={1}
        borderColor={theme.palette.divider}
      >
        <JobHiringList jobId={jobId} />
      </Stack>
    </>
  );
};
