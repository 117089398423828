import { Box } from '@mui/material';
import { Outlet } from 'react-router';
import { Footer, NavBar } from '../../components';
import { includes, trimEnd } from 'lodash';
import { useLocation } from 'react-router-dom';

export const AuthLayout = () => {
  const location = useLocation();
  const showNavBar = !includes(['/auth/sign_in','/auth/thank_you'], trimEnd(location.pathname, '/'));

  return (
    <Box display="flex" height="100svh" flexDirection="column">
      {showNavBar && <NavBar />}
      <Outlet />

      <div style={{ flexGrow: 1 }} />

      <Footer />
    </Box>
  );
};
