import React from 'react';

import { useLocation } from 'react-router-dom';

export const useRouteLocationRef = () => {
  const location = useLocation();

  const locationRef = React.useRef(location);
  React.useEffect(() => {
    locationRef.current = location;
  }, [location]);

  return locationRef;
};
