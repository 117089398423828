import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Collapse, Container, FormControl, Grid, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { get, replace, size, slice, split } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import type { SubmitHandler } from 'react-hook-form';
import { BottomSafeArea, LoadingButton } from '../../components';
import { GB_PRIME_PAY_CARD_TOKEN_URL, GB_PRIME_PAY_PUBLIC_KEY } from '../../config';

const CheckOutCreditCardSchema = Yup.object({
  cardNumber: Yup.string().required().min(12).max(19),
  expirationDate: Yup.string()
    .required()
    .matches(/0[1-9]|1[0-2]\/[0-9]{2}/),
  cvc: Yup.string().required().min(3).max(4),
});

const formatExpirationDate = (expirationDate: string | null | undefined) => {
  if (expirationDate && size(expirationDate) > 2) {
    const expirationDateTrim = replace(expirationDate, '/', '').trim();
    return [slice(expirationDateTrim, 0, 2).join(''), slice(expirationDateTrim, 2).join('')].join('/');
  }
  return expirationDate;
};

const chargeGbPrimePayGql = gql(`
  mutation chargeGbPrimePay($satang: Int!, $token: String!, $method: PAYMENT_METHOD_OPTIONS!, $isTaxInvoice: Boolean) {
    chargeGbPrimePay(satang: $satang, token: $token, method: $method, isTaxInvoice: $isTaxInvoice) {
      success
      errors
      otpFormHtml
      redirectUrl
    }
  }
`);

type CheckOutCreditCardFormData = Yup.InferType<typeof CheckOutCreditCardSchema>;

export const CheckOutCreditCardPage = () => {
  const navigate = useNavigate();
  const { state }: { state?: { amount?: number; isTaxInvoice?: boolean } } = useLocation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(CheckOutCreditCardSchema),
  });

  const [chargeGbPrimePayMutation] = useMutation(chargeGbPrimePayGql);
  const [errorMessage, setErrorMessage] = React.useState<string | null>();
  const submitHandler: SubmitHandler<CheckOutCreditCardFormData> = async (values) => {
    if ((state?.amount ?? 0) <= 0) return;

    setErrorMessage(null);
    const [expirationMonth, expirationYear] = split(values.expirationDate, '/');

    let token: string | null = null;
    try {
      const gbPrimeResponse = await axios.post(
        GB_PRIME_PAY_CARD_TOKEN_URL,
        {
          rememberCard: false,
          card: {
            number: values.cardNumber,
            expirationMonth,
            expirationYear,
            securityCode: values.cvc,
          },
        },
        {
          headers: {
            'Authorization': `Basic ${btoa(`${GB_PRIME_PAY_PUBLIC_KEY}:`)}`, //! FIXME: Use node Buffer for string -> Base64
            'Content-Type': 'application/json',
          },
        },
      );

      if (get(gbPrimeResponse.data, 'resultCode') === '00') {
        token = get(gbPrimeResponse.data, ['card', 'token']);
      } else {
        setErrorMessage('บัตรเครดิตนี้ไม่สามารถใช้ได้');
      }
    } catch (error) {
      setErrorMessage(get(error, 'message'));
    }

    if (token) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { data } = await chargeGbPrimePayMutation({
          variables: {
            token,
            method: 'card',
            satang: (state?.amount ?? 0) * 100,
            isTaxInvoice: state?.isTaxInvoice ?? false,
          },
        });

        if (data?.chargeGbPrimePay?.success) {
          if (data?.chargeGbPrimePay?.otpFormHtml) {
            navigate('/credit/check_out_credit_card_pending', {
              state: { otpFormHtml: data?.chargeGbPrimePay?.otpFormHtml },
            });
          } else if (data?.chargeGbPrimePay?.redirectUrl) {
            navigate('/credit/check_out_credit_card_pending', {
              state: { redirectUrl: data?.chargeGbPrimePay?.redirectUrl },
            });
          }
        } else {
          const errors = data?.chargeGbPrimePay?.errors;
          setErrorMessage('ไม่สามารถชำระเงินด้วยบัตรเครดิตใบนี้ได้');
        }
      } catch (error) {
        setErrorMessage(get(error, 'message'));
      }
    }
  };

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container gap={2}>
          <Controller
            control={control}
            name="cardNumber"
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <TextField
                  id={field.name}
                  type="tel"
                  inputMode="numeric"
                  autoComplete="cc-number"
                  fullWidth
                  label="หมายเลขบัตร *"
                  value={value ?? ''}
                  placeholder="•••• •••• •••• ••••"
                  error={invalid}
                  helperText={error?.message}
                  inputProps={{ pattern: '[0-9]{12,19}' }}
                  {...field}
                />
              </FormControl>
            )}
          />

          <Grid item xs={7}>
            <Controller
              control={control}
              name="expirationDate"
              render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <TextField
                    id={field.name}
                    type="tel"
                    inputMode="numeric"
                    autoComplete="cc-exp"
                    fullWidth
                    label="วันหมดอายุ *"
                    value={formatExpirationDate(value) ?? ''}
                    placeholder="MM/YY"
                    error={invalid}
                    helperText={error?.message}
                    inputProps={{ pattern: '(0[1-9]|1[0-2])/[0-9]{2}' }}
                    {...field}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs>
            <Controller
              control={control}
              name="cvc"
              render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                <FormControl id={field.name} fullWidth error={invalid}>
                  <TextField
                    id={field.name}
                    type="password"
                    inputMode="numeric"
                    autoComplete="cc-csc"
                    fullWidth
                    label="CVC *"
                    value={value ?? ''}
                    placeholder="•••"
                    error={invalid}
                    helperText={error?.message}
                    inputProps={{ pattern: '[0-9]{3,4}' }}
                    {...field}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Stack width="100%">
            <Collapse in={!!errorMessage}>
              <Alert severity="error" sx={{ width: '100%' }}>
                {errorMessage}
              </Alert>
            </Collapse>
          </Stack>

          <LoadingButton
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
            fullWidth
            variant="contained"
            color="primary"
          >
            ยืนยัน
          </LoadingButton>
        </Grid>
      </form>
      <BottomSafeArea />
    </Container>
  );
};
