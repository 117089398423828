import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import type { StackProps } from '@mui/material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { isEmpty, map } from 'lodash';
import { CardLevel, LoadingSpinner } from '../../../components';
import { createScopedI18n } from '../../../i18n/i18n';
import StudentCandidateCard from './student_candidate_card';

import type { StudentCandidateType } from './student_candidate_card';

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');

const getJobHiringsV2Gql = gql(`
  query GetJobHiringsV2($jobId: ID) {
    jobHiringsV2(jobId: $jobId) {
      id
      status
      restaurantChargePerHour
      job {
        id
        jobType
      }
      student {
        id
        avatar
        nickName
        headline
        rating
        isWorkedHere
        lastStudentCovid19Vaccine {
          id
          nth
          vaccinedAt
        }
        studentExperiences {
          id
          jobType
          minute
        }
        studentPreExperiences
        studentCertificates {
          id
          certificateType
        }
        latestCommends
      }
    }
  }
`);

type jobHiringType = {
  id: string;
  restaurantChargePerHour?: number;
  student: StudentCandidateType;
  job: { jobType: string };
};

export type JobHiringListProps = {
  jobId?: string;
  onCardClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, jobHiringId: string) => void;
  outerStackProps?: StackProps;
};

const JobHiringList = ({ jobId, outerStackProps }: JobHiringListProps) => {
  const { data, loading, refetch } = useQuery(getJobHiringsV2Gql, {
    variables: { jobId },
    skip: !jobId,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const jobHirings: jobHiringType[] = data?.jobHiringsV2 ?? [];

  return (
    <Stack
      flex={1}
      {...outerStackProps}
      sx={[
        { overflowX: 'auto' },
        ...(Array.isArray(outerStackProps?.sx) ? outerStackProps?.sx ?? [] : [outerStackProps?.sx]),
      ]}
    >
      <Stack flexDirection="row" alignItems="center" paddingX={3} paddingY={2} minHeight={48}>
        <Typography variant="h6">{jobDetailPageI18n('job_hirings')}</Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={() => refetch()}>
          <Refresh />
        </IconButton>
      </Stack>

      <Box flex={1} sx={{ overflowY: 'scroll' }}>
        <Stack>
          {loading || isEmpty(jobHirings) ? (
            <CardLevel variant="outlined" sx={{ margin: 0.5 }}>
              {loading ? (
                <LoadingSpinner size={24} BoxProps={{ padding: 3 }} />
              ) : (
                <Stack padding={3} alignItems="center">
                  <Typography>{jobDetailPageI18n('job_hiring_empty')}</Typography>
                </Stack>
              )}
            </CardLevel>
          ) : (
            map(jobHirings, ({ id, student, restaurantChargePerHour, job: { jobType } }) => (
              <StudentCandidateCard
                key={id}
                student={student}
                restaurantChargePerHour={restaurantChargePerHour}
                jobType={jobType}
                disableDetailDialogAction
                sx={{ margin: 0.5 }}
              />
            ))
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default JobHiringList;
