import React from 'react';

import { Box, Container } from '@mui/material';

import type { ContainerProps, BoxProps } from '@mui/material';

export type ContainerFlexHeightProps = {
  outerBoxProps?: BoxProps;
} & ContainerProps;

const ContainerFlexHeight = (
  { children, outerBoxProps, ...props }: ContainerFlexHeightProps,
  ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined,
) => {
  return (
    <Box display="flex" flex={1} overflow="auto" {...outerBoxProps}>
      <Container ref={ref} {...props}>
        {children}
      </Container>
    </Box>
  );
};

export default React.forwardRef(ContainerFlexHeight);
