import React from 'react';

import { Box, Card } from '@mui/material';

import type { CardProps } from '@mui/material';

export type CardLevelProps = {
  level?: 0 | 1 | 2 | 3 | 4 | 5;
} & CardProps;

const LEVEL_OPACITY_PERCENT_MAP = {
  0: 0,
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
};

const CardLevel = (
  { level = 1, children, ...props }: CardLevelProps,
  ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined,
) => {
  const backgroundColorBase = '#000';
  const backgroundColor = `color-mix(in srgb, ${backgroundColorBase} ${LEVEL_OPACITY_PERCENT_MAP[level] ?? 0}%, transparent)`;

  return (
    <Card ref={ref} {...props}>
      <Box sx={{ backgroundColor, transition: 'background-color 200ms ease' }}>{children}</Box>
    </Card>
  );
};

export default React.forwardRef(CardLevel);
