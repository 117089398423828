import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Collapse,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { BottomSafeArea, CooldownButton, LoadingButton, apiAxios, useGoBack } from '../../components';
import { createScopedI18n, i18n } from '../../i18n/i18n';
import { joinPairs } from '../../utils/libs';

const resetPasswordPageI18n = createScopedI18n('pages.reset_password');
const resetPasswordFieldI18n = createScopedI18n('graphql.fields.reset_password');
const resetPasswordErrorI18n = createScopedI18n('graphql.errors.types.reset_password.fields', { joinOutput: true });

const ResetPasswordSchema = Yup.object({
  phoneNum: Yup.string().required(resetPasswordErrorI18n('phone_number.blank')),
  otp: Yup.string().required(resetPasswordErrorI18n('otp.blank')),
  refNum: Yup.string(),
  newPassword: Yup.string().required(resetPasswordErrorI18n('new_password.blank')),
  newPasswordConfirm: Yup.string()
    .required(resetPasswordErrorI18n('confirm_password.blank'))
    .oneOf([Yup.ref('newPassword')], resetPasswordErrorI18n('confirm_password.not_same')),
});

type ResetPasswordValueType = Yup.InferType<typeof ResetPasswordSchema>;

export const ResetPasswordPage = () => {
  const goBack = useGoBack();

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    trigger,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const phoneNum = watch('phoneNum');
  const refNum = watch('refNum');

  const [isGettingOtp, setIsGettingOtp] = React.useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = React.useState<string | null>(null);
  const [nextTryAt, setNextTryAt] = React.useState<Date | null>(null);

  const getOtpHandler = async () => {
    if (!(await trigger('phoneNum'))) {
      return;
    }

    setIsGettingOtp(true);
    setOtpErrorMessage(null);
    let nextTryAt: string | undefined = undefined;
    try {
      const {
        data: { data, success },
      } = await apiAxios({
        method: 'post',
        url: '/reset_password',
        data: {
          phone_number: phoneNum,
        },
      });

      if (success) {
        nextTryAt = get(data, 'next_try');
        setValue('refNum', get(data, 'reference_number', null));
        enqueueSnackbar(get(data, 'message'));
      } else {
        const errorMessage = get(data, 'otp') ?? get(data, 'phone') ?? null;
        setOtpErrorMessage(errorMessage);
      }
    } catch (error) {
      nextTryAt = get(error, ['response', 'data', 'data', 'next_try']);
      const errors = get(error, ['response', 'data', 'errors']);
      setOtpErrorMessage(errors ? joinPairs(errors) : get(error, ['message'], null));
    }

    setNextTryAt(nextTryAt ? dayjs(nextTryAt).toDate() : null);
    setIsGettingOtp(false);
  };

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const submitHandler = async (value: ResetPasswordValueType) => {
    setErrorMessage(null);
    try {
      const { data, status } = await apiAxios({
        method: 'patch',
        url: '/reset_password',
        data: {
          otp: value.otp,
          password: value.newPassword,
          phone_number: value.phoneNum,
          reference_number: value.refNum,
        },
      });

      if (status === 200) {
        enqueueSnackbar(resetPasswordPageI18n('success'));
        goBack();
      } else {
        const errors = get(data, 'errors');
        setErrorMessage(errors ? joinPairs(errors) : resetPasswordPageI18n('error'));
      }
    } catch (error) {
      const errors = get(error, ['response', 'data', 'errors']);
      setErrorMessage(errors ? joinPairs(errors) : get(error, ['message'], null));
    }
  };

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container gap={2}>
          <Controller
            name="phoneNum"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{resetPasswordFieldI18n('phone_number')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="tel"
                      fullWidth
                      autoComplete="tel"
                      value={value ?? ''}
                      error={invalid}
                      {...field}
                    />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            name="otp"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{resetPasswordFieldI18n('otp')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="text"
                      fullWidth
                      autoComplete="one-time-code"
                      value={value ?? ''}
                      error={invalid}
                      {...field}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CooldownButton
                      variant="contained"
                      fullWidth
                      disabled={!phoneNum || isGettingOtp}
                      cooldownUntil={nextTryAt ?? undefined}
                      onClick={getOtpHandler}
                    >
                      {resetPasswordPageI18n('get_otp')}
                    </CooldownButton>
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    {refNum && (
                      <FormHelperText error={false}>
                        {resetPasswordPageI18n('ref_num_text', { ref_num: refNum })}
                      </FormHelperText>
                    )}
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Collapse in={!!otpErrorMessage} sx={{ width: '100%' }}>
            <Alert severity="error">{otpErrorMessage}</Alert>
          </Collapse>

          <Divider sx={{ width: '100%', height: 2 }} />

          <Controller
            name="newPassword"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{resetPasswordFieldI18n('new_password')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="password"
                      fullWidth
                      autoComplete="new-password"
                      value={value ?? ''}
                      error={invalid}
                      {...field}
                    />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            name="newPasswordConfirm"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{resetPasswordFieldI18n('confirm_password')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="password"
                      fullWidth
                      autoComplete="new-password"
                      value={value ?? ''}
                      error={invalid}
                      {...field}
                    />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Collapse in={!!errorMessage} sx={{ width: '100%' }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>
          <LoadingButton type="submit" disabled={isSubmitting} loading={isSubmitting} fullWidth variant="contained">
            {i18n.t('general.confirm')}
          </LoadingButton>
        </Grid>
      </form>

      <BottomSafeArea />
    </Container>
  );
};
