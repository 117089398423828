import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Link as LinkMui,
  Radio,
  RadioGroup,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../../../components';

import type { DialogProps } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';

const getTopUpInfoGql = gql(`
  query getTopUpInfo($amount: Float!) {
    topupInfo(amount: $amount) {
      id
      credit
      bonus
      vat
      withholding
      price
      cardFee
      cardVat
      cardWithholding
      cardPrice
    }
  }
`);

const Transition = React.forwardRef(
  (props: TransitionProps & { children: React.ReactElement }, ref: React.Ref<unknown>) => {
    return <Slide ref={ref} direction="up" {...props} />;
  },
);

export type TopUpItemDetailDialogProps = {
  amount?: number | null | undefined;
  onClose?: (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | Record<string, never>,
    reason: 'backdropClick' | 'escapeKeyDown' | 'close' | 'submit',
  ) => void | undefined;
} & Omit<DialogProps, 'onClose'>;

export const TopUpItemDetailDialog = ({ amount, onClose, ...props }: TopUpItemDetailDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, loading } = useQuery(getTopUpInfoGql, {
    fetchPolicy: 'network-only',
    variables: { amount: amount ?? 0 },
    skip: isNil(amount),
  });
  const topUpInfo = data?.topupInfo;

  const [isTaxInvoice, setIsTaxInvoice] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState<'card' | 'qrCode' | 'UNSELECT'>('UNSELECT');

  const onClickHandler: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    onClose && onClose(event, 'submit');
  };

  const checkoutLink = () => {
    if (paymentMethod === 'card') {
      return { to: '/credit/check_out_credit_card', state: { amount, isTaxInvoice } };
    } else if (paymentMethod === 'qrCode') {
      return { to: '/credit/check_out_qr', state: { amount, isTaxInvoice } };
    } else {
      return { to: '' };
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      TransitionComponent={Transition}
      onClose={onClose}
      {...props}
    >
      <DialogTitle display="inline-flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        รายละเอียดการซื้อเครดิต
        <IconButton onClick={(e) => onClose && onClose(e, 'close')}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Collapse in={loading}>
          <LoadingSpinner BoxProps={{ height: '100%' }} />
        </Collapse>
        <Collapse in={!loading}>
          <Stack gap={2}>
            <Card>
              <CardContent sx={{ paddingY: 1 }}>
                <Typography variant="subtitle2" color="GrayText" textAlign="right">
                  ราคา (บาท)
                </Typography>
              </CardContent>
              <Divider />

              <CardContent>
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <Typography flex={1} variant="subtitle2">
                    {numeral(topUpInfo?.credit).format('0,0')} เครดิต
                  </Typography>
                  <Typography minWidth={80} textAlign="right">
                    {numeral(topUpInfo?.credit).format('0,0.00')}
                  </Typography>
                </Stack>
                {(topUpInfo?.bonus ?? 0) > 0 && (
                  <Stack flexDirection="row" alignItems="center" gap={0.5} marginTop={1}>
                    <Typography flex={1} variant="subtitle2">
                      โบนัส {numeral(topUpInfo?.bonus).format('0,0')} เครดิต
                    </Typography>
                    <Typography minWidth={80} textAlign="right">
                      ฟรี
                    </Typography>
                  </Stack>
                )}
                <Collapse in={paymentMethod === 'card'}>
                  <Stack flexDirection="row" alignItems="center" gap={0.5} marginTop={1}>
                    <Typography flex={1} variant="subtitle2">
                      ค่าธรรมเนียมชำระผ่านบัตรเครดิต
                    </Typography>
                    <Typography minWidth={80} textAlign="right">
                      {numeral(topUpInfo?.cardFee).format('0,0.00')}
                    </Typography>
                  </Stack>
                </Collapse>
                <Divider sx={{ marginY: 1 }} />

                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <Typography flex={1} variant="subtitle2">
                    ยอดรวม
                  </Typography>
                  <Typography minWidth={80} textAlign="right">
                    {numeral(
                      (topUpInfo?.credit ?? 0) + (paymentMethod === 'card' ? topUpInfo?.cardFee ?? 0 : 0),
                    ).format('0,0.00')}
                  </Typography>
                </Stack>
              </CardContent>
              <Divider />

              <CardContent>
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <Typography flex={1} variant="subtitle2">
                    ภาษีมูลค่าเพิ่ม 7%
                  </Typography>
                  <Typography minWidth={80} textAlign="right">
                    {numeral(paymentMethod === 'card' ? topUpInfo?.cardVat : topUpInfo?.vat).format('0,0.00')}
                  </Typography>
                </Stack>
                {topUpInfo?.withholding !== 0 && (
                  <Stack flexDirection="row" alignItems="center" gap={0.5} marginTop={1}>
                    <Typography flex={1} variant="subtitle2">
                      หักภาษี ณ ที่จ่าย 3%
                    </Typography>
                    <Typography minWidth={80} textAlign="right">
                      {numeral(paymentMethod === 'card' ? topUpInfo?.cardWithholding : topUpInfo?.withholding).format(
                        '0,0.00',
                      )}
                    </Typography>
                  </Stack>
                )}
              </CardContent>
              <Divider />

              <CardContent>
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <Typography flex={1} color="primary" fontWeight="bold">
                    จำนวนเงินสุทธิ
                  </Typography>
                  <Typography color="primary" fontWeight="bold" minWidth={80} textAlign="right">
                    {numeral(paymentMethod === 'card' ? topUpInfo?.cardPrice : topUpInfo?.price).format('0,0.00')}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>

            <FormControl>
              <FormControlLabel
                control={<Checkbox checked={isTaxInvoice} onChange={() => setIsTaxInvoice(!isTaxInvoice)} />}
                label="ต้องการใบกำกับภาษี / ใบเสร็จรับเงินฉบับจริง"
              />
            </FormControl>

            <FormControl>
              <FormLabel>เลือกช่องทางการชำระเงิน</FormLabel>
              <RadioGroup value={paymentMethod} onChange={(_e, value) => setPaymentMethod(value as 'card' | 'qrCode')}>
                <FormControlLabel value="card" control={<Radio />} label="บัตรเครดิต/เดบิต VISA/MasterCard" />
                <FormControlLabel value="qrCode" control={<Radio />} label="QR Code พร้อมเพย์" />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Collapse>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="overline" paddingLeft={1} gutterBottom>
          ในการกดยืนยันหมายถึงท่านได้อ่านและยอมรับ{' '}
          <LinkMui href="https://app.findtemp.in.th/static/findTEMP_refund_policy.pdf" target="_blank" rel="noopener">
            นโยบายการคืนเงิน
          </LinkMui>
        </Typography>
        <Button
          variant="contained"
          disabled={loading || paymentMethod === 'UNSELECT'}
          fullWidth
          disableElevation
          component={Link}
          {...checkoutLink()}
          onClick={onClickHandler}
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};
