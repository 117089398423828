import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { chain, groupBy, isEmpty, isNil, map, sumBy, toString } from 'lodash';
import numeral from 'numeral';
import { LoadingSpinner } from '../../../components';
import { AppContext } from '../../../contexts/app_context';
import { formatDate } from '../../../utils/libs';

type transaction = {
  id: string;
  amount: number;
  note?: string | null | undefined;
  createdAt: string;
  restaurantId?: string | null | undefined;
};

export type MonthlyTransactionListProps = {
  year: number;
  month: number;
  skipFetch?: boolean;
};

export type MonthlyTransactionListRef = {
  refetch: () => void;
};

const getMonthlyTransactionGql = gql(`
  query getMonthlyTransaction($month: Int!, $year: Int!) {
    transactions(month: $month, year: $year) {
      id
      createdAt
      note
      amount
      restaurantId
    }
  }
`);

const TransactionSubList = ({ title, transactions }: { title: string; transactions: transaction[] }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <ListItemButton divider onClick={() => setOpen((prev) => !prev)}>
        <ListItemText
          primary={
            <Stack flexDirection="row" alignItems="center" marginRight={1}>
              <Typography flex={1}>{title}</Typography>
              <Typography variant="subtitle2" minWidth="25%" textAlign="end">
                {numeral(sumBy(transactions, (transaction) => transaction.amount)).format('0,0.00')}
              </Typography>
            </Stack>
          }
        />
        <ExpandMore sx={{ transform: open ? 'rotate(180deg)' : 'rotate(360deg)', transition: '0.2s ease' }} />
      </ListItemButton>
      <Collapse in={open}>
        <List component="div">
          {map(transactions, (transaction) => (
            <ListItem key={transaction.id} divider dense>
              <ListItemText primary={transaction.note} secondary={formatDate(transaction.createdAt, 'DD MMM HH:mm')} />
              <Stack>
                <Typography>{numeral(transaction.amount).format('0,0.00')}</Typography>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const MonthlyTransactionList = React.forwardRef<MonthlyTransactionListRef, MonthlyTransactionListProps>(
  ({ year, month, skipFetch }: MonthlyTransactionListProps, ref) => {
    const { contextRestaurants } = React.useContext(AppContext);
    const restaurantNameMapId = React.useMemo(() => {
      return chain(contextRestaurants)
        .keyBy((restaurant) => toString(restaurant.id))
        .mapValues((restaurant) => `${restaurant.name} ${restaurant.branchName}`)
        .value();
    }, [contextRestaurants]);

    const { data, loading, refetch } = useQuery(getMonthlyTransactionGql, {
      variables: { year, month },
      fetchPolicy: 'network-only',
      skip: skipFetch || isNil(year) || isNil(month),
      notifyOnNetworkStatusChange: true,
    });
    const transactionsGroup = groupBy(data?.transactions, (transaction) => transaction.restaurantId ?? 'other');

    React.useImperativeHandle(
      ref,
      () => {
        return {
          refetch: async () => {
            await refetch();
          },
        };
      },
      [refetch],
    );

    return (
      <List disablePadding>
        <Collapse in={loading || isEmpty(data?.transactions)}>
          <Stack height={48}>
            {loading ? (
              <LoadingSpinner size={24} />
            ) : isEmpty(data?.transactions) ? (
              <ListItem>
                <ListItemText primary="ไม่มีรายการใช้จ่าย" />
              </ListItem>
            ) : null}
          </Stack>
        </Collapse>

        <Collapse in={!loading && !isEmpty(data?.transactions)}>
          {map(transactionsGroup, (transactions, restaurantId) => {
            const title =
              restaurantId === 'other'
                ? 'การเติมเครดิต'
                : restaurantNameMapId[toString(restaurantId)] ?? `ร้าน #${restaurantId}`;
            return <TransactionSubList key={restaurantId} title={title} transactions={transactions} />;
          })}
        </Collapse>
      </List>
    );
  },
);
