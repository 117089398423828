import { gql, useQuery } from '@apollo/client';
import { Edit } from '@mui/icons-material';
import { Alert, Container, Fab, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { BottomSafeArea, LoadingSpinner, useTrackPageView } from '../../components';

const getChainProfileGql = gql(`
  query getChainProfile {
    user {
      id
      userRestaurantContact {
        id
        isCompany
        companyName
        companyAddress
        companyTaxId
        companyLicense
        contactName
        contactEmail
        contactAddress
        contactThaiId
        contactThaiCard
        isInfoCompleted
      }
    }
  }
`);

export const ProfilePage = () => {
  useTrackPageView('ProfilePage');

  const { data, loading } = useQuery(getChainProfileGql);

  const userRestaurantContact = data?.user?.userRestaurantContact;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isEmpty(userRestaurantContact)) {
    return (
      <Container
        maxWidth={'sm'}
        sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Alert severity="warning">ไม่มีข้อมูล</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <Stack gap={2}>
        <Stack gap={1}>
          <Typography variant="subtitle2" color="primary">
            ชื่อผู้ใช้บริการ
          </Typography>
          <Typography>{userRestaurantContact.contactName}</Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="subtitle2" color="primary">
            อีเมล
          </Typography>
          <Typography>{userRestaurantContact.contactEmail}</Typography>
        </Stack>

        <Stack gap={1}>
          <Typography variant="subtitle2" color="primary">
            ลักษณะการจดทะเบียน
          </Typography>
          <Typography>{userRestaurantContact.isCompany ? 'นิติบุคคล' : 'บุคคลธรรมดา'}</Typography>
        </Stack>

        {userRestaurantContact.isCompany ? (
          <>
            <Stack gap={1}>
              <Typography variant="subtitle2" color="primary">
                ชื่อห้างหุ้นส่วนจำกัด/บริษัท
              </Typography>
              <Typography>{userRestaurantContact.companyName ?? '-'}</Typography>
            </Stack>

            <Stack gap={1}>
              <Typography variant="subtitle2" color="primary">
                เลขประจำตัวผู้เสียภาษี
              </Typography>
              <Typography>{userRestaurantContact.companyTaxId ?? '-'}</Typography>
            </Stack>

            <Stack gap={1}>
              <Typography variant="subtitle2" color="primary">
                ที่อยู่ห้างหุ้นส่วนจำกัด/บริษัท
              </Typography>
              <Typography>{userRestaurantContact.companyAddress ?? '-'}</Typography>
            </Stack>
          </>
        ) : (
          <>
            <Stack gap={1}>
              <Typography variant="subtitle2" color="primary">
                ที่อยู่
              </Typography>
              <Typography>{userRestaurantContact.contactAddress ?? '-'}</Typography>
            </Stack>

            <Stack gap={1}>
              <Typography variant="subtitle2" color="primary">
                เลขประจำตัวประชาชน
              </Typography>
              <Typography>{userRestaurantContact.contactThaiId ?? '-'}</Typography>
            </Stack>
          </>
        )}
      </Stack>

      <Fab
        variant="extended"
        color="primary"
        component={Link}
        to="edit"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <Edit sx={{ marginRight: 1 }} />
        แก้ไขข้อมูล
      </Fab>

      <BottomSafeArea size="large" />
    </Container>
  );
};
