import React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { DoneAll, Refresh } from '@mui/icons-material';
import type { StackProps } from '@mui/material';
import { Box, Fab, IconButton, Stack, Typography, Zoom } from '@mui/material';
import { filter, get, includes, isEmpty, map, reduce, size } from 'lodash';
import { CardLevel, LoadingSpinner } from '../../../components';
import { CreditNotEnoughPopup } from '../../../components/credit_not_enough_popup';
import { createScopedI18n, i18n } from '../../../i18n/i18n';
import { joinPairs } from '../../../utils/libs';
import Snackbar, { INSUFFICIENT_BALANCE } from '../../../utils/snackbar';
import StudentCandidateCard from './student_candidate_card';

import type { StudentCandidateType } from './student_candidate_card';

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');
const creditErrorI18n = createScopedI18n('graphql.errors.types.credit');

const getJobApplicantsGql = gql(`
  query GetJobApplicantsV2($jobId: ID) {
    jobApplicantsV2(jobId: $jobId) {
      id
      status
      restaurantChargePerHour
      job {
        id
        jobType
        numberOfPosition
        numberOfHired
      }
      student {
        id
        avatar
        nickName
        headline
        rating
        isWorkedHere
        lastStudentCovid19Vaccine {
          id
          nth
          vaccinedAt
        }
        studentExperiences {
          id
          jobType
          minute
        }
        studentPreExperiences
        studentCertificates {
          id
          certificateType
        }
        latestCommends
      }
    }
  }
`);

const acceptAppliedStudentGql = gql(`
  mutation acceptAppliedStudent($jobApplicantId: ID!) {
    acceptAppliedStudent(jobApplicantId: $jobApplicantId) {
      success
      errors
    }
  }
`);

type JobApplicantType = {
  id: string;
  restaurantChargePerHour?: number;
  student: StudentCandidateType;
  job: {
    jobType: string;
    numberOfPosition: number;
    numberOfHired: number;
  };
};

export type JobCandidateListProps = {
  jobId?: string;
  onCardClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined, jobApplicantId: string) => void;
  onSelectedJobApplicantIdsChange?: (selectedJobApplicantIds: string[]) => void;
  outerStackProps?: StackProps;
};

const JobCandidateList = ({ jobId, onSelectedJobApplicantIdsChange, outerStackProps }: JobCandidateListProps) => {
  const { data, loading, refetch } = useQuery(getJobApplicantsGql, {
    variables: { jobId },
    skip: !jobId,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const jobApplicants: JobApplicantType[] = data?.jobApplicantsV2 ?? [];
  const remainPosition =
    get(jobApplicants, [0, 'job', 'numberOfPosition'], 0) - get(jobApplicants, [0, 'job', 'numberOfHired'], 0);

  const [isNotEnoughCredit, setIsNotEnoughCredit] = React.useState(false);

  const [selectedJobApplicantIds, setSelectedJobApplicantIds] = React.useState<string[]>([]);
  const isMaxSelected = size(selectedJobApplicantIds) >= remainPosition;

  const toggleSelectedHandlerHigherOrder = (id: string) => () => {
    let nextSelectedJobApplicantIds = selectedJobApplicantIds ?? [];
    if (includes(nextSelectedJobApplicantIds, id)) {
      nextSelectedJobApplicantIds = filter(nextSelectedJobApplicantIds, (jobApplicantId) => jobApplicantId !== id);
    } else if (size(nextSelectedJobApplicantIds) < remainPosition) {
      nextSelectedJobApplicantIds = [...nextSelectedJobApplicantIds, id];
    }
    setSelectedJobApplicantIds(nextSelectedJobApplicantIds);

    if (onSelectedJobApplicantIdsChange) onSelectedJobApplicantIdsChange(nextSelectedJobApplicantIds);
  };

  React.useEffect(() => {
    const jobApplicantIds = map(jobApplicants, 'id');
    setSelectedJobApplicantIds((prevSelectedJobApplicantIds) =>
      filter(prevSelectedJobApplicantIds, (selectedJobApplicantId) =>
        includes(jobApplicantIds, selectedJobApplicantId),
      ),
    );
  }, [jobApplicants]);

  const [errorMessageMap, setErrorMessageMap] = React.useState<{ [jobApplicantId: string]: string | null | undefined }>(
    {},
  );
  const onCardErrorChangeHandlerHigherOrder = React.useCallback(
    (jobApplicantId: string) => (errorMessage: string | null, errorsPairs?: [string, string][] | null) => {
      setErrorMessageMap((prev) => ({ ...prev, [jobApplicantId]: errorMessage }));

      if (!isNotEnoughCredit && joinPairs(errorsPairs).includes(creditErrorI18n('not_enough'))) {
        setIsNotEnoughCredit(true);
        Snackbar.enqueueError(INSUFFICIENT_BALANCE);
      }
    },
    [isNotEnoughCredit],
  );

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [acceptAppliedStudentMutation] = useMutation(acceptAppliedStudentGql);
  const acceptAppliedStudentsHandler = async (jobApplicantIds: string[] = []) => {
    if (isEmpty(jobApplicantIds)) {
      return;
    }

    if (size(jobApplicantIds) > remainPosition) {
      setErrorMessageMap((prevState) =>
        reduce(
          jobApplicantIds,
          (prev, id) => ({ ...prev, [id]: i18n.t('errors.messages.too_many_accepted_student_candidate') }),
          prevState,
        ),
      );
      return;
    }

    setIsSubmitting(true);
    // eslint-disable-next-line no-restricted-syntax
    for (const jobApplicantId of jobApplicantIds) {
      setErrorMessageMap((prev) => ({ ...prev, [jobApplicantId]: null }));

      try {
        // TODO: CACHE Update /jobs, /job_hirings, /jobs/:id
        // eslint-disable-next-line no-await-in-loop
        const { data } = await acceptAppliedStudentMutation({
          variables: { jobApplicantId },
          refetchQueries: ({ data }) =>
            data?.acceptAppliedStudent.success ? ['GetJobApplicantsV2', 'GetJobHiringsV2', 'GetJobDetail'] : [],
        });

        if (data?.acceptAppliedStudent.success) {
          setSelectedJobApplicantIds((prev) => filter(prev, (id) => id !== jobApplicantId));
        } else {
          const errors = data?.acceptAppliedStudent.errors;

          if (joinPairs(errors).includes(creditErrorI18n('not_enough'))) {
            setIsNotEnoughCredit(true);
            Snackbar.enqueueError(INSUFFICIENT_BALANCE);
          }

          setErrorMessageMap((prev) => ({ ...prev, [jobApplicantId]: joinPairs(errors) }));
        }
      } catch (error) {
        setErrorMessageMap((prev) => ({ ...prev, [jobApplicantId]: get(error, 'message') }));
      }
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Stack
        flex={1}
        {...outerStackProps}
        sx={[
          { overflowX: 'auto' },
          ...(Array.isArray(outerStackProps?.sx) ? outerStackProps?.sx ?? [] : [outerStackProps?.sx]),
        ]}
      >
        <Stack flexDirection="row" alignItems="center" paddingX={3} paddingY={2}>
          <Typography variant="h6">{jobDetailPageI18n('job_applicants')}</Typography>
          <div style={{ flexGrow: 1 }} />
          <IconButton onClick={() => refetch()}>
            <Refresh />
          </IconButton>
        </Stack>

        <Box flex={1} sx={{ overflowY: 'scroll' }}>
          {loading || isEmpty(jobApplicants) ? (
            <CardLevel variant="outlined" sx={{ margin: 0.5 }}>
              {loading ? (
                <LoadingSpinner size={24} BoxProps={{ padding: 3 }} />
              ) : (
                <Stack padding={3} alignItems="center">
                  <Typography>{jobDetailPageI18n('job_applicant_empty')}</Typography>
                </Stack>
              )}
            </CardLevel>
          ) : (
            map(jobApplicants, ({ id, student, restaurantChargePerHour, job: { jobType } }) => {
              const isSelected = includes(selectedJobApplicantIds, id);
              return (
                <StudentCandidateCard
                  key={id}
                  jobApplicantId={id}
                  student={student}
                  restaurantChargePerHour={restaurantChargePerHour}
                  jobType={jobType}
                  selected={isSelected}
                  disabled={isMaxSelected && !isSelected}
                  disableDetailDialogAction={isMaxSelected && !isSelected}
                  onAvatarClick={toggleSelectedHandlerHigherOrder(id)}
                  errorMessage={errorMessageMap ? errorMessageMap[id] : undefined}
                  onErrorMessageChange={onCardErrorChangeHandlerHigherOrder(id)}
                  sx={{ flex: 1, margin: 0.5 }}
                />
              );
            })
          )}
        </Box>
      </Stack>

      <Zoom in={!isEmpty(selectedJobApplicantIds)}>
        <Fab
          variant="extended"
          color="primary"
          disabled={isSubmitting}
          onClick={() => acceptAppliedStudentsHandler(selectedJobApplicantIds)}
          sx={{ position: 'fixed', bottom: 24, right: 24 }}
        >
          <DoneAll sx={{ marginRight: 1 }} />
          {jobDetailPageI18n('accept_job_applicants_count', {
            count: size(selectedJobApplicantIds),
            max: remainPosition,
          })}
        </Fab>
      </Zoom>

      <CreditNotEnoughPopup open={isNotEnoughCredit} onClosed={(_) => setIsNotEnoughCredit(false)} />
    </>
  );
};

export default JobCandidateList;
