import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { ArrowBack, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { includes, trimEnd } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '../loading_button';
import { AppDrawer } from './app_drawer';
import { DateSelector } from './date_selector';
import { RestaurantSelector } from './restaurant_selector';
import { UserInfoMenuButton } from './user_info_menu_button';
import { createScopedI18n } from '../../i18n/i18n';
import { useGoBack } from '../use_go_back';

const resetPasswordPageI18n = createScopedI18n('pages.reset_password');
const registerPageI18n = createScopedI18n('pages.register');
const firstProfileEditPageI18n = createScopedI18n('pages.first_profile_edit');

const getUnLoginTitle = (pathname: string) => {
  if (includes(['/auth/reset_password'], trimEnd(pathname, '/'))) {
    return resetPasswordPageI18n('title');
  }
  if (includes(['/auth/register'], trimEnd(pathname, '/'))) {
    return registerPageI18n('title');
  }
  if (includes(['/first_profile_edit'], trimEnd(pathname, '/'))) {
    return firstProfileEditPageI18n('title');
  }
  return '';
};

const testPushNotificationGql = gql(`
  mutation testPushNotification {
    testPushNotification {
      success
      errors
    }
  }
`);

const TestNotificationButton = ({ hideOnProduction }: { hideOnProduction?: boolean }) => {
  const [testPushNotificationMutation] = useMutation(testPushNotificationGql);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const submitHandler = async () => {
    setIsSubmitting(true);
    await testPushNotificationMutation();
    setIsSubmitting(false);
  };

  if (hideOnProduction && !(import.meta.env.MODE === 'development' || import.meta.env.MODE === 'staging')) {
    return null;
  }
  return (
    <LoadingButton disabled={isSubmitting} loading={isSubmitting} color="inherit" size="small" onClick={submitHandler}>
      Test Noti
    </LoadingButton>
  );
};

const NavBar = () => {
  const navigate = useNavigate();
  const goBack = useGoBack();

  const location = useLocation();
  const showDrawerMenu = includes(['/jobs', '/job_hirings'], trimEnd(location.pathname, '/'));
  const showGoBackMenu = !includes(
    ['/flow_sign_out', '/first_new_restaurant', '/first_profile_edit', '/force_change_password', '/pdpa_agreement'],
    trimEnd(location.pathname, '/'),
  );
  // showRestaurantSelector if path is /jobs, /jobs/new, /restaurants/:id/*
  const showRestaurantSelector = /^(?:\/jobs\/?|\/jobs\/new\/?|\/restaurants\/\d+[/\w]*\/?)$/.test(location.pathname);
  const isRestaurantSelectorGroups = /^\/jobs\/?$/.test(location.pathname);

  const showDatesSelector = includes(['/job_hirings'], trimEnd(location.pathname, '/'));
  const showUnLoginTitle = getUnLoginTitle(location.pathname);

  const signOutHandler = () => {
    navigate('/flow_sign_out', { replace: false });
  };

  const goBackHandler = () => {
    goBack();
  };

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const toggleDrawerHandler = () => {
    setOpenDrawer((prevOpenDrawer) => !prevOpenDrawer);
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          {showDrawerMenu ? (
            <IconButton size="large" edge="start" color="inherit" onClick={toggleDrawerHandler}>
              <MenuIcon />
            </IconButton>
          ) : showGoBackMenu ? (
            <IconButton size="large" edge="start" color="inherit" onClick={goBackHandler}>
              <ArrowBack />
            </IconButton>
          ) : (
            <IconButton size="large" edge="start" color="inherit" onClick={signOutHandler}>
              <ArrowBack />
            </IconButton>
          )}

          {showRestaurantSelector ? <RestaurantSelector sx={{ width: '40%', maxWidth: 330 }} group={isRestaurantSelectorGroups} /> : null}
          {showDatesSelector ? <DateSelector sx={{ width: '40%', maxWidth: 330 }} /> : null}
          {showUnLoginTitle ? (
            <>{showUnLoginTitle}</>
          ) : (
            <>
              <TestNotificationButton hideOnProduction />
              <div style={{ flexGrow: 1 }} />
              <UserInfoMenuButton sx={{ maxWidth: '35%' }} />
            </>
          )}
        </Toolbar>
      </AppBar>

      <AppDrawer open={openDrawer} onClose={toggleDrawerHandler} />

      <Toolbar /* Use as spacer to push content out of AppBar */ />
    </>
  );
};

export default NavBar;
