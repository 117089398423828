import React from 'react';

import { LocationOff, LocationOn, Map } from '@mui/icons-material';
import { Alert, Autocomplete, Collapse, ListItem, ListItemIcon, ListItemText, Paper, TextField } from '@mui/material';
import { debounce, get, isNil, join, size, trim } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { LoadingSpinner, apiAxiosAuth } from '.';

import type { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material';

const DEFAULT_OPTIONS = {
  radius: 10000, // Meters
  language: 'th',
};

type PlacesAutocompleteResponse = {
  predictions: google.maps.places.AutocompletePrediction[];
  status: 'OK' | 'ZERO_RESULTS' | 'INVALID_REQUEST' | 'OVER_QUERY_LIMIT' | 'REQUEST_DENIED' | 'UNKNOWN_ERROR';
  error_message?: string;
  info_messages?: string[];
};

type PlacesDetailResponse = {
  html_attributions: string[];
  result: Omit<google.maps.places.PlaceResult, 'geometry'> & { geometry?: { location: google.maps.LatLngLiteral } };
  status:
    | 'OK'
    | 'ZERO_RESULTS'
    | 'NOT_FOUND'
    | 'INVALID_REQUEST'
    | 'OVER_QUERY_LIMIT'
    | 'REQUEST_DENIED'
    | 'UNKNOWN_ERROR';
  info_messages?: string[];
};

export type PlaceResult = {
  placeId: string | null;
  location: { latitude: number; longitude: number } | null;
  address: string | null;
  name: string | null;
};

export type PlaceAutocompleteInputProps = {
  onPlaceChange?: (place: PlaceResult) => void;
  locationBias?: { latitude: number; longitude: number };
  autocompleteOptions?: {
    radius?: number;
    language?: string;
  };
};

export const PlaceAutocompleteInput = ({
  onPlaceChange,
  locationBias,
  autocompleteOptions,
}: PlaceAutocompleteInputProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [sessionToken, setSessionToken] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [placeOptions, setPlaceOptions] = React.useState<google.maps.places.AutocompletePrediction[]>([]);

  const getPlaceAutocomplete = React.useMemo(
    () =>
      debounce(
        async (
          searchText: string,
          sessionToken: string,
          location?: typeof locationBias,
          options?: typeof autocompleteOptions,
        ) => {
          setIsLoading(true);
          setErrorMessage(null);
          try {
            const { data }: { data?: PlacesAutocompleteResponse } = await apiAxiosAuth(
              'GET',
              'google_api/place_autocomplete',
              {
                params: {
                  sessiontoken: sessionToken,
                  input: searchText,
                  location:
                    location?.latitude && location?.longitude ? `${location.latitude},${location.longitude}` : null,
                  radius: options?.radius ?? DEFAULT_OPTIONS.radius, //Meters
                  language: options?.language ?? DEFAULT_OPTIONS.language,
                },
              },
            );

            if (data?.status === 'OK') {
              setPlaceOptions(data.predictions ?? []);
            } else {
              setErrorMessage(`[${data?.status ?? 'UNKNOWN_ERROR'}]: ${data?.error_message ?? 'UNKNOWN_ERROR'}`);
            }
          } catch (error) {
            setErrorMessage(get(error, 'message', 'Unknown error'));
          }

          setIsLoading(false);
        },
        250,
      ),
    [],
  );

  const onInputChangeHandler = async (
    _event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setSearchText(value);
    const searchValue = trim(value);

    if (reason === 'clear') {
      setIsLoading(false);
      setPlaceOptions([]);
    }

    // Click on options
    if (reason === 'reset') {
      setIsLoading(false);
      return;
    }

    if (size(searchValue) >= 3) {
      setIsLoading(true);

      let nextSessionToken = sessionToken;
      if (isNil(nextSessionToken)) {
        nextSessionToken = uuidV4();
        setSessionToken(nextSessionToken);
      }

      await getPlaceAutocomplete(searchValue, nextSessionToken, locationBias, autocompleteOptions);
      setIsLoading(false);
    } else {
      getPlaceAutocomplete.cancel();
      setPlaceOptions([]);
      setIsLoading(false);
    }
  };

  // onClickOption
  const onChangeHandler = async (
    _event: React.SyntheticEvent<Element, Event>,
    value: google.maps.places.AutocompletePrediction | null,
    _reason: AutocompleteChangeReason,
    _details?: AutocompleteChangeDetails<google.maps.places.AutocompletePrediction | null>,
  ) => {
    const placeId = value?.place_id;
    if (placeId) {
      try {
        const { data }: { data?: PlacesDetailResponse } = await apiAxiosAuth('GET', 'google_api/place_details', {
          params: {
            sessiontoken: sessionToken,
            place_id: placeId,
            fields: 'name,formatted_address,geometry,place_id',
            language: autocompleteOptions?.language ?? DEFAULT_OPTIONS.language,
          },
        });

        if (data?.status === 'OK') {
          const latitude = data.result.geometry?.location.lat;
          const longitude = data.result.geometry?.location.lng;

          const result = {
            placeId,
            location: latitude && longitude ? { latitude, longitude } : null,
            address: data.result.formatted_address ?? null,
            name: data.result.name ?? null,
          };

          onPlaceChange && onPlaceChange(result);
        } else {
          setErrorMessage(
            `[${data?.status ?? 'UNKNOWN_ERROR'}]: ${join(data?.info_messages ?? ['UNKNOWN_ERROR'], ', ')}`,
          );
        }
      } catch (error) {
        setErrorMessage(get(error, 'message', 'Unknown error'));
      }
    }
    setSessionToken(null);
  };

  return (
    <Paper>
      <Collapse in={!!errorMessage}>
        <Alert severity="error">{errorMessage}</Alert>
      </Collapse>

      <Autocomplete
        id="google-maps-place-autocomplete"
        disablePortal
        clearOnBlur={false}
        loading={isLoading}
        loadingText={
          <ListItem dense>
            <ListItemIcon>
              <LoadingSpinner size={24} color="inherit" BoxProps={{ flex: 0 }} />
            </ListItemIcon>
            <ListItemText primary="กำลังค้นหา" />
          </ListItem>
        }
        noOptionsText={
          <ListItem dense>
            <ListItemIcon>
              <LocationOff />
            </ListItemIcon>
            <ListItemText primary="ไม่พบสถานที่" />
          </ListItem>
        }
        inputValue={searchText ?? ''}
        onInputChange={onInputChangeHandler}
        onChange={onChangeHandler}
        options={placeOptions}
        getOptionLabel={(option) => option.structured_formatting.main_text}
        isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
        renderInput={(inputProps) => (
          <TextField
          placeholder="ค้นหาสถานที่"
          error={!!errorMessage}
          sx={{
            color: '#a2a2a2',
            '&:hover': {
              '&::placeholder': {
                color: '#a2a2a2',
              },
            },
            '&::placeholder': {
              color: '#a2a2a2',
            },
            backgroundColor: '#a2a2a2',
          }}
          {...inputProps}
          InputProps={{
            ...inputProps.InputProps,
            startAdornment: (
              <Map
                sx={{
                  marginX: 1,
                }}
              />
            ),
          }}
        />
        
        )}
        renderOption={(optionProps, option) => (
          <ListItem {...optionProps} dense>
            <ListItemIcon>
              <LocationOn />
            </ListItemIcon>
            <ListItemText
              primary={option.structured_formatting.main_text}
              secondary={option.structured_formatting.secondary_text}
            />
          </ListItem>
        )}
      />
    </Paper>
  );
};
