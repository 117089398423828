import React from 'react';

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { AppContext } from '../contexts/app_context';
import { i18n } from '../i18n/i18n';

export const InstallPWADialog = () => {
  const beforeInstallPromptRef = React.useRef<Event | null>(null);

  const { contextIsLogin, contextInfoCompleteStatus } = React.useContext(AppContext);
  const canShowDialog =
    contextIsLogin === true &&
    contextInfoCompleteStatus?.isPdpa === true &&
    contextInfoCompleteStatus?.isInfoComplete === true;

  const isSupportPrompt = 'BeforeInstallPromptEvent' in window;
  React.useEffect(() => {
    const listener = (e: Event) => {
      e.preventDefault();
      beforeInstallPromptRef.current = e;
    };
    window.addEventListener('beforeinstallprompt', listener);

    return () => window.removeEventListener('beforeinstallprompt', listener);
  }, []);

  const [pwaStatus, setPwaStatus] = React.useState<{ checkAt?: string | null } | null>(null);
  React.useEffect(() => {
    let pwaStatus: { checkAt?: string | null } | null = null;
    const pwaStatusJson = localStorage.getItem('PWA_STATUS');
    if (pwaStatusJson) {
      pwaStatus = JSON.parse(pwaStatusJson);
    }

    setPwaStatus(pwaStatus);
  }, []);

  const [showDialog, setShowDialog] = React.useState(false);
  const [hadShow, setHadShow] = React.useState(false);
  const closeDialogHandler = () => {
    setShowDialog(false);

    const pwaStatus = { checkAt: dayjs().toISOString() };
    localStorage.setItem('PWA_STATUS', JSON.stringify(pwaStatus));
  };

  React.useEffect(() => {
    if (
      beforeInstallPromptRef.current &&
      canShowDialog &&
      !hadShow &&
      (isNil(pwaStatus?.checkAt) || dayjs().isAfter(dayjs(pwaStatus?.checkAt).add(3, 'days')))
    ) {
      setShowDialog(true);
      setHadShow(true);
    }
  }, [canShowDialog, hadShow, pwaStatus?.checkAt]);

  const installHandler = async () => {
    closeDialogHandler();
    if (beforeInstallPromptRef.current) {
      // @ts-expect-error prompt() from BeforeInstallPromptEvent
      beforeInstallPromptRef.current.prompt();
      beforeInstallPromptRef.current = null;
    }
  };

  return (
    <Dialog open={showDialog} onClose={closeDialogHandler}>
      <DialogTitle>{i18n.t('components.install_pwa_dialog.title')}</DialogTitle>
      <DialogActions>
        <Button onClick={closeDialogHandler}>{i18n.t('general.cancel')}</Button>
        <Button variant="contained" disableElevation onClick={installHandler}>
          {i18n.t('general.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
