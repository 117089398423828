import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Edit } from '@mui/icons-material';
import { Alert, Box, Container, Fab, FormLabel, Stack, Typography } from '@mui/material';
import { find, isEmpty, isNil } from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BottomSafeArea, LoadingSpinner, MapsPickerCard, useTrackPageView } from '../../../components';
import { AppContext } from '../../../contexts/app_context';
import { i18n } from '../../../i18n/i18n';
import { labelForEnum } from '../../../utils/libs';

const getRestaurantDetailGql = gql(`
  query getRestaurantDetail($restaurantId: ID!) {
    user {
      id
      chain {
        id
        restaurant(id: $restaurantId) {
          id
          branchName
          businessType
          landmark
          latitude
          longitude
          name
          restaurantPhotos {
            photo
            photoType
          }
        }
      }
    }
  }
`);

export const RestaurantsDetailPage = () => {
  const { id: restaurantId } = useParams();
  const navigate = useNavigate();

  const { contextCurrentRestaurant } = React.useContext(AppContext);

  useTrackPageView('RestaurantDetailPage');

  const { data, loading } = useQuery(getRestaurantDetailGql, {
    variables: { restaurantId: restaurantId as string },
    fetchPolicy: 'cache-and-network',
    skip: isNil(restaurantId),
  });
  const restaurant = data?.user.chain?.restaurant;
  const photoFront: string | undefined = find(
    restaurant?.restaurantPhotos,
    (photo) => photo.photoType === 'front',
  )?.photo;
  const photoAdd1: string | undefined = find(
    restaurant?.restaurantPhotos,
    (photo) => photo.photoType === 'additional1',
  )?.photo;
  const photoAdd2: string | undefined = find(
    restaurant?.restaurantPhotos,
    (photo) => photo.photoType === 'additional2',
  )?.photo;
  const location =
    restaurant?.latitude && restaurant?.longitude
      ? { latitude: restaurant.latitude, longitude: restaurant.longitude }
      : undefined;

  React.useEffect(() => {
    if (isNil(contextCurrentRestaurant?.id) || isNil(restaurantId)) {
      return;
    }

    if (contextCurrentRestaurant?.id !== restaurantId) {
      navigate(`/restaurants/${contextCurrentRestaurant?.id}`, { replace: true });
    }
  }, [contextCurrentRestaurant?.id, navigate, restaurantId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isEmpty(restaurant)) {
    return (
      <Container
        maxWidth={'sm'}
        sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Alert severity="warning">{i18n.t('general.empty')}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <Stack gap={2}>
        <Stack gap={0.5}>
          <Stack flex={1}>
            <Box component="img" src={photoFront} width="100%" sx={{ objectFit: 'cover', aspectRatio: 2 }} />
          </Stack>
          <Stack flexDirection="row" gap={0.5}>
            <Stack flex={1}>
              <Box component="img" src={photoAdd1} width="100%" sx={{ objectFit: 'cover', aspectRatio: 3 / 2 }} />
            </Stack>
            <Stack flex={1}>
              <Box component="img" src={photoAdd2} width="100%" sx={{ objectFit: 'cover', aspectRatio: 3 / 2 }} />
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={0.5}>
          <FormLabel>ชื่อสถานประกอบการ</FormLabel>
          <Typography>{restaurant?.name ?? '-'}</Typography>
        </Stack>

        <Stack gap={0.5}>
          <FormLabel>ชื่อสาขา</FormLabel>
          <Typography>{restaurant?.branchName ?? '-'}</Typography>
        </Stack>

        <Stack gap={0.5}>
          <FormLabel>ประเภทสถานประกอบการ</FormLabel>
          <Typography>{labelForEnum('business_type', restaurant?.businessType) ?? '-'}</Typography>
        </Stack>

        <Stack gap={0.5}>
          <FormLabel>วิธีเดินทาง/จุดสังเกต</FormLabel>
          <Typography style={{ whiteSpace: 'break-spaces' }}>{restaurant?.landmark ?? '-'}</Typography>
        </Stack>

        <Stack gap={0.5}>
          <FormLabel>ที่ตั้งสถานประกอบการบน Google Map</FormLabel>
          <MapsPickerCard disabled location={location} />
        </Stack>
      </Stack>

      <Fab color="primary" component={Link} to="edit" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <Edit />
      </Fab>

      <BottomSafeArea size="large" />
    </Container>
  );
};
