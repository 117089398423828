import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Chip, Container, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { map } from 'lodash';
import numeral from 'numeral';
import { LoadingSpinner, useTrackPageView } from '../../../components';
import { TopUpItemDetailDialog } from './top_up_item_detail_dialog';

const getTopUpItemsGql = gql(`
  query getTopUpItems {
    topupItems {
      id
      amount
      bonus
    }
  }
`);

export const CreditPurchasePage = () => {
  useTrackPageView('CreditPurchasePage');

  const { data, loading } = useQuery(getTopUpItemsGql);

  const [currentItemAmount, setCurrentItemAmount] = React.useState<number | null>(null);
  const [showItemDialog, setShowItemDialog] = React.useState(false);
  const topUpClickHandlerHigherOrder = (amount: number) => () => {
    setCurrentItemAmount(amount);
    setShowItemDialog(true);
  };

  const onDismiss = () => {
    setShowItemDialog(false);
    setCurrentItemAmount(null);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <List>
        {map(data?.topupItems, (topUpItem) => (
          <ListItem key={topUpItem.id} divider disablePadding>
            <ListItemButton onClick={topUpClickHandlerHigherOrder(topUpItem.amount)}>
              <ListItemText
                primary={`${numeral(topUpItem.amount + (topUpItem.bonus ?? 0)).format('0,0')} เครดิต`}
                secondary={topUpItem.bonus ? `+ ${numeral(topUpItem.bonus).format('0,0')}` : undefined}
              />
              <Chip
                clickable
                variant="filled"
                color="primary"
                label={`${numeral(topUpItem.amount).format('0,0')} บาท`}
              />
            </ListItemButton>
          </ListItem>
        ))}
        <Typography color="GrayText" padding={2}>
          * ระบบจะส่งใบกำกับภาษี/ใบเสร็จรับเงินให้ทางอีเมลทุกครั้งที่ชำระเงิน
        </Typography>
      </List>

      <TopUpItemDetailDialog open={showItemDialog} onClose={onDismiss} amount={currentItemAmount} />
    </Container>
  );
};
