import React from 'react';

import { gql, useQuery } from '@apollo/client';

import type { GraphQLErrors, NetworkError } from '@apollo/client/errors';

export type apolloInternalErrorType = {
  __typename?: 'apolloInternalError';
  message: string | null;
  statusCode: number | null;
  networkError: NetworkError | null;
  graphQLErrors: GraphQLErrors | null;
};

export const getApolloInternalErrorGql = gql(`
  query getApolloInternalError {
    apolloInternalError {
      message
      statusCode
      networkError
      graphQLErrors
    }
  }
`);

export const useThrowApolloError = () => {
  const { data, client } = useQuery<{ apolloInternalError: apolloInternalErrorType | null }>(
    getApolloInternalErrorGql,
    {
      fetchPolicy: 'cache-only',
    },
  );

  const resetApolloInternalError = React.useCallback(() => {
    client.cache.writeQuery({
      query: getApolloInternalErrorGql,
      data: { apolloInternalError: null },
      broadcast: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.cache.writeQuery]);

  React.useEffect(() => {
    if (data?.apolloInternalError?.networkError) {
      resetApolloInternalError();

      throw data.apolloInternalError.networkError;
    }
  }, [data?.apolloInternalError, resetApolloInternalError]);
};

export const ApolloErrorThrower = ({ children }: { children: React.ReactNode }) => {
  useThrowApolloError();

  return children;
};
