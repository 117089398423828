import React from 'react';

import { includes, trimEnd } from 'lodash';
import { Navigate } from 'react-router-dom';
import { LoadingSpinner, useRouteLocationRef } from '../components';
import { AppContext } from '../contexts/app_context';

export const IsLoginGuard = ({ children }: { children: React.ReactNode }) => {
  const locationRef = useRouteLocationRef();

  const { contextIsLogin, setContextRedirectFrom } = React.useContext(AppContext);

  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    const setRedirectFromAsync = async () => {
      setIsLoading(true);
      await setContextRedirectFrom(locationRef.current);
      setIsLoading(false);
    };

    if (
      contextIsLogin === false &&
      !includes(
        ['/sign_out', '/flow_sign_out', '/change_phone_number', '/profile/change_password', '/first_profile_edit','/pdpa_agreement','/force_change_password','/first_new_restaurant'],
        trimEnd(locationRef.current.pathname, '/'),
      )
    ) {
      setRedirectFromAsync();
    }
  }, [contextIsLogin]);

  if (isLoading) {
    return <LoadingSpinner BoxProps={{ height: '100vh' }} />;
  }

  if (contextIsLogin === false) {
    return <Navigate to="/auth/sign_in" replace />;
  }
  return children;
};
