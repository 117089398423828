import React from 'react';

import { ExpandMore, Refresh } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { formatDate } from '../../../utils/libs';
import { MonthlyTransactionList } from './monthly_transaction_list';

import type { AccordionProps } from '@mui/material';
import type { MonthlyTransactionListRef } from './monthly_transaction_list';

export type MonthlyTransactionAccordionProps = {
  year: number;
  month: number;
} & Omit<AccordionProps, 'children' | 'expanded' | 'onChange'>;

export const MonthlyTransactionAccordion = ({ year, month, ...props }: MonthlyTransactionAccordionProps) => {
  const transactionListRef = React.useRef<MonthlyTransactionListRef>(null);

  const [isExpand, setIsExpand] = React.useState(false);

  const refetchHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await transactionListRef.current?.refetch();
  };

  return (
    <Accordion expanded={isExpand} onChange={(_e, expanded) => setIsExpand(expanded)} {...props}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Stack flexDirection="row" alignItems="center" width="100%" paddingX={1}>
          <Typography variant="h6" flex={1}>
            {formatDate(`${year}-${month}`, 'MMMM YYYY')}
          </Typography>
          <Collapse in={isExpand} orientation="horizontal">
            <IconButton onClick={refetchHandler}>
              <Refresh />
            </IconButton>
          </Collapse>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <MonthlyTransactionList ref={transactionListRef} year={year} month={month} skipFetch={!isExpand} />
      </AccordionDetails>
    </Accordion>
  );
};
