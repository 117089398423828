import { Box, CircularProgress } from '@mui/material';

import type { BoxProps, CircularProgressProps } from '@mui/material';

export type LoadingSpinnerProps = {
  BoxProps?: BoxProps;
} & CircularProgressProps;

const LoadingSpinner = ({ BoxProps, ...CircularProgressProps }: LoadingSpinnerProps) => {
  return (
    <Box display="flex" flex={1} justifyContent="center" alignItems="center" {...BoxProps}>
      <CircularProgress {...CircularProgressProps} />
    </Box>
  );
};

export default LoadingSpinner;
