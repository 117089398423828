import React from 'react';
import { Container, Box, Link as LinkMui } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { BottomSafeArea } from '../../components';
import { AppContext } from '../../contexts/app_context';

export const ThankYouPage = () => {
  const { setContextIsLogin } = React.useContext(AppContext);

  return (
    <Container
      maxWidth="sm"
      sx={{ paddingY: 2, height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box textAlign="center">
        <TaskAltIcon sx={{ fontSize: 80 }} />
        <p> ได้รับข้อมูลเรียบร้อย โปรดรอเจ้าหน้าที่ติดต่อกลับ </p>
        <LinkMui href="/" rel="noopener">
          <p>กลับหน้าหลัก</p>
        </LinkMui>
      </Box>
      <BottomSafeArea />
    </Container>
  );
};
