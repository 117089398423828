import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { filter, groupBy, isEqual, keys, map, orderBy } from 'lodash';
import { ContainerFlexHeight, LoadingSpinner, useTrackPageView } from '../../../components';
import { AppContext } from '../../../contexts/app_context';
import { DateJobHiringList } from './date_job_hiring_list';

import type { DateType } from '../../../contexts/app_context';

const getPendingJobHiringJobsGql = gql(`
  query getPendingJobHiringJobs {
    pendingJobHiringJobs {
      id
      scheduleDate
    }
  }
`);

export const JobHiringsPage = () => {
  const datesLaneRef = React.useRef<{ [date: string]: HTMLDivElement | null }>({});

  useTrackPageView('JobHiringListPage');

  const { data, loading } = useQuery(getPendingJobHiringJobsGql, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const pendingJobs = filter(data?.pendingJobHiringJobs, (job) =>
    dayjs(job.scheduleDate).isBefore(dayjs().endOf('day')),
  );
  const dateGroupJobs = groupBy(pendingJobs, (job) => dayjs(job.scheduleDate).format('YYYY-MM-DD'));
  const dates = orderBy(keys(dateGroupJobs), (date) => dayjs(date));

  const { contextDates, setContextDates } = React.useContext(AppContext);

  const dateSelectorHandler = React.useCallback((date: DateType) => {
    const laneRef = datesLaneRef.current[date.date];

    if (laneRef) {
      laneRef.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      laneRef.animate({ opacity: [1, 0.25, 1, 0.25, 1] }, 1000);
    }
  }, []);

  React.useEffect(() => {
    const nextContextDates = map(dates, (date) => ({ date, onClick: dateSelectorHandler }));
    if (!isEqual(contextDates, nextContextDates)) {
      setContextDates(nextContextDates);
    }
  }, [contextDates, dateSelectorHandler, dates, setContextDates]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ContainerFlexHeight maxWidth={false} disableGutters sx={{ display: 'flex' }}>
      <Stack
        flexDirection="row"
        flex={1}
        sx={{ overflowX: 'scroll', scrollSnapType: { xs: 'x mandatory', sm: 'inherit' } }}
      >
        {map(dates, (date) => (
          <DateJobHiringList
            key={date}
            ref={(ref) => {
              datesLaneRef.current[date] = ref;
            }}
            date={date}
            outerStackProps={{
              width: 330,
              margin: 0.5,
              sx: { scrollSnapAlign: 'center', scrollSnapStop: 'always' },
            }}
          />
        ))}
      </Stack>
    </ContainerFlexHeight>
  );
};
