import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Collapse,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { BottomSafeArea, LoadingButton, apiAxiosAuth } from '../components';
import { createScopedI18n, i18n } from '../i18n/i18n';
import { joinPairs } from '../utils/libs';
import { AppContext } from '../contexts/app_context';
import { Navigate } from 'react-router-dom';
import Snackbar from '../utils/snackbar';
import tokenManager from '../utils/token_manager';
import { useApolloClient } from '@apollo/client';

const changePasswordPageI18n = createScopedI18n('pages.change_password');
const changePasswordFieldI18n = createScopedI18n('graphql.fields.change_password');
const changePasswordErrorI18n = createScopedI18n('graphql.errors.types.change_password.fields', { joinOutput: true });

const ChangePasswordSchema = Yup.object({
  oldPassword: Yup.string().required(changePasswordErrorI18n('old_password.blank')),
  newPassword: Yup.string().required(changePasswordErrorI18n('new_password.blank')),
  newPasswordConfirm: Yup.string()
    .required(changePasswordErrorI18n('confirm_password.blank'))
    .oneOf([Yup.ref('newPassword')], changePasswordErrorI18n('confirm_password.not_same')),
});

type ChangePasswordValueType = Yup.InferType<typeof ChangePasswordSchema>;

export const ForceChangePasswordPage = () => {
  const apolloClient = useApolloClient();
  const {
    setContextIsLogin,
    setContextUser,
    setContextRedirectFrom,
    setContextRestaurants,
    setContextCurrentRestaurant,
    setContextInfoCompleteStatus,
  } = React.useContext(AppContext);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
  });
  const { contextInfoCompleteStatus } = React.useContext(AppContext);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const submitHandler = async (value: ChangePasswordValueType) => {
    setErrorMessage(null);
    try {
      const { data, status } = await apiAxiosAuth('PATCH', '/users/passwords/', {
        data: {
          password: value.oldPassword,
          new_password: value.newPassword,
          password_confirmation: value.newPasswordConfirm,
        },
      });

      if (status === 200) {
        Snackbar.enqueueSuccess('SIGN_OUT_CHANGE_PASSWORD');
        // Clear data
        await tokenManager.clearTokens();
        await apolloClient.clearStore();
        localStorage.clear();

        setContextUser(null);
        setContextRestaurants(null);
        setContextCurrentRestaurant(null);
        setContextInfoCompleteStatus(null);
        setContextRedirectFrom(null);

        // Will auto redirect to /auth/sign_in
        setContextIsLogin(false);
      } else {
        const errors = get(data, 'errors');
        setErrorMessage(errors ? joinPairs(errors) : changePasswordPageI18n('error'));
      }
    } catch (error) {
      const errors = get(error, ['response', 'data', 'errors']);
      setErrorMessage(errors ? joinPairs(errors) : get(error, ['message'], null));
    }
  };

  if (contextInfoCompleteStatus?.isForceChangePassword === false) {
    return <Navigate to="/" replace />; //Double check
  }

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <Typography variant="h6" color="primary">
        {changePasswordPageI18n('force_change_password_title')}
      </Typography>
      <Typography sx={{ fontSize: 'small', marginY: 1 }}>
        {changePasswordPageI18n('force_change_password_description')}
      </Typography>
      <Divider sx={{ width: '100%', height: 2, marginY: 2 }} />

      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container gap={2}>
          <Controller
            name="oldPassword"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{changePasswordFieldI18n('old_password')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField id={field.name} type="text" fullWidth value={value ?? ''} error={invalid} {...field} />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Divider sx={{ width: '100%', height: 2 }} />

          <Controller
            name="newPassword"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{changePasswordFieldI18n('new_password')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="password"
                      fullWidth
                      autoComplete="new-password"
                      value={value ?? ''}
                      error={invalid}
                      {...field}
                    />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            name="newPasswordConfirm"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{changePasswordFieldI18n('confirm_password')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="password"
                      fullWidth
                      autoComplete="new-password"
                      value={value ?? ''}
                      error={invalid}
                      {...field}
                    />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Collapse in={!!errorMessage} sx={{ width: '100%' }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>
          <LoadingButton type="submit" disabled={isSubmitting} loading={isSubmitting} fullWidth variant="contained">
            {i18n.t('general.confirm')}
          </LoadingButton>
        </Grid>
      </form>
      <BottomSafeArea />
    </Container>
  );
};
