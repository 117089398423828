import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Card, CardContent, Container, Stack } from '@mui/material';
import { isNil } from 'lodash';
import { useLocation } from 'react-router-dom';

const topUpStatusGql = gql(`
  query topUpStatus($referenceId: ID!) {
    topupStatus(referenceId: $referenceId) {
      success
      status
    }
  }
`);

export const CheckOutCreditCardPendingPage = () => {
  const { state }: { state?: { otpFormHtml?: string; redirectUrl?: string } } = useLocation();

  const popupWindowRef = React.useRef<Window | null>();

  const [referenceId, setReferenceId] = React.useState<string | null>(null);
  const { data, loading, refetch, startPolling, stopPolling } = useQuery(topUpStatusGql, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { referenceId },
    skip: isNil(referenceId),
  });

  React.useEffect(() => {
    if (data?.topupStatus?.status === 'complete') {
      stopPolling();
    } else if (data?.topupStatus?.status === 'error') {
      stopPolling();
    }
  }, [data?.topupStatus?.status, stopPolling]);

  React.useEffect(() => {
    const postMessageHandler = (event: MessageEvent) => {
      const referenceId = event.data;
      // TODO: Check for event.origin url instead of typeof event.data
      if (typeof referenceId === 'string') {
        setReferenceId(referenceId);
        startPolling(10 * 1000);
        refetch({ referenceId });
      }
    };

    window.addEventListener('message', postMessageHandler, false);

    return () => window.removeEventListener('message', postMessageHandler, false);
  }, [refetch, startPolling]);

  React.useEffect(() => {
    if (popupWindowRef.current && !popupWindowRef.current?.closed) return;

    if (state?.otpFormHtml) {
      const popup = window.open('about:blank', '', '_blank');
      popupWindowRef.current = popup;
      popup?.document.write(state.otpFormHtml);
      popup?.document.close();
    } else if (state?.redirectUrl) {
      const popup = window.open(state.redirectUrl, '', '_blank');
      popupWindowRef.current = popup;
    } else {
      popupWindowRef.current = null;
    }
  }, [state?.otpFormHtml, state?.redirectUrl]);

  return (
    <Container maxWidth="sm" sx={{ paddingTop: 4 }}>
      <Stack alignItems="center">
        <Card sx={{ width: '75%', backgroundColor: 'red' }}>
          <CardContent></CardContent>
        </Card>
      </Stack>
    </Container>
  );
};
