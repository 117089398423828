import React from 'react';

import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { NumberTextField } from './number_text_field';
import { i18n } from '../i18n/i18n';

import type { FormControlLabelProps, RadioGroupProps, TextFieldProps } from '@mui/material';

export type RadioGroupWithOptionProps = {
  textFieldMode?: 'text' | 'number';
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | null) => void;
  onValueChange?: (value: string | null) => void;
  TextFieldProps?: TextFieldProps;
  TextFieldFormControlLabelProps?: FormControlLabelProps;
} & Omit<RadioGroupProps, 'onChange'>;

export const RadioGroupWithOption = React.forwardRef(
  (
    {
      textFieldMode = 'text',
      TextFieldProps,
      TextFieldFormControlLabelProps,
      value,
      onChange,
      onValueChange,
      children,
      ...props
    }: RadioGroupWithOptionProps,
    ref,
  ) => {
    const inputRef = React.useRef<unknown>(null);

    const CustomTextField = textFieldMode === 'number' ? NumberTextField : TextField;

    const [selection, setSelection] = React.useState<string | '_input' | null>(value ?? null);
    const [valueInternal, setValueInternal] = React.useState(selection);

    const onChangeHandler = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: string | null,
    ) => {
      const newEvent = { ...event, target: { ...event.target, value: value ?? '' } };
      onChange && onChange(newEvent, value);
      onValueChange && onValueChange(value);
    };

    const onSelectionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setSelection(value);
      setValueInternal(null);
      if (value === '_input') {
        onChangeHandler(event, '');
        // @ts-expect-error ref any type
        setTimeout(() => inputRef.current?.focus(), 10);
      } else {
        onChangeHandler(event, value);
      }
    };

    const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setValueInternal(value);
      onChangeHandler(event, value);
    };

    return (
      <RadioGroup ref={ref} {...props} value={selection} onChange={onSelectionChangeHandler}>
        {children}

        <FormControlLabel
          {...TextFieldFormControlLabelProps}
          key="_input"
          value="_input"
          control={<Radio />}
          label={
            <CustomTextField
              inputRef={inputRef}
              size="small"
              placeholder={i18n.t('general.other_please_specify')}
              {...TextFieldProps}
              value={selection === '_input' ? value ?? valueInternal ?? '' : ''}
              onChange={onTextFieldChange}
              onClick={(event: unknown) => onSelectionChangeHandler(event as never, '_input')}
            />
          }
        />
      </RadioGroup>
    );
  },
);
