import { Button, Stack } from '@mui/material';
import { useParams } from 'react-router';
import { ContainerFlexHeight, useTrackPageView } from '../../../components';
import { JobCandidateHiringList } from './job_candidate_hiring_list';
import JobDetailSummary from './job_detail_summary';

const JobDetailPage = () => {
  const { id: jobId } = useParams();

  useTrackPageView('JobDetailPage', undefined, { trackUrlChange: true });

  return (
    <ContainerFlexHeight maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', paddingTop: 2, gap: 1 }}>
      <Stack>
        <JobDetailSummary jobId={jobId} />
      </Stack>

      <Stack flex={1} flexDirection="row" sx={{ overflowX: 'scroll' }}>
        <JobCandidateHiringList jobId={jobId} />
      </Stack>
    </ContainerFlexHeight>
  );
};

export default JobDetailPage;
