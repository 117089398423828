import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty, map, trim } from 'lodash';
import type { ButtonProps } from '@mui/material';
import { LoadingSpinner, useGoBack } from '../../../components';
import { createScopedI18n, i18n } from '../../../i18n/i18n';
import { formatDate, formatTime, formattedDuration, labelForEnum } from '../../../utils/libs';
import { CancelJobDialog } from './cancel_job_dialog';
import { CloseJobDialog } from './close_job_dialog';

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');
const jobFieldI18n = createScopedI18n('graphql.fields.job');

export type JobDetailSummary = {
  jobId?: string;
  borderColor?: string;
} & ButtonProps;

const jobDetailGql = gql(`
  query GetJobDetail($jobId: ID!) {
    job(id: $jobId) {
      id
      jobType
      scheduleDate
      jobStartAt
      jobEndAt
      status
      gender
      duties
      jobDurationMinute
      breakDurationMinute
      numberOfPosition
      numberOfHired
      benefit
      remark
      jobUrgent
      cancelFee
    }
  }
`);

const JobDetailSummary = ({ jobId, borderColor = 'primary.main', sx, ...props }: JobDetailSummary) => {
  const goBack = useGoBack();

  const { data, loading } = useQuery(jobDetailGql, {
    variables: { jobId },
    skip: !jobId,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const job = data?.job;

  // TODO: improve it
  if (!loading && job === null) {
    throw new Response('Not Found', { status: 404 });
  }

  const [showJobDialog, setShowJobDialog] = React.useState(false);
  const openJobDialogHandler = () => setShowJobDialog(true);
  const closeJobDialogHandler = () => setShowJobDialog(false);

  const [showCloseJobDialog, setShowCloseJobDialog] = React.useState(false);
  const openCloseJobDialogHandler = () => setShowCloseJobDialog(true);
  const closeCloseJobDialogHandler = () => setShowCloseJobDialog(false);

  const [showCancelJobDialog, setShowCancelJobDialog] = React.useState(false);
  const openCancelJobDialogHandler = () => setShowCancelJobDialog(true);
  const closeCancelJobDialogHandler = () => setShowCancelJobDialog(false);

  if (loading || isEmpty(job)) {
    return (
      <Button
        variant="outlined"
        color="inherit"
        disabled
        fullWidth
        {...props}
        sx={{ border: 1, borderColor, padding: 0, textTransform: 'none', textAlign: 'inherit', ...sx }}
      >
        <Stack justifyContent="center" alignItems="center" height={24} margin={1}>
          {loading ? (
            <LoadingSpinner size={24} />
          ) : (
            <Typography sx={{ opacity: 0.6 }}>{i18n.t('general.empty')}</Typography>
          )}
        </Stack>
      </Button>
    );
  }

  return (
    <>
      <Stack flexDirection="row" gap={1}>
        <Button
          variant="outlined"
          color="inherit"
          fullWidth
          onClick={openJobDialogHandler}
          {...props}
          sx={{ border: 1, borderColor, padding: 0, textTransform: 'none', textAlign: 'inherit', ...sx }}
        >
          <Stack flex={1} paddingX={2} paddingY={1}>
            <Typography variant="body2" textAlign="start">
              {labelForEnum('job_type', job.jobType)}, {formatDate(job.scheduleDate, 'ddd D MMM YYYY')},{' '}
              {formatTime(job.jobStartAt)} - {formatTime(job.jobEndAt)},{' '}
              {jobDetailPageI18n('job_duration_duration', { duration: formattedDuration(job.jobDurationMinute) })},{' '}
              {jobDetailPageI18n('job_gender', { gender: labelForEnum('gender', job.gender ?? 'unspecified') })}
            </Typography>
          </Stack>
          <Divider orientation="vertical" flexItem sx={{ backgroundColor: borderColor }} />
          <Stack paddingX={2} paddingY={1}>
            <Typography noWrap>
              {job.numberOfHired} / {job.numberOfPosition}
            </Typography>
          </Stack>
        </Button>

        {/*
        <Button variant="contained" color="error" disableElevation onClick={openCancelDialogHandler} sx={{ border: 1, whiteSpace: 'nowrap', minWidth: 'auto' }}>
          {jobDetailPageI18n('cancel_job')}
        </Button>
        */}
      </Stack>

      <Dialog fullWidth open={showJobDialog} onClose={closeJobDialogHandler}>
        <DialogTitle display="inline-flex" alignItems="center" gap={1}>
          <Typography flex={1} variant="inherit">
            {jobDetailPageI18n('job_detail')}
          </Typography>
          <IconButton onClick={closeJobDialogHandler}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h6" color="primary">
            <Typography variant="inherit" display="inline">
              {labelForEnum('job_type', job.jobType)}{' '}
              {jobDetailPageI18n('job_number_of_position', { count: job.numberOfPosition })}
            </Typography>
            <Typography variant="inherit" display="inline">
              {' '}
              (
              {job.status === 'replacing'
                ? labelForEnum('job_status', 'replacing')
                : labelForEnum('job_urgents', job.jobUrgent)}
              )
            </Typography>
          </Typography>

          <Box>
            <Typography>
              <Typography variant="inherit" color="primary" display="inline">
                {jobDetailPageI18n('job_time')}:{' '}
              </Typography>
              {formatTime(job.jobStartAt)} - {formatTime(job.jobEndAt)}
            </Typography>
            <Typography>
              <Typography variant="inherit" color="primary" display="inline">
                {jobDetailPageI18n('job_duration')}:{' '}
              </Typography>
              {formattedDuration(job.jobDurationMinute)}
            </Typography>
            <Typography>
              <Typography variant="inherit" color="primary" display="inline">
                {jobFieldI18n('break_duration')}:{' '}
              </Typography>
              {job.breakDurationMinute
                ? formattedDuration(job.breakDurationMinute)
                : jobDetailPageI18n('job_break_duration_none')}
            </Typography>
            <Typography>
              <Typography variant="inherit" color="primary" display="inline">
                {jobFieldI18n('gender')}:{' '}
              </Typography>
              {labelForEnum('gender', job.gender ?? 'unspecified')}
            </Typography>
          </Box>

          <Box>
            <Typography color="primary">{jobFieldI18n('detail')}</Typography>
            <Typography variant="body2" style={{ whiteSpace: 'break-spaces' }}>
              {job.remark ? trim(job.remark) : i18n.t('general.unspecified')}
            </Typography>
          </Box>

          {job.jobType !== 'other' && (
            <Box>
              <Typography color="primary">{jobFieldI18n('duty')}</Typography>
              {isEmpty(JSON.parse(job.duties ?? [])) ? (
                <Typography variant="body2">{i18n.t('general.unspecified')}</Typography>
              ) : (
                map(JSON.parse(job.duties ?? []), (duty, index) => (
                  <Typography key={index} variant="body2">
                    {index + 1}. {labelForEnum('job_duty', duty)}
                  </Typography>
                ))
              )}
            </Box>
          )}

          <Box>
            <Typography color="primary">{jobFieldI18n('benefit')}</Typography>
            <Typography variant="body2" style={{ whiteSpace: 'break-spaces' }}>
              {job.benefit ?? i18n.t('general.unspecified')}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="text" color="error" fullWidth disableElevation onClick={openCancelJobDialogHandler}>
            {jobDetailPageI18n('cancel_job')}
          </Button>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            disableElevation
            disabled={job?.status === 'full' || job?.status === 'close' || job?.status === 'cancel'}
            onClick={openCloseJobDialogHandler}
          >
            {jobDetailPageI18n('close_job')}
          </Button>
        </DialogActions>
      </Dialog>

      <CloseJobDialog open={showCloseJobDialog} jobId={jobId} onClose={closeCloseJobDialogHandler} onSuccess={goBack} />

      <CancelJobDialog
        open={showCancelJobDialog}
        jobId={jobId}
        onClose={closeCancelJobDialogHandler}
        onSuccess={goBack}
      />
    </>
  );
};

export default JobDetailSummary;
