import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import SnackbarErrorVariant from './snackbar_error_variant';
import SnackbarSuccessVariant from './snackbar_success_variant';
import { useMediaQuery, useTheme } from '@mui/material';

declare module 'notistack' {
  interface VariantOverrides {
    snackbarErrorVariant: true;
    snackbarSuccessVariant: true;
  }
}

const SnackbarProvider = (props: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down('sm'));

  const { children } = props;

  return (
    <NotistackSnackbarProvider
      Components={{
        snackbarErrorVariant: SnackbarErrorVariant,
        snackbarSuccessVariant: SnackbarSuccessVariant,
      }}
      maxSnack={1}
      anchorOrigin={
        isSmallWidth ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'center' }
      }
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
