import React from 'react';

import { ThemeProvider, useMediaQuery } from '@mui/material';
import { AppContext } from '../contexts/app_context';
import { createCustomTheme } from '../utils/theme';

import type { ThemeProviderProps } from '@mui/material/styles/ThemeProvider';
import type { ThemeModeType } from '../contexts/app_context';

export type CustomMuiThemeProviderProps = ThemeProviderProps;

const CustomMuiThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const isSystemDark = useMediaQuery('(prefers-color-scheme: dark)');
  const systemThemeMode = isSystemDark ? 'dark' : 'light';
  const { contextThemeMode, setContextThemeMode } = React.useContext(AppContext);

  React.useEffect(() => {
    const savedThemeMode = localStorage.getItem('THEME_MODE') as ThemeModeType | null;
    setContextThemeMode(savedThemeMode ?? 'system');
  }, []);

  const themeMode = contextThemeMode === 'system' ? systemThemeMode : contextThemeMode;
  const theme = createCustomTheme(themeMode);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomMuiThemeProvider;
