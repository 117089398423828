import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import { CardContent, IconButton, Stack, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { get, groupBy, isEmpty, map } from 'lodash';
import { CardLevel, LoadingSpinner } from '../../../components';
import { formatDate } from '../../../utils/libs';
import { RestaurantJobHiringsGroup } from './restaurant_job_hirings_group';

import type { StackProps } from '@mui/material';

export type DateJobHiringListProps = {
  date: string | dayjs.Dayjs;
  outerStackProps?: StackProps;
};

const getPendingJobHiringJobsPerDateGql = gql(`
  query getPendingJobHiringJobsPerDate($date: Date) {
    pendingJobHiringJobs(date: $date) {
      id
      restaurant {
        id
        name
        branchName
      }
    }
  }
`);

export const DateJobHiringList = React.forwardRef<HTMLDivElement, DateJobHiringListProps>(
  ({ date, outerStackProps }, ref) => {
    const theme = useTheme();

    const { data, loading, refetch } = useQuery(getPendingJobHiringJobsPerDateGql, {
      variables: { date },
      skip: !date,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

    const refetchHandler: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
      event.stopPropagation();
      await refetch();
    };

    const jobs = data?.pendingJobHiringJobs ?? [];
    const restaurantGroupJobs = map(
      groupBy(jobs, (job) => job.restaurant.id),
      (jobs) => ({ restaurant: get(jobs, [0, 'restaurant']), jobIds: map(jobs, 'id') }),
    );

    return (
      <Stack
        ref={ref}
        flexShrink={0}
        border={1}
        borderRadius={1}
        borderColor={theme.palette.divider}
        {...outerStackProps}
      >
        <Stack paddingX={1} paddingTop={0.75}>
          <Stack flexDirection="row" alignItems="center" paddingLeft={2} height={64} gap={1}>
            <Typography
              variant="h6"
              flex={1}
              sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}
            >
              {formatDate(date)}
            </Typography>

            <IconButton onClick={refetchHandler}>
              <Refresh />
            </IconButton>
          </Stack>
        </Stack>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <Stack flex={1} sx={{ overflowY: 'scroll' }}>
            <Stack gap={1} padding={0.75}>
              {isEmpty(jobs) ? (
                <CardLevel variant="outlined" level={1}>
                  <CardContent>
                    <Typography variant="subtitle2" textAlign="center" sx={{ opacity: 0.6 }}>
                      ไม่มีข้อมูล
                    </Typography>
                  </CardContent>
                </CardLevel>
              ) : (
                map(restaurantGroupJobs, ({ restaurant, jobIds }) => (
                  <RestaurantJobHiringsGroup key={restaurant.id} restaurant={restaurant} jobIds={jobIds} />
                ))
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  },
);
