import React from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { map } from 'lodash';

import type { ToggleButtonGroupProps } from '@mui/material';

export type RadioButtonGroupProps = {
  items: { label: React.ReactNode; value: any }[];
  value?: any;
  onChangeValue?: (value: any) => void;
} & Omit<ToggleButtonGroupProps, 'children'>;

const RadioButtonGroup = ({ items, value, onChangeValue, ...props }: RadioButtonGroupProps) => {
  const onChangeHandler = (_event: any, value: any) => {
    onChangeValue && onChangeValue(value);
  };

  return (
    <ToggleButtonGroup exclusive value={value} onChange={onChangeHandler} {...props}>
      {map(items, (item) => (
        <ToggleButton key={item.value} value={item.value}>
          {item.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default RadioButtonGroup;
