import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Link as LinkMui,
  Radio,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { get, isEmpty, map } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  BottomSafeArea,
  LoadingButton,
  LoadingSpinner,
  RadioGroupWithOption,
  apiAxios,
  useHttpRequest,
} from '../../components';
import { APP_NAME } from '../../config';
import { createScopedI18n, i18n } from '../../i18n/i18n';
import { joinPairs, labelFieldEnum } from '../../utils/libs';

const registerPageI18n = createScopedI18n('pages.register');
const registerFieldI18n = createScopedI18n('graphql.fields.register');
const registerErrorI18n = createScopedI18n('graphql.errors.types.register.fields', { joinOutput: true });

const registerUserSchema = Yup.object({
  name: Yup.string().required(registerErrorI18n('name.blank')),
  businessTypes: Yup.string().required(registerErrorI18n('business_type.blank')),
  contactName: Yup.string().required(registerErrorI18n('contact_name.blank')),
  contactPhoneNumber: Yup.string().required(registerErrorI18n('contact_phone_number.blank')),
  contactEmail: Yup.string()
    .required(registerErrorI18n('contact_email.blank'))
    .email(registerErrorI18n('contact_email.invalid')),
  contactLineId: Yup.string().required(registerErrorI18n('contact_line_id.blank')),
  referrerType: Yup.string(),
  isAcceptTermAndCondition: Yup.boolean()
    .default(false)
    .isTrue(registerErrorI18n('is_accept_term_and_condition.false')),
});

type registerUserValueType = Yup.InferType<typeof registerUserSchema>;

export const RegisterPage = () => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { referrerType: 'application' },
    resolver: yupResolver(registerUserSchema),
  });

  const { response: businessTypeResponse, isLoading: businessTypeLoading } = useHttpRequest({
    url: '/enums/business_types',
  });
  const businessTypeOptions: string[] | undefined = businessTypeResponse?.data;

  const { response: referrerTypeResponse, isLoading: referrerTypeLoading } = useHttpRequest({
    url: '/enums/referrer_types',
  });
  const referrerTypeOptions: string[] | undefined = referrerTypeResponse?.data;

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const submitHandler = async (value: registerUserValueType) => {
    setErrorMessage(null);

    try {
      await apiAxios({
        method: 'POST',
        url: '/registration',
        data: {
          name: value.name,
          business_type: value.businessTypes,
          referrer_type: value.referrerType,
          contact_name: value.contactName,
          contact_email: value.contactEmail,
          contact_line_id: value.contactLineId,
          contact_phone_number: value.contactPhoneNumber,
          district: 'กรุงเทพฯ และปริมณฑล',
        },
      });

      // if status === OK
      navigate('/auth/thank_you');
    } catch (error) {
      const status = get(error, ['response', 'status']);
      const errors = get(error, ['response', 'data', 'errors']);

      if (status === 423) {
        navigate('/auth/reset_password');
      }

      if (isEmpty(errors)) {
        setErrorMessage(get(error, 'message', null));
      } else {
        setErrorMessage(joinPairs(errors));
      }
    }
  };

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container gap={2}>
          <Stack width="100%" alignItems="center">
            <LinkMui href="https://findtemp.in.th/" target="_blank" rel="noopener">
              <Box
                component="img"
                src="/logo.png"
                alt={i18n.t('general.logo_alt')}
                height={160}
                width={160}
                sx={{ objectFit: 'contain', aspectRatio: 1 }}
              />
            </LinkMui>
            <Typography color="primary" fontWeight="bold" variant="subtitle1">
              {i18n.t('general.brand_title')}
            </Typography>
          </Stack>

          <Controller
            key="name"
            control={control}
            name="name"
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{registerFieldI18n('name')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField id={field.name} type="text" fullWidth value={value ?? ''} error={invalid} {...field} />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            key="businessTypes"
            control={control}
            name="businessTypes"
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <FormLabel>{registerFieldI18n('business_type')} *</FormLabel>
                <Collapse in={businessTypeLoading} unmountOnExit>
                  <LoadingSpinner size={24} sx={{ marginTop: 2 }} />
                </Collapse>
                <Collapse in={!businessTypeLoading}>
                  <RadioGroupWithOption value={value ?? null} {...field} sx={{ paddingLeft: 2, paddingTop: 2, gap: 1 }}>
                    {map(businessTypeOptions, (businessType) => (
                      <FormControlLabel
                        key={businessType}
                        value={businessType}
                        label={labelFieldEnum('register.business_types', businessType)}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroupWithOption>
                </Collapse>

                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            key="contactName"
            control={control}
            name="contactName"
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{registerFieldI18n('contact_name')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField id={field.name} type="text" fullWidth value={value ?? ''} error={invalid} {...field} />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            key="contactPhoneNumber"
            control={control}
            name="contactPhoneNumber"
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{registerFieldI18n('contact_phone_number')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField id={field.name} type="tel" fullWidth value={value ?? ''} error={invalid} {...field} />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            key="contactEmail"
            control={control}
            name="contactEmail"
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{registerFieldI18n('contact_email')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField id={field.name} type="email" fullWidth value={value ?? ''} error={invalid} {...field} />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            key="contactLineId"
            control={control}
            name="contactLineId"
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{registerFieldI18n('contact_line_id')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField id={field.name} type="text" fullWidth value={value ?? ''} error={invalid} {...field} />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            key="referrerType"
            control={control}
            name="referrerType"
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <FormLabel>{registerFieldI18n('referrer_type', { app_name: APP_NAME })}</FormLabel>
                <Collapse in={referrerTypeLoading} unmountOnExit>
                  <LoadingSpinner size={24} sx={{ marginTop: 2 }} />
                </Collapse>
                <Collapse in={!referrerTypeLoading}>
                  <RadioGroupWithOption value={value ?? null} {...field} sx={{ paddingLeft: 2, paddingTop: 2, gap: 1 }}>
                    {map(referrerTypeOptions, (referrerType) => (
                      <FormControlLabel
                        key={referrerType}
                        value={referrerType}
                        label={labelFieldEnum('register.referrer_types', referrerType)}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroupWithOption>
                </Collapse>

                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            key="isAcceptTermAndCondition"
            control={control}
            name="isAcceptTermAndCondition"
            render={({ field: { value, onChange, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <FormControlLabel
                  value={value}
                  onChange={onChange}
                  control={<Checkbox />}
                  label={
                    <Typography color={invalid ? 'error' : undefined}>
                      {registerPageI18n('accept_condition_1', { app_name: APP_NAME })}
                      <LinkMui href="https://www.findtemp.in.th/term-condition" target="_blank" rel="noopener">
                        {i18n.t('general.term_condition')}
                      </LinkMui>
                      {` ${i18n.t('general.and')} `}
                      <LinkMui href="https://www.findtemp.in.th/privacy-policy" target="_blank" rel="noopener">
                        {i18n.t('general.privacy_policy')}
                      </LinkMui>
                      {registerPageI18n('accept_condition_2')} *
                    </Typography>
                  }
                />

                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          <Collapse in={!!errorMessage}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>
          <LoadingButton type="submit" disabled={isSubmitting} loading={isSubmitting} fullWidth variant="contained">
            {i18n.t('general.register')}
          </LoadingButton>
        </Grid>
      </form>

      <BottomSafeArea />
    </Container>
  );
};
