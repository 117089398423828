import React from 'react';

import { AutoAwesome, Cached, CheckCircleOutline, HourglassBottom, People } from '@mui/icons-material';
import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import { concat } from 'lodash';
import { createScopedI18n } from '../../../i18n/i18n';

const jobPageI18n = createScopedI18n('pages.jobs');

export type JobStatusChipsProps = {
  jobStatus: string;
  jobExpiredAt: string;
  isImmediateAccept: boolean;
  applicantCount: number;
};

const JobStatusChips = ({ jobStatus, jobExpiredAt, isImmediateAccept, applicantCount }: JobStatusChipsProps) => {
  let statusChips: React.ReactNode = [];

  if (jobStatus === 'full') {
    statusChips = concat(
      statusChips,
      <Chip
        key="jobFull"
        size="small"
        color="success"
        variant="outlined"
        label={jobPageI18n('job_full')}
        icon={<CheckCircleOutline />}
        sx={{ padding: '4px' }}
      />,
    );
  }

  if (jobStatus === 'open' && dayjs(/* now */).isBefore(jobExpiredAt)) {
    if (applicantCount > 0) {
      statusChips = concat(
        statusChips,
        <Chip
          key="haveApplicants"
          size="small"
          color="warning"
          label={jobPageI18n('job_applicants_count', { count: applicantCount })}
          icon={<People />}
          sx={{ padding: '4px' }}
        />,
      );
    } else {
      statusChips = concat(
        statusChips,
        <Chip
          key="waitApplicants"
          size="small"
          label={jobPageI18n('job_applicants_wait')}
          icon={<HourglassBottom />}
          sx={{ padding: '4px' }}
        />,
      );
    }
  }

  if (jobStatus === 'replacing') {
    statusChips = concat(
      statusChips,
      <Chip
        key="jobReplacing"
        size="small"
        label={jobPageI18n('job_replacing')}
        icon={<Cached />}
        sx={{ padding: '4px' }}
      />,
    );
  }

  if (jobStatus !== 'full' && isImmediateAccept) {
    statusChips = concat(
      statusChips,
      <Chip
        key="jobImmediateAccept"
        size="small"
        label={jobPageI18n('job_auto_accept')}
        icon={<AutoAwesome />}
        sx={{ padding: '4px' }}
      />,
    );
  }

  return <>{statusChips}</>;
};

export default JobStatusChips;
