import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { i18n } from '../i18n/i18n';

export const UpdatePWADialog = () => {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW();

  const updatePWAHandler = React.useCallback(async () => {
    await updateServiceWorker();
    setNeedRefresh(false); //* Update the state after the service worker is updated
    setShowDialog(false);
  }, [updateServiceWorker, setNeedRefresh]);

  const [showDialog, setShowDialog] = React.useState(false);

  const onCloseHandler = () => {
    setShowDialog(false);
    setNeedRefresh(false);
  };

  React.useEffect(() => {
    if (needRefresh && import.meta.env.MODE !== 'production') {
      setShowDialog(true);
    }
  }, [needRefresh]);

  return (
    <Dialog open={showDialog} onClose={onCloseHandler}>
      <DialogTitle>{i18n.t('components.update_dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{i18n.t('components.update_dialog.description')}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCloseHandler}>{i18n.t('general.cancel')}</Button>
        <Button variant="contained" disableElevation onClick={updatePWAHandler}>
          {i18n.t('general.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
