import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Refresh, Store } from '@mui/icons-material';
import { Avatar, CardContent, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { isEmpty, map, isEqual } from 'lodash';
import type { StackProps } from '@mui/material';
import { CardLevel, LoadingSpinner } from '../../../components';
import { i18n } from '../../../i18n/i18n';
import DateJobsCard from './date_jobs_card';
import type { RestaurantType } from '../../../contexts/app_context';
import { AppContext } from '../../../contexts/app_context';

export type RestaurantDateJobsListProps = {
  restaurant: RestaurantType;
  outerStackProps?: StackProps;
};

const incomingJobsGql = gql(`
  query incomingJobs($restaurantId: ID) {
    incomingJobs(restaurantId: $restaurantId) {
      date
    }
  }
`);

export const RestaurantDateJobsList = React.forwardRef<HTMLDivElement, RestaurantDateJobsListProps>(
  ({ restaurant, outerStackProps }, ref) => {
    const theme = useTheme();
    const { setContextRestaurants, contextRestaurants } = React.useContext(AppContext);

    const { data, loading, refetch, client } = useQuery(incomingJobsGql, {
      variables: { restaurantId: restaurant.id },
      skip: !restaurant.id,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

    const refreshHandler = async () => {
      const { data } = await refetch();

      map(data?.incomingJobs, ({ date }) => {
        if (restaurant.id && date) {
          client.cache.evict({
            fieldName: 'jobs',
            args: { restaurantId: restaurant.id, date },
          });
        }
      });
    };

    // Note: This code use for set grouping hiring for restaurants selector in navbar
    React.useEffect(() => {
      if (!isEmpty(data?.incomingJobs)) {
        const updatedRestaurants = map(contextRestaurants, (res) => ({
          ...res,
          isHiring: isEqual(res.id, restaurant.id),
        }));
        if (!isEqual(updatedRestaurants, contextRestaurants)) {
          // Note: Context keep RestaurantType[], Example: [{ id, isInfoComplete, isHiringname, branchName, businessType, frontPhotoUri, }]
          setContextRestaurants(updatedRestaurants);
        }
      }
    }, [data?.incomingJobs]);

    return (
      <Stack
        ref={ref}
        flexShrink={0}
        border={1}
        borderRadius={1}
        borderColor={theme.palette.divider}
        {...outerStackProps}
      >
        <Stack paddingX={1} paddingTop={0.75}>
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap={1}>
            <IconButton size="small" color="info" onClick={refreshHandler}>
              <Refresh />
            </IconButton>
          </Stack>

          <Stack flexDirection="row" alignItems="center" height={64} gap={1}>
            <Avatar src={restaurant.frontPhotoUri} sx={{ width: 48, height: 48 }}>
              <Store />
            </Avatar>

            <Typography
              variant="h6"
              flex={1}
              sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}
            >
              {restaurant.name} {restaurant.branchName}
            </Typography>
          </Stack>
        </Stack>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <Stack flex={1} sx={{ overflowY: 'scroll', scrollPadding: '16px' }}>
            <Stack gap={1} padding={0.75}>
              {isEmpty(data?.incomingJobs) ? (
                <CardLevel variant="outlined" level={1}>
                  <CardContent>
                    <Typography variant="subtitle2" textAlign="center" sx={{ opacity: 0.6 }}>
                      {i18n.t('pages.jobs.no_jobs')}
                    </Typography>
                  </CardContent>
                </CardLevel>
              ) : (
                map(data?.incomingJobs, ({ date }) => (
                  <DateJobsCard key={date} date={date} restaurantId={restaurant.id} />
                ))
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  },
);

export default RestaurantDateJobsList;
