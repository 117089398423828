import React from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Drawer, IconButton, Toolbar, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CloseOrCancelJobButton } from './close_or_cancel_job_button';
import JobDetailPage from './page';

export const JobDetailLayout = () => {
  const theme = useTheme();

  const { id: jobId } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const onDrawerCloseHandler = React.useCallback(() => {
    setOpen(false);
    navigate('/jobs', { replace: true });
  }, [navigate]);

  React.useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | undefined;
    if (jobId) {
      // Wait for React Route changing route to play open animation
      timeoutId = setTimeout(() => {
        setOpen(true);
      }, 10);
    } else {
      onDrawerCloseHandler();
    }

    return () => clearTimeout(timeoutId);
  }, [jobId, onDrawerCloseHandler]);

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        variant="persistent"
        onClose={onDrawerCloseHandler}
        PaperProps={{
          variant: 'outlined',
          sx: {
            width: '100%',
            maxWidth: theme.breakpoints.values.md,
          },
        }}
      >
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" onClick={onDrawerCloseHandler}>
            <ArrowBack />
          </IconButton>
          <div style={{ flexGrow: 1 }} />
          <CloseOrCancelJobButton jobId={jobId} onSuccess={onDrawerCloseHandler} />
        </Toolbar>

        <JobDetailPage />
      </Drawer>
    </>
  );
};
