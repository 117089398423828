import React from 'react';

import { Button, ButtonProps } from '@mui/material';
import dayjs from 'dayjs';
import { isNil, round } from 'lodash';
import { i18n } from '../i18n/i18n';

export type CooldownButtonProps = {
  cooldownUntil?: Date;
} & ButtonProps;

export const CooldownButton = ({ cooldownUntil, disabled, children, ...props }: CooldownButtonProps) => {
  const [secondLeft, setSecondLeft] = React.useState(0);
  const isCooldown = secondLeft > 0;

  React.useEffect(() => {
    let intervalId: NodeJS.Timer | undefined = undefined;
    const checkSecondLeft = () => {
      if (isNil(cooldownUntil)) {
        clearInterval(intervalId);
        return;
      }

      const second = round((cooldownUntil.valueOf() - dayjs().valueOf()) / 1000);
      if (second >= 0) {
        setSecondLeft(second);
      } else {
        setSecondLeft(0);
        clearInterval(intervalId);
      }
    };

    if (cooldownUntil) {
      checkSecondLeft();
      intervalId = setInterval(checkSecondLeft, 1000);
    } else {
      setSecondLeft(0);
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [cooldownUntil]);

  return (
    <Button disabled={isCooldown || disabled} {...props}>
      {isCooldown
        ? i18n.t('components.cooldown_button.cooling_down', {
            minute: dayjs.duration(secondLeft, 'seconds').format('m:ss'),
          })
        : children}
    </Button>
  );
};
