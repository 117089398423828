import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { ControlPointDuplicate, Refresh } from '@mui/icons-material';
import { Button, Card, CardContent, Container, Divider, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { map, range } from 'lodash';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { LoadingSpinner, useTrackPageView } from '../../../components';
import { MonthlyTransactionAccordion } from './monthly_transaction_accordion';

const getCreditAmountGql = gql(`
  query getCreditAmount {
    user {
      id
      credit
      sumHiringsCharge
    }
  }
`);

export const CreditPage = () => {
  useTrackPageView('CreditPage');

  const { data, loading, refetch } = useQuery(getCreditAmountGql, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const monthList = React.useMemo(
    () => map(range(0, 5), (monthOffset) => dayjs().subtract(monthOffset, 'months').startOf('month')),
    [],
  );

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <Stack gap={2}>
        <Card elevation={3}>
          <CardContent sx={{ height: 200, display: 'flex', flexDirection: 'column' }}>
            <Stack flexDirection="row" alignItems="center">
              <Typography flex={1}>เครดิตคงเหลือ</Typography>
              <IconButton onClick={() => refetch()}>
                <Refresh />
              </IconButton>
            </Stack>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Stack flex={1} justifyContent="center" alignItems="center">
                  <Typography variant="h3">{numeral(data?.user.credit).format('0,0.00')}</Typography>
                </Stack>
                <Stack>
                  <Typography>ยอดการจองจ้างงาน {numeral(data?.user.sumHiringsCharge).format('0,0')}</Typography>
                </Stack>
              </>
            )}
          </CardContent>
        </Card>

        <Button
          variant="contained"
          size="large"
          component={Link}
          to={'purchase'}
          startIcon={<ControlPointDuplicate />}
          sx={{ flexDirection: 'column' }}
        >
          ซื้อเครดิตใช้งาน
        </Button>

        <Divider />
        <Typography variant="subtitle1" paddingLeft={3}>
          รายงานค่าใช้จ่าย
        </Typography>
        <Stack>
          {map(monthList, (month) => (
            <MonthlyTransactionAccordion key={month.month()} year={month.year()} month={month.month() + 1} />
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};
