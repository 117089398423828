import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../contexts/app_context';

export const useGoBack = ({ fallbackPath }: { fallbackPath?: string } = {}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { contextIsLogin } = React.useContext(AppContext);

  const fallbackPathInternal = React.useMemo(() => {
    if (fallbackPath) {
      return fallbackPath;
    } else {
      return contextIsLogin ? '/jobs' : '/auth/sign_in';
    }
  }, [contextIsLogin, fallbackPath]);

  const goBack = React.useCallback(() => {
    if (window.history.state.idx === 0 || location.key === 'default') {
      navigate(fallbackPathInternal, { replace: true });
    } else {
      navigate(-1);
    }
  }, [location.key, navigate, fallbackPathInternal]);

  return goBack;
};
