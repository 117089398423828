import React from 'react';

import { FileUpload } from '@mui/icons-material';
import { Alert, Box, Card, Collapse, Stack, useTheme } from '@mui/material';
import { get, includes } from 'lodash';
import type { CardProps } from '@mui/material';
import { LoadingButton, apiAxiosAuth } from '.';
import { joinPairs } from '../utils/libs';

const defaultAspectRatio = 3 / 2;

export type UploadImagePickerCardProps = {
  initialSrc?: string | undefined;
  buttonText?: string | undefined;
  buttonUploadingText?: string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, image?: File) => void;
  onUploadDone?: (token: string) => void;
  onError?: (message?: string, error?: unknown) => void;
} & CardProps;

export const UploadImagePickerCard = ({
  onChange,
  onUploadDone,
  onError,
  initialSrc,
  buttonText,
  buttonUploadingText,
  sx,
  ...props
}: UploadImagePickerCardProps) => {
  const theme = useTheme();

  const abortControllerRef = React.useRef<AbortController | null>(null);

  const [image, setImage] = React.useState<File | null>(null);
  const imageUri = React.useMemo(
    () => (image instanceof File ? URL.createObjectURL(image) : initialSrc),
    [image, initialSrc],
  );

  const onImageChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    let nextImage;
    if (event.target.files && event.target.files[0]) {
      nextImage = event.target.files[0];
      if (includes(nextImage.type, 'image/')) {
        setImage(nextImage);
        uploadImage(nextImage);
      } else {
        setErrorMessage('Invalid Image Type');
        onError && onError('Invalid Image Type');
      }
    }
    onChange && onChange(event, nextImage);
  };

  const [isUploading, setIsUploading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const uploadImage = async (image: File) => {

    abortControllerRef.current?.abort();
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    setIsUploading(true);
    setErrorMessage(null);
    try {
      const formData = new FormData();
      formData.append('image', image);

      const { data } = await apiAxiosAuth('POST', 'image_service', {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
        signal: abortController.signal,
      });

      const success: boolean | undefined = get(data, 'success');
      const token: string | undefined = get(data, ['data', 'token']);

      if (success && token) {
        onUploadDone && onUploadDone(token);
      } else {
        const errors = get(data, 'errors');
        const errorMessage = joinPairs(errors);
        setErrorMessage(errorMessage);
        onError && onError(errorMessage);
      }
    } catch (error) {
      const errorMessage = get(error, 'message');
      setErrorMessage(errorMessage ?? null);
      onError && onError(errorMessage, error);
    }
    setIsUploading(false);
  };

  return (
    <Card {...props} sx={{ width: '100%', aspectRatio: defaultAspectRatio, ...sx }}>
      <Stack width="100%" height="100%" position="relative">
        {imageUri && (
          <Box
            component="img"
            src={imageUri}
            maxWidth="100%"
            sx={{ objectFit: 'contain', aspectRatio: get(sx, 'aspectRatio', defaultAspectRatio) }}
          />
        )}
        <Stack
          width="100%"
          height="100%"
          position="absolute"
          justifyContent="center"
          alignItems="center"
          gap={1}
          bgcolor="rgba(0,0,0,0.25)"
        >
          <LoadingButton
            id="input-image-upload"
            component="label"
            variant="contained"
            color="inherit"
            size="large"
            disableElevation
            loading={isUploading}
            disabled={isUploading}
            startIcon={<FileUpload />}
            sx={{ opacity: 0.85, minWidth: '40%' }}
            style={{ backgroundColor: theme.palette.background.paper }}
          >
            {isUploading ? buttonUploadingText ?? 'อัปโหลด' : buttonText ?? 'เลือกรูป'}
            <input id="input-image-upload" hidden type="file" accept="image/*" onChange={onImageChangeHandler} />
          </LoadingButton>

          <Collapse in={!!errorMessage}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>
        </Stack>
      </Stack>
    </Card>
  );
};
