import { Outlet } from 'react-router';
import JobsPage from './page';

export const JobsLayout = () => {
  return (
    <>
      <JobsPage />

      <Outlet />
    </>
  );
};
