import React from 'react';

import { gql, useApolloClient, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Collapse,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { BottomSafeArea, CooldownButton, LoadingButton, useTrackPageView } from '../components';
import { AppContext } from '../contexts/app_context';
import { createScopedI18n, i18n } from '../i18n/i18n';
import { joinPairs } from '../utils/libs';
import Snackbar, { SIGN_OUT_CHANGE_PHONE_NUMBER } from '../utils/snackbar';
import tokenManager from '../utils/token_manager';

const changePhoneNumberPageI18n = createScopedI18n('pages.change_phone_number');
const changePhoneNumberFieldI18n = createScopedI18n('graphql.fields.change_phone_number');
const changePhoneNumberErrorI18n = createScopedI18n('graphql.errors.types.change_phone_number.fields', {
  joinOutput: true,
});

const createOtpTokenGql = gql(`
  mutation createOtpToken($phoneNumber: String!) {
    createOtpToken(phoneNumber: $phoneNumber) {
      success
      errors
      data {
        id
        nextTry
        referenceNumber
      }
    }
  }
`);

const changeUsernameGql = gql(`
  mutation changeUsername(
    $otp: String!
    $password: String!
    $phoneNumber: String!
    $referenceNumber: String!
  ) {
    changeUsername(
      otp: $otp
      password: $password
      phoneNumber: $phoneNumber
      referenceNumber: $referenceNumber
    ) {
      success
      errors
    }
  }
`);

const changePhoneNumberSchema = Yup.object({
  phoneNumber: Yup.string().required(changePhoneNumberErrorI18n('phone_number.blank')),
  otp: Yup.string().required(changePhoneNumberErrorI18n('otp.blank')),
  referenceNumber: Yup.string(),
  password: Yup.string().required(changePhoneNumberErrorI18n('password.blank')),
});

type changePhoneNumberValueType = Yup.InferType<typeof changePhoneNumberSchema>;

export const ChangePhoneNumberPage = () => {
  useTrackPageView('ChangePhonePage');

  const apolloClient = useApolloClient();
  // prettier-ignore
  const { 
    setContextIsLogin, 
    setContextUser, 
    setContextRedirectFrom, 
    setContextRestaurants, 
    setContextCurrentRestaurant, 
    setContextInfoCompleteStatus 
  } = React.useContext(AppContext);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    trigger,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(changePhoneNumberSchema),
  });

  const phoneNumber = watch('phoneNumber');
  const referenceNumber = watch('referenceNumber');

  const [isGettingOtp, setIsGettingOtp] = React.useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = React.useState<string | null>(null);
  const [nextTryAt, setNextTryAt] = React.useState<Date | null>(null);
  const [createOtpTokenMutation] = useMutation(createOtpTokenGql);

  const getOtpHandler = async () => {
    if (!(await trigger('phoneNumber'))) {
      return;
    }

    setIsGettingOtp(true);
    setOtpErrorMessage(null);
    let nextTryAt;
    try {
      const { data } = await createOtpTokenMutation({
        variables: { phoneNumber },
      });

      if (data?.createOtpToken?.success) {
        nextTryAt = data?.createOtpToken?.data?.nextTry;
        setValue('referenceNumber', data?.createOtpToken?.data?.referenceNumber);
      } else {
        const errors = data?.createOtpToken?.errors;
        setOtpErrorMessage(joinPairs(errors));
      }
    } catch (error) {
      setOtpErrorMessage(get(error, 'message', null));
    }

    setNextTryAt(nextTryAt ? dayjs(nextTryAt).toDate() : null);
    setIsGettingOtp(false);
  };

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [changeUsernameMutation] = useMutation(changeUsernameGql);

  const submitHandler = async (values: changePhoneNumberValueType) => {
    setErrorMessage(null);

    try {
      const { data } = await changeUsernameMutation({
        variables: {
          otp: values.otp,
          password: values.password,
          phoneNumber: values.phoneNumber,
          referenceNumber: values.referenceNumber,
        },
      });

      if (data?.changeUsername?.success) {
        Snackbar.enqueueSuccess(SIGN_OUT_CHANGE_PHONE_NUMBER);

        // User token had been clear from server, Clear data and go to SignInPage
        // Clear data
        await tokenManager.clearTokens();
        await apolloClient.clearStore();
        localStorage.clear();

        setContextUser(null);
        setContextRestaurants(null);
        setContextCurrentRestaurant(null);
        setContextInfoCompleteStatus(null);
        setContextRedirectFrom(null);

        // Will auto redirect to /auth/sign_in
        setContextIsLogin(false);
      } else {
        const errors = data?.changeUsername?.errors;
        setErrorMessage(joinPairs(errors));
      }
    } catch (error) {
      setErrorMessage(get(error, 'message', null));
    }
  };

  return (
    <Container maxWidth="sm" sx={{ paddingY: 2 }}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container gap={2}>
          <Controller
            key="phoneNumber"
            name="phoneNumber"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{changePhoneNumberFieldI18n('phone_number')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="tel"
                      fullWidth
                      autoComplete="tel"
                      value={value ?? ''}
                      error={invalid}
                      {...field}
                    />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Controller
            key="otp"
            name="otp"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{changePhoneNumberFieldI18n('otp')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="text"
                      fullWidth
                      autoComplete="one-time-code"
                      value={value ?? ''}
                      error={invalid}
                      {...field}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CooldownButton
                      variant="contained"
                      fullWidth
                      disabled={isEmpty(phoneNumber) || isGettingOtp}
                      cooldownUntil={nextTryAt ?? undefined}
                      onClick={getOtpHandler}
                    >
                      {changePhoneNumberPageI18n('get_otp')}
                    </CooldownButton>
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    {referenceNumber && (
                      <FormHelperText error={false}>
                        {changePhoneNumberPageI18n('ref_num_text', { ref_num: referenceNumber })}
                      </FormHelperText>
                    )}
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Collapse in={!isEmpty(otpErrorMessage)} sx={{ width: '100%' }}>
            <Alert severity="error">{otpErrorMessage}</Alert>
          </Collapse>

          <Divider sx={{ width: '100%', height: 2 }} />

          <Controller
            key="password"
            name="password"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <FormControl id={field.name} fullWidth error={invalid}>
                <Grid item container alignItems="center" gap={1}>
                  <Grid item xs={3}>
                    <FormLabel>{changePhoneNumberFieldI18n('password')} *</FormLabel>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id={field.name}
                      type="password"
                      fullWidth
                      autoComplete="current-password"
                      value={value ?? ''}
                      error={invalid}
                      {...field}
                    />
                  </Grid>
                </Grid>
                <Grid item container gap={1}>
                  <Grid item xs={3} />
                  <Grid item xs>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />

          <Collapse in={!isEmpty(errorMessage)} sx={{ width: '100%' }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>
          <Typography variant="body2" color="text.secondary">
            {changePhoneNumberPageI18n('sigh_out_on_success')}
          </Typography>
          <LoadingButton type="submit" disabled={isSubmitting} loading={isSubmitting} fullWidth variant="contained">
            {i18n.t('general.confirm')}
          </LoadingButton>
        </Grid>
      </form>

      <BottomSafeArea />
    </Container>
  );
};
