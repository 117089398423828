import React from 'react';

import { isNil, merge } from 'lodash';
import type { PathPattern } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router-dom';
import Analytic from '../utils/analytic';

import type { PageViewEventParamsType } from '../utils/analytic';

type OptionsType = {
  trackUrlChange?: boolean;
  trackUrlPattern?: PathPattern | string | null;
};

const useTrackPageView = (
  pageName: string,
  eventParams: PageViewEventParamsType = {},
  options: OptionsType = { trackUrlChange: false },
) => {
  const initialized = React.useRef(false);

  const location = useLocation();
  const lastLocation = React.useRef(location.pathname);

  const sendEvent = React.useCallback(
    (newPageName?: string, newEventParams: PageViewEventParamsType = {}) => {
      const finalPageName = newPageName ?? pageName;
      const finalEventParams = merge(eventParams, newEventParams);

      Analytic().logPageView({ page_title: finalPageName, ...finalEventParams });
    },
    [pageName, eventParams],
  );

  React.useEffect(() => {
    if (!initialized.current) {
      sendEvent();
      initialized.current = true;
    }
  }, [sendEvent]);

  React.useEffect(() => {
    if (options.trackUrlChange && lastLocation.current !== location.pathname) {
      const pathMatched = isNil(options.trackUrlPattern) ? true : matchPath(options.trackUrlPattern, location.pathname);
      if (pathMatched) {
        sendEvent();
      }

      lastLocation.current = location.pathname;
    }
  }, [sendEvent, options.trackUrlChange, options.trackUrlPattern, location.pathname]);

  return { sendEvent };
};

export default useTrackPageView;
