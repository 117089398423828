import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { camelCase } from 'lodash';
import { apiAxios } from '../components';
import { APP_NAME } from '../config';

const defaultAccessTokenKey = `${camelCase(APP_NAME)}_accessToken`;
const defaultRefreshTokenKey = `${camelCase(APP_NAME)}_refreshToken`;

type refreshAccessToken = (
  accessToken: string,
  refreshToken: string,
) => Promise<{
  access: string;
  refresh: string;
}>;

class Manager {
  storage: any;
  _refreshAccessToken: refreshAccessToken;
  accessTokenKey: string;
  refreshTokenKey: string;
  expireOffset: number;
  accessToken: string | null;
  accessTokenExpDate: number | null;
  refreshToken: string | null;
  _isRefreshing: boolean;

  constructor({
    storage,
    refreshAccessToken,
    accessTokenKey = defaultAccessTokenKey,
    refreshTokenKey = defaultRefreshTokenKey,
    expireOffset = 0,
  }: {
    storage: any;
    refreshAccessToken: refreshAccessToken;
    accessTokenKey?: string;
    refreshTokenKey?: string;
    expireOffset?: number;
  }) {
    this.storage = storage;
    this._refreshAccessToken = refreshAccessToken;

    this.accessTokenKey = accessTokenKey;
    this.refreshTokenKey = refreshTokenKey;
    this.expireOffset = expireOffset;

    this._isRefreshing = false;
    this.accessToken = null;
    this.accessTokenExpDate = null;
    this.refreshToken = null;
  }

  async getToken(): Promise<string> {
    if (!this.accessToken || !this.accessTokenExpDate) {
      this.accessToken = this.storage.getItem(this.accessTokenKey);
      const { exp } = jwtDecode<JwtPayload>(this.accessToken!);
      this.accessTokenExpDate = this.accessToken ? exp! * 1000 - this.expireOffset : null;
    }
    if (this.accessTokenExpDate && +new Date() > this.accessTokenExpDate) return await this.refreshAccessToken();
    return this.accessToken!;
  }
  async setToken(token: string) {
    if (this.accessToken !== token) {
      const { exp } = jwtDecode<JwtPayload>(token);

      this.storage.setItem(this.accessTokenKey, token);
      this.accessToken = token;
      this.accessTokenExpDate = exp! * 1000 - this.expireOffset; // API always set accessToken exp
    }
    return true;
  }
  async removeToken() {
    this.accessToken = null;
    this.accessTokenExpDate = null;
    this.storage.removeItem(this.accessTokenKey);
  }
  async getRefreshToken(): Promise<string> {
    if (!this.refreshToken) this.refreshToken = this.storage.getItem(this.refreshTokenKey);
    return this.refreshToken as string;
  }
  async setRefreshToken(refreshToken: string) {
    if (this.refreshToken !== refreshToken) {
      this.storage.setItem(this.refreshTokenKey, refreshToken);
      this.refreshToken = refreshToken;
    }
    return true;
  }
  async refreshAccessToken(): Promise<string> {
    if (this._isRefreshing) {
      for (let i = 0; this._isRefreshing && i < 12000; i += 128) {
        await new Promise((resolve) => setTimeout(resolve, 128));
      }
      const access = this.getToken();
      return access;
    } else {
      this._isRefreshing = true;
      const refreshToken = await this.getRefreshToken();
      const accessToken = this.accessToken || this.storage.getItem(this.accessTokenKey);
      const { access, refresh } = await this._refreshAccessToken(refreshToken, accessToken);
      await this.setToken(access);
      await this.setRefreshToken(refresh);
      this._isRefreshing = false;
      return access;
    }
  }
  async clearTokens() {
    this.storage.removeItem(this.accessTokenKey);
    this.storage.removeItem(this.refreshTokenKey);
    this.accessToken = null;
    this.accessTokenExpDate = null;
    this.refreshToken = null;
  }
}

const manager = new Manager({
  storage: localStorage,
  refreshAccessToken: async (refreshToken, token) => {
    for (let i = 1; i <= 5; i++) {
      try {
        const response = await apiAxios({
          method: 'post',
          url: '/users/tokens/',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Refresh-Token': refreshToken,
          },
        });
        return {
          access: response.headers['access-token'],
          refresh: response.headers['refresh-token'],
        };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const errorStatus = error?.response?.status;
          if (errorStatus === 401) {
            localStorage.removeItem(defaultAccessTokenKey);
            localStorage.removeItem(defaultRefreshTokenKey);
            throw new Error(error?.response?.data?.error);
          } else {
            await new Promise((resolve) => setTimeout(resolve, 2100));
          }
        } else throw error;
      }
    }
    throw new Error('connection problem');
  },
});

export default manager;
