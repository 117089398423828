import React from 'react';

import { Close } from '@mui/icons-material';
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Marker } from '@react-google-maps/api';
import { debounce, every } from 'lodash';
import { GeolocationButton, GoogleMapsBase, PlaceAutocompleteInput } from '.';

import type { DialogProps } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import type { PlaceResult } from './place_autocomplete_input';

const DEFAULT_LOCATION = { lat: 13.7563, lng: 100.5018 };

const toLatLng = (
  location?: {
    lat?: number | google.maps.LatLng['lat'] | null;
    latitude?: number | null;
    lng?: number | google.maps.LatLng['lng'] | null;
    longitude?: number | null;
  } | null,
) => {
  const lat = (typeof location?.lat === 'function' ? location.lat() : location?.lat) ?? location?.latitude;
  const lng = (typeof location?.lng === 'function' ? location.lng() : location?.lng) ?? location?.longitude;
  if (lat && lng) {
    return { lat, lng };
  }
};

const toLocation = (
  location?: {
    lat?: number | google.maps.LatLng['lat'] | null;
    latitude?: number | null;
    lng?: number | google.maps.LatLng['lat'] | null;
    longitude?: number | null;
  } | null,
) => {
  const latitude = location?.latitude ?? (typeof location?.lat === 'function' ? location.lat() : location?.lat);
  const longitude = location?.longitude ?? (typeof location?.lng === 'function' ? location.lng() : location?.lng);
  if (latitude && longitude) {
    return { latitude, longitude };
  }
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children: React.ReactElement }, ref: React.Ref<unknown>) => {
    return <Slide ref={ref} direction="up" {...props} />;
  },
);

type Location = { latitude: number; longitude: number };

export type MapsPickerResultType = {
  location: Location | null;
  placeId: string | null;
};

export type MapsPickerDialogProps = {
  title?: string;
  initialMapCenter?: { latitude: number; longitude: number };
  onConfirm?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, result: MapsPickerResultType) => void;
  onClose?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reason: 'close' | 'submit' | 'backdropClick' | 'escapeKeyDown',
  ) => void | DialogProps['onClose'];
} & DialogProps;

export const MapsPickerDialog = ({ title, initialMapCenter, onConfirm, onClose, ...props }: MapsPickerDialogProps) => {
  const theme = useTheme();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down('sm'));

  const mapInstanceRef = React.useRef<google.maps.Map | null>(null);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [currentPlaceId, setCurrentPlaceId] = React.useState<string | null>(null);
  const [currentLocation, setCurrentLocation] = React.useState<Location | null>(null);

  const [mapCenter, setMapCenter] = React.useState<google.maps.LatLng | google.maps.LatLngLiteral | null>(null);
  const locationBias = toLocation(mapCenter) ?? initialMapCenter ?? toLocation(DEFAULT_LOCATION);

  const locationSelected = every([currentLocation?.latitude, currentLocation?.longitude]);

  const onCloseHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reason?: 'close' | 'submit' | 'backdropClick' | 'escapeKeyDown',
  ) => {
    setErrorMessage(null);
    onClose && onClose(event, reason ?? 'close');
    setCurrentLocation(null);
    setMapLoaded(false);
  };

  const onConfirmHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setErrorMessage(null);
    if (onConfirm) {
      onConfirm(event, { location: currentLocation, placeId: currentPlaceId });
    }
    onCloseHandler(event, 'submit');
  };

  const [mapLoaded, setMapLoaded] = React.useState(false);
  const onMapsLoadHandler = (instance: google.maps.Map) => {
    mapInstanceRef.current = instance;

    const initialLocationLatLng = toLatLng(initialMapCenter);
    if (initialLocationLatLng) {
      instance.setCenter(initialLocationLatLng);
    }

    setTimeout(() => setMapLoaded(true), 100);
  };

  const onCenterChangedHandler = React.useMemo(
    () =>
      debounce(() => {
        const nextMapCenter = mapInstanceRef.current?.getCenter();
        setMapCenter((prev) => {
          if (prev instanceof google.maps.LatLng && prev.equals(nextMapCenter ?? null)) {
            return prev;
          } else {
            return nextMapCenter ?? null;
          }
        });
      }, 250),
    [],
  );

  const locationChangeHandler = (
    event: google.maps.MapMouseEvent & { placeId?: google.maps.IconMouseEvent['placeId'] },
  ) => {
    const latitude = event.latLng?.lat() ?? null;
    const longitude = event.latLng?.lng() ?? null;
    const placeId = event.placeId ?? null;

    if (latitude && longitude) {
      setCurrentLocation({ latitude, longitude });
    } else {
      setCurrentLocation(null);
    }
    setCurrentPlaceId(placeId);

    if (latitude && longitude) {
      mapInstanceRef.current?.panTo({ lat: latitude, lng: longitude });
    }
  };

  const onPlaceChangeHandler = (place: PlaceResult) => {
    const location = place.location;
    if (location) {
      setCurrentLocation(location);
      setCurrentPlaceId(place.placeId);
      mapInstanceRef.current?.panTo(toLatLng(location) as google.maps.LatLngLiteral);
    }
  };

  const onGeolocationChangeHandler = (location: Location) => {
    setCurrentLocation(location);
    setCurrentPlaceId(null);
    mapInstanceRef.current?.panTo(toLatLng(location) as google.maps.LatLngLiteral);
  };

  return (
    <Dialog fullScreen TransitionComponent={Transition} onClose={onCloseHandler} {...props}>
      <DialogTitle display="inline-flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        {title ?? 'เลือกสถานที่'}
        <IconButton onClick={onCloseHandler}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <Stack position="relative" width="100%" height="100%">
          <GoogleMapsBase
            clickableIcons
            zoom={14}
            center={DEFAULT_LOCATION}
            onLoad={onMapsLoadHandler}
            onClick={locationChangeHandler}
            onCenterChanged={onCenterChangedHandler}
            options={{
              gestureHandling: 'greedy',
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
            }}
            mapContainerStyle={{ width: '100%', height: '100%' }}
          >
            {mapLoaded && (
              <Marker
                draggable
                onDragEnd={locationChangeHandler}
                position={toLatLng(currentLocation) ?? toLatLng(initialMapCenter) ?? DEFAULT_LOCATION}
                onClick={() => setCurrentLocation(null)}
              />
            )}

            <Stack
              sx={{
                position: 'absolute',
                marginLeft: 14,
                paddingTop: 1,
                width: { xs: '60%', sm: '20%' },
              }}
            >
              <PlaceAutocompleteInput locationBias={locationBias} onPlaceChange={onPlaceChangeHandler} />
            </Stack>


            <GeolocationButton
              onClick={() => setErrorMessage(null)}
              onGeolocationChange={onGeolocationChangeHandler}
              onGeolocationError={setErrorMessage}
              geolocationOptions={{ enableHighAccuracy: true }}
              sx={{ position: 'absolute', top: 12, left: 32, backgroundColor: 'white' }}
            />
          </GoogleMapsBase>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ flexDirection: 'column' }}>
        <Collapse in={!!errorMessage} sx={{ width: '100%' }}>
          <Alert severity="error" onClose={() => setErrorMessage(null)} sx={{ marginBottom: 1 }}>
            {errorMessage}
          </Alert>
        </Collapse>
        <Button variant="contained" fullWidth disableElevation disabled={!locationSelected} onClick={onConfirmHandler}>
          {locationSelected ? 'ยืนยัน' : 'กรุณาเลือกตำแหน่ง'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
